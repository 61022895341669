@charset "UTF-8"; @font-face {
    font-family: "Jakarta";
    src: local("Plus Jakarta Sans ExtraBold"), local("PlusJakartaSans-ExtraBold"), url("../fonts/jakarta-black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Jakarta";
    src: local("Plus Jakarta Sans Bold"), local("PlusJakartaSans-Bold"), url("../fonts/jakarta-bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Jakarta";
    src: local("Plus Jakarta Sans Medium"), local("PlusJakartaSans-Medium"), url("../fonts/jakarta-medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: local("Plus Jakarta Sans Regular"), local("PlusJakartaSans-Regular"), url("../fonts/jakarta-regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}
:root {
    --blue: #0d6efd;
    --indigo: #a425fe;
    --purple: #6f42c1;
    --pink: #d63384;
    --red: #ce5a56;
    --orange: #fd7e14;
    --yellow: #f7c239;
    --green: #198754;
    --teal: #55b59e;
    --cyan: #39baf6;
    --black: #000;
    --white: #fff;
    --gray: #6b7989;
    --gray-dark: var(--secondary);
    --gray-50: #fcfcfc;
    --gray-100: #f8f9fa;
    --gray-200: #f4f7fa;
    --gray-300: #dce3ed;
    --gray-400: #8b97a6;
    --gray-500: #76808e;
    --gray-600: #6b7989;
    --gray-700: #495057;
    --gray-800: #1c263b;
    --gray-900: #0e0e10;
    --primary: #9146FF;
    --primary-dark: #7d3ae1;
    --secondary: var(--gray-900);
    --success: #55b59e;
    --info: #39baf6;
    --warning: #f7c239;
    --danger: #ce5a56;
    --light: #f8f9fa;
    --dark: #1c263b;
    --darker: var(--secondary);
    --primary-rgb: 108, 91, 243;
    --secondary-rgb: 14, 20, 36;
    --success-rgb: 85, 181, 158;
    --info-rgb: 57, 186, 246;
    --warning-rgb: 247, 194, 57;
    --danger-rgb: 206, 90, 86;
    --light-rgb: 248, 249, 250;
    --dark-rgb: 28, 38, 59;
    --darker-rgb: 14, 20, 36;
    --white-rgb: 255, 255, 255;
    --black-rgb: 0, 0, 0;
    --body-color-rgb: 107, 121, 137;
    --body-bg-rgb: 255, 255, 255;
    --font-sans-serif: "Jakarta", sans-serif;
    --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --root-font-size: 16px;
    --body-font-family: var(--font-sans-serif);
    --body-font-size: 0.9375rem;
    --body-font-weight: 400;
    --body-line-height: 1.6;
    --body-color: #6b7989;
    --body-bg: #fff;
    --border-width: 1px;
    --border-style: solid;
    --border-color: #dce3ed;
    --border-color-translucent: rgba(0, 0, 0, 0.175);
    --border-radius: 0.375rem;
    --border-radius-sm: 0.25rem;
    --border-radius-lg: 0.5rem;
    --border-radius-xl: 0.875rem;
    --border-radius-2xl: 2rem;
    --border-radius-3xl: 3rem;
    --border-radius-4xl: 4rem;
    --border-radius-pill: 50rem;
    --litw-color: var(--primary);
    --litw-hover-color: var(--primary-dark);
    --code-color: #d63384;
    --highlight-bg: #fff3cd
}

*, *::before, *::after {
    box-sizing: border-box
}

:root {
    font-size: var(--root-font-size)
}

@media(prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0 !important;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    color: var(--body-color);
    overflow: overlay;
    text-align: var(--body-text-align);
    background-color: rgba(var(--darker-rgb), 1) !important;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25;
    color: #1c263b
}

h1, .h1 {
    font-size: calc(1.425rem + 2.1vw)
}

@media(min-width: 1200px) {
    h1, .h1 {
        font-size: 3rem;
    }
}

h2, .h2 {
    font-size: calc(1.35rem + 1.2vw)
}

@media(min-width: 1200px) {
    h2, .h2 {
        font-size: 2.25rem
    }
}

h3, .h3 {
    font-size: calc(1.275rem + 0.3vw)
}

@media(min-width: 1200px) {
    h3, .h3 {
        font-size: 1.5rem
    }
}

h4, .h4 {
    font-size: 1.25rem
}

h5, .h5 {
    font-size: 1.125rem
}

h6, .h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol, ul {
    padding-left: 2rem
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol, ul ul, ol ul, ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b, strong {
    font-weight: bolder
}

small, .small {
    font-size: 0.875em
}

mark, .mark {
    padding: .1875em;
    background-color: var(--highlight-bg)
}

sub, sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

a {
    color: var(--litw-color);
    text-decoration: underline
}

a:hover {
    color: var(--litw-hover-color)
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

pre, code, kbd, samp {
    font-family: var(--font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: 0.875em;
    color: var(--code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .1875rem .375rem;
    font-size: 0.875em;
    color: var(--body-bg);
    background-color: var(--body-color);
    border-radius: .25rem
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

figure {
    margin: 0 0 1rem
}

img, svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important
}

button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button;
    appearance: button;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit
}

@media(min-width: 1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    appearance: textfield;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    appearance: none;
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit;
    appearance: button;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.125rem;
    font-weight: 400
}

.display-1 {
    font-size: calc(1.875rem + 7.5vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-1 {
        font-size: 7.5rem
    }
}

.display-2 {
    font-size: calc(1.775rem + 6.3vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-2 {
        font-size: 6.5rem
    }
}

.display-3 {
    font-size: calc(1.7rem + 5.4vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-3 {
        font-size: 5.75rem
    }
}

.display-4 {
    font-size: calc(1.625rem + 4.5vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-4 {
        font-size: 5rem
    }
}

.display-5 {
    font-size: calc(1.575rem + 3.9vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-5 {
        font-size: 4.5rem
    }
}

.display-6 {
    font-size: calc(1.5rem + 3vw);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 1.25
}

@media(min-width: 1200px) {
    .display-6 {
        font-size: 3.75rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6b7989
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: .375rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 0.875em;
    color: #6c757d
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --gutter-x: 2rem;
    --gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {
    .toast .alert {
        width: 100%;
        max-width: 350px;
        margin: auto;
        text-align: left;
    }

    .container-sm, .container {
        max-width: 540px
    }
}

@media(min-width: 768px) {
    .container-md, .container-sm, .container {
        max-width: 720px
    }
}

@media(min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
        max-width: 960px
    }
}

@media(min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px
    }
}

@media(min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1260px
    }
}

.row {
    --gutter-x: 2rem;
    --gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--gutter-y));
    margin-right: calc(-0.5*var(--gutter-x));
    margin-left: calc(-0.5*var(--gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-top: var(--gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0, .gx-0 {
    --gutter-x: 0
}

.g-0, .gy-0 {
    --gutter-y: 0
}

.g-0-5, .gx-0-5 {
    --gutter-x: 0.125rem
}

.g-0-5, .gy-0-5 {
    --gutter-y: 0.125rem
}

.g-1, .gx-1 {
    --gutter-x: 0.25rem
}

.g-1, .gy-1 {
    --gutter-y: 0.25rem
}

.g-2, .gx-2 {
    --gutter-x: 0.5rem
}

.g-2, .gy-2 {
    --gutter-y: 0.5rem
}

.g-3, .gx-3 {
    --gutter-x: 1rem
}

.g-3, .gy-3 {
    --gutter-y: 1rem
}

.g-4, .gx-4 {
    --gutter-x: 1.5rem
}

.g-4, .gy-4 {
    --gutter-y: 1.5rem
}

.g-5, .gx-5 {
    --gutter-x: 2rem
}

.g-5, .gy-5 {
    --gutter-y: 2rem
}

.g-6, .gx-6 {
    --gutter-x: 3.5rem
}

.g-6, .gy-6 {
    --gutter-y: 3.5rem
}

.g-7, .gx-7 {
    --gutter-x: 4rem
}

.g-7, .gy-7 {
    --gutter-y: 4rem
}

.g-8, .gx-8 {
    --gutter-x: 4.5rem
}

.g-8, .gy-8 {
    --gutter-y: 4.5rem
}

.g-9, .gx-9 {
    --gutter-x: 5rem
}

.g-9, .gy-9 {
    --gutter-y: 5rem
}

.g-10, .gx-10 {
    --gutter-x: 6rem
}

.g-10, .gy-10 {
    --gutter-y: 6rem
}

.g-11, .gx-11 {
    --gutter-x: 7rem
}

.g-11, .gy-11 {
    --gutter-y: 7rem
}

.g-12, .gx-12 {
    --gutter-x: 8rem
}

.g-12, .gy-12 {
    --gutter-y: 8rem
}

.g-gs, .gx-gs {
    --gutter-x: 2rem
}

.g-gs, .gy-gs {
    --gutter-y: 2rem
}

.g-10p, .gx-10p {
    --gutter-x: 10%
}

.g-10p, .gy-10p {
    --gutter-y: 10%
}

.g-20p, .gx-20p {
    --gutter-x: 20%
}

.g-20p, .gy-20p {
    --gutter-y: 20%
}

.g-25p, .gx-25p {
    --gutter-x: 25%
}

.g-25p, .gy-25p {
    --gutter-y: 25%
}

.g-30p, .gx-30p {
    --gutter-x: 30%
}

.g-30p, .gy-30p {
    --gutter-y: 30%
}

.g-35p, .gx-35p {
    --gutter-x: 35%
}

.g-35p, .gy-35p {
    --gutter-y: 35%
}

.g-40p, .gx-40p {
    --gutter-x: 40%
}

.g-40p, .gy-40p {
    --gutter-y: 40%
}

.g-45p, .gx-45p {
    --gutter-x: 45%
}

.g-45p, .gy-45p {
    --gutter-y: 45%
}

.g-50p, .gx-50p {
    --gutter-x: 50%
}

.g-50p, .gy-50p {
    --gutter-y: 50%
}

.g-60p, .gx-60p {
    --gutter-x: 60%
}

.g-60p, .gy-60p {
    --gutter-y: 60%
}

.g-70p, .gx-70p {
    --gutter-x: 70%
}

.g-70p, .gy-70p {
    --gutter-y: 70%
}

.g-75p, .gx-75p {
    --gutter-x: 75%
}

.g-75p, .gy-75p {
    --gutter-y: 75%
}

.g-80p, .gx-80p {
    --gutter-x: 80%
}

.g-80p, .gy-80p {
    --gutter-y: 80%
}

.g-90p, .gx-90p {
    --gutter-x: 90%
}

.g-90p, .gy-90p {
    --gutter-y: 90%
}

.g-100p, .gx-100p {
    --gutter-x: 100%
}

.g-100p, .gy-100p {
    --gutter-y: 100%
}

@media(min-width: 576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0, .gx-sm-0 {
        --gutter-x: 0
    }

    .g-sm-0, .gy-sm-0 {
        --gutter-y: 0
    }

    .g-sm-0-5, .gx-sm-0-5 {
        --gutter-x: 0.125rem
    }

    .g-sm-0-5, .gy-sm-0-5 {
        --gutter-y: 0.125rem
    }

    .g-sm-1, .gx-sm-1 {
        --gutter-x: 0.25rem
    }

    .g-sm-1, .gy-sm-1 {
        --gutter-y: 0.25rem
    }

    .g-sm-2, .gx-sm-2 {
        --gutter-x: 0.5rem
    }

    .g-sm-2, .gy-sm-2 {
        --gutter-y: 0.5rem
    }

    .g-sm-3, .gx-sm-3 {
        --gutter-x: 1rem
    }

    .g-sm-3, .gy-sm-3 {
        --gutter-y: 1rem
    }

    .g-sm-4, .gx-sm-4 {
        --gutter-x: 1.5rem
    }

    .g-sm-4, .gy-sm-4 {
        --gutter-y: 1.5rem
    }

    .g-sm-5, .gx-sm-5 {
        --gutter-x: 2rem
    }

    .g-sm-5, .gy-sm-5 {
        --gutter-y: 2rem
    }

    .g-sm-6, .gx-sm-6 {
        --gutter-x: 3.5rem
    }

    .g-sm-6, .gy-sm-6 {
        --gutter-y: 3.5rem
    }

    .g-sm-7, .gx-sm-7 {
        --gutter-x: 4rem
    }

    .g-sm-7, .gy-sm-7 {
        --gutter-y: 4rem
    }

    .g-sm-8, .gx-sm-8 {
        --gutter-x: 4.5rem
    }

    .g-sm-8, .gy-sm-8 {
        --gutter-y: 4.5rem
    }

    .g-sm-9, .gx-sm-9 {
        --gutter-x: 5rem
    }

    .g-sm-9, .gy-sm-9 {
        --gutter-y: 5rem
    }

    .g-sm-10, .gx-sm-10 {
        --gutter-x: 6rem
    }

    .g-sm-10, .gy-sm-10 {
        --gutter-y: 6rem
    }

    .g-sm-11, .gx-sm-11 {
        --gutter-x: 7rem
    }

    .g-sm-11, .gy-sm-11 {
        --gutter-y: 7rem
    }

    .g-sm-12, .gx-sm-12 {
        --gutter-x: 8rem
    }

    .g-sm-12, .gy-sm-12 {
        --gutter-y: 8rem
    }

    .g-sm-gs, .gx-sm-gs {
        --gutter-x: 2rem
    }

    .g-sm-gs, .gy-sm-gs {
        --gutter-y: 2rem
    }

    .g-sm-10p, .gx-sm-10p {
        --gutter-x: 10%
    }

    .g-sm-10p, .gy-sm-10p {
        --gutter-y: 10%
    }

    .g-sm-20p, .gx-sm-20p {
        --gutter-x: 20%
    }

    .g-sm-20p, .gy-sm-20p {
        --gutter-y: 20%
    }

    .g-sm-25p, .gx-sm-25p {
        --gutter-x: 25%
    }

    .g-sm-25p, .gy-sm-25p {
        --gutter-y: 25%
    }

    .g-sm-30p, .gx-sm-30p {
        --gutter-x: 30%
    }

    .g-sm-30p, .gy-sm-30p {
        --gutter-y: 30%
    }

    .g-sm-35p, .gx-sm-35p {
        --gutter-x: 35%
    }

    .g-sm-35p, .gy-sm-35p {
        --gutter-y: 35%
    }

    .g-sm-40p, .gx-sm-40p {
        --gutter-x: 40%
    }

    .g-sm-40p, .gy-sm-40p {
        --gutter-y: 40%
    }

    .g-sm-45p, .gx-sm-45p {
        --gutter-x: 45%
    }

    .g-sm-45p, .gy-sm-45p {
        --gutter-y: 45%
    }

    .g-sm-50p, .gx-sm-50p {
        --gutter-x: 50%
    }

    .g-sm-50p, .gy-sm-50p {
        --gutter-y: 50%
    }

    .g-sm-60p, .gx-sm-60p {
        --gutter-x: 60%
    }

    .g-sm-60p, .gy-sm-60p {
        --gutter-y: 60%
    }

    .g-sm-70p, .gx-sm-70p {
        --gutter-x: 70%
    }

    .g-sm-70p, .gy-sm-70p {
        --gutter-y: 70%
    }

    .g-sm-75p, .gx-sm-75p {
        --gutter-x: 75%
    }

    .g-sm-75p, .gy-sm-75p {
        --gutter-y: 75%
    }

    .g-sm-80p, .gx-sm-80p {
        --gutter-x: 80%
    }

    .g-sm-80p, .gy-sm-80p {
        --gutter-y: 80%
    }

    .g-sm-90p, .gx-sm-90p {
        --gutter-x: 90%
    }

    .g-sm-90p, .gy-sm-90p {
        --gutter-y: 90%
    }

    .g-sm-100p, .gx-sm-100p {
        --gutter-x: 100%
    }

    .g-sm-100p, .gy-sm-100p {
        --gutter-y: 100%
    }
}

@media(min-width: 768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0, .gx-md-0 {
        --gutter-x: 0
    }

    .g-md-0, .gy-md-0 {
        --gutter-y: 0
    }

    .g-md-0-5, .gx-md-0-5 {
        --gutter-x: 0.125rem
    }

    .g-md-0-5, .gy-md-0-5 {
        --gutter-y: 0.125rem
    }

    .g-md-1, .gx-md-1 {
        --gutter-x: 0.25rem
    }

    .g-md-1, .gy-md-1 {
        --gutter-y: 0.25rem
    }

    .g-md-2, .gx-md-2 {
        --gutter-x: 0.5rem
    }

    .g-md-2, .gy-md-2 {
        --gutter-y: 0.5rem
    }

    .g-md-3, .gx-md-3 {
        --gutter-x: 1rem
    }

    .g-md-3, .gy-md-3 {
        --gutter-y: 1rem
    }

    .g-md-4, .gx-md-4 {
        --gutter-x: 1.5rem
    }

    .g-md-4, .gy-md-4 {
        --gutter-y: 1.5rem
    }

    .g-md-5, .gx-md-5 {
        --gutter-x: 2rem
    }

    .g-md-5, .gy-md-5 {
        --gutter-y: 2rem
    }

    .g-md-6, .gx-md-6 {
        --gutter-x: 3.5rem
    }

    .g-md-6, .gy-md-6 {
        --gutter-y: 3.5rem
    }

    .g-md-7, .gx-md-7 {
        --gutter-x: 4rem
    }

    .g-md-7, .gy-md-7 {
        --gutter-y: 4rem
    }

    .g-md-8, .gx-md-8 {
        --gutter-x: 4.5rem
    }

    .g-md-8, .gy-md-8 {
        --gutter-y: 4.5rem
    }

    .g-md-9, .gx-md-9 {
        --gutter-x: 5rem
    }

    .g-md-9, .gy-md-9 {
        --gutter-y: 5rem
    }

    .g-md-10, .gx-md-10 {
        --gutter-x: 6rem
    }

    .g-md-10, .gy-md-10 {
        --gutter-y: 6rem
    }

    .g-md-11, .gx-md-11 {
        --gutter-x: 7rem
    }

    .g-md-11, .gy-md-11 {
        --gutter-y: 7rem
    }

    .g-md-12, .gx-md-12 {
        --gutter-x: 8rem
    }

    .g-md-12, .gy-md-12 {
        --gutter-y: 8rem
    }

    .g-md-gs, .gx-md-gs {
        --gutter-x: 2rem
    }

    .g-md-gs, .gy-md-gs {
        --gutter-y: 2rem
    }

    .g-md-10p, .gx-md-10p {
        --gutter-x: 10%
    }

    .g-md-10p, .gy-md-10p {
        --gutter-y: 10%
    }

    .g-md-20p, .gx-md-20p {
        --gutter-x: 20%
    }

    .g-md-20p, .gy-md-20p {
        --gutter-y: 20%
    }

    .g-md-25p, .gx-md-25p {
        --gutter-x: 25%
    }

    .g-md-25p, .gy-md-25p {
        --gutter-y: 25%
    }

    .g-md-30p, .gx-md-30p {
        --gutter-x: 30%
    }

    .g-md-30p, .gy-md-30p {
        --gutter-y: 30%
    }

    .g-md-35p, .gx-md-35p {
        --gutter-x: 35%
    }

    .g-md-35p, .gy-md-35p {
        --gutter-y: 35%
    }

    .g-md-40p, .gx-md-40p {
        --gutter-x: 40%
    }

    .g-md-40p, .gy-md-40p {
        --gutter-y: 40%
    }

    .g-md-45p, .gx-md-45p {
        --gutter-x: 45%
    }

    .g-md-45p, .gy-md-45p {
        --gutter-y: 45%
    }

    .g-md-50p, .gx-md-50p {
        --gutter-x: 50%
    }

    .g-md-50p, .gy-md-50p {
        --gutter-y: 50%
    }

    .g-md-60p, .gx-md-60p {
        --gutter-x: 60%
    }

    .g-md-60p, .gy-md-60p {
        --gutter-y: 60%
    }

    .g-md-70p, .gx-md-70p {
        --gutter-x: 70%
    }

    .g-md-70p, .gy-md-70p {
        --gutter-y: 70%
    }

    .g-md-75p, .gx-md-75p {
        --gutter-x: 75%
    }

    .g-md-75p, .gy-md-75p {
        --gutter-y: 75%
    }

    .g-md-80p, .gx-md-80p {
        --gutter-x: 80%
    }

    .g-md-80p, .gy-md-80p {
        --gutter-y: 80%
    }

    .g-md-90p, .gx-md-90p {
        --gutter-x: 90%
    }

    .g-md-90p, .gy-md-90p {
        --gutter-y: 90%
    }

    .g-md-100p, .gx-md-100p {
        --gutter-x: 100%
    }

    .g-md-100p, .gy-md-100p {
        --gutter-y: 100%
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0, .gx-lg-0 {
        --gutter-x: 0
    }

    .g-lg-0, .gy-lg-0 {
        --gutter-y: 0
    }

    .g-lg-0-5, .gx-lg-0-5 {
        --gutter-x: 0.125rem
    }

    .g-lg-0-5, .gy-lg-0-5 {
        --gutter-y: 0.125rem
    }

    .g-lg-1, .gx-lg-1 {
        --gutter-x: 0.25rem
    }

    .g-lg-1, .gy-lg-1 {
        --gutter-y: 0.25rem
    }

    .g-lg-2, .gx-lg-2 {
        --gutter-x: 0.5rem
    }

    .g-lg-2, .gy-lg-2 {
        --gutter-y: 0.5rem
    }

    .g-lg-3, .gx-lg-3 {
        --gutter-x: 1rem
    }

    .g-lg-3, .gy-lg-3 {
        --gutter-y: 1rem
    }

    .g-lg-4, .gx-lg-4 {
        --gutter-x: 1.5rem
    }

    .g-lg-4, .gy-lg-4 {
        --gutter-y: 1.5rem
    }

    .g-lg-5, .gx-lg-5 {
        --gutter-x: 2rem
    }

    .g-lg-5, .gy-lg-5 {
        --gutter-y: 2rem
    }

    .g-lg-6, .gx-lg-6 {
        --gutter-x: 3.5rem
    }

    .g-lg-6, .gy-lg-6 {
        --gutter-y: 3.5rem
    }

    .g-lg-7, .gx-lg-7 {
        --gutter-x: 4rem
    }

    .g-lg-7, .gy-lg-7 {
        --gutter-y: 4rem
    }

    .g-lg-8, .gx-lg-8 {
        --gutter-x: 4.5rem
    }

    .g-lg-8, .gy-lg-8 {
        --gutter-y: 4.5rem
    }

    .g-lg-9, .gx-lg-9 {
        --gutter-x: 5rem
    }

    .g-lg-9, .gy-lg-9 {
        --gutter-y: 5rem
    }

    .g-lg-10, .gx-lg-10 {
        --gutter-x: 6rem
    }

    .g-lg-10, .gy-lg-10 {
        --gutter-y: 6rem
    }

    .g-lg-11, .gx-lg-11 {
        --gutter-x: 7rem
    }

    .g-lg-11, .gy-lg-11 {
        --gutter-y: 7rem
    }

    .g-lg-12, .gx-lg-12 {
        --gutter-x: 8rem
    }

    .g-lg-12, .gy-lg-12 {
        --gutter-y: 8rem
    }

    .g-lg-gs, .gx-lg-gs {
        --gutter-x: 2rem
    }

    .g-lg-gs, .gy-lg-gs {
        --gutter-y: 2rem
    }

    .g-lg-10p, .gx-lg-10p {
        --gutter-x: 10%
    }

    .g-lg-10p, .gy-lg-10p {
        --gutter-y: 10%
    }

    .g-lg-20p, .gx-lg-20p {
        --gutter-x: 20%
    }

    .g-lg-20p, .gy-lg-20p {
        --gutter-y: 20%
    }

    .g-lg-25p, .gx-lg-25p {
        --gutter-x: 25%
    }

    .g-lg-25p, .gy-lg-25p {
        --gutter-y: 25%
    }

    .g-lg-30p, .gx-lg-30p {
        --gutter-x: 30%
    }

    .g-lg-30p, .gy-lg-30p {
        --gutter-y: 30%
    }

    .g-lg-35p, .gx-lg-35p {
        --gutter-x: 35%
    }

    .g-lg-35p, .gy-lg-35p {
        --gutter-y: 35%
    }

    .g-lg-40p, .gx-lg-40p {
        --gutter-x: 40%
    }

    .g-lg-40p, .gy-lg-40p {
        --gutter-y: 40%
    }

    .g-lg-45p, .gx-lg-45p {
        --gutter-x: 45%
    }

    .g-lg-45p, .gy-lg-45p {
        --gutter-y: 45%
    }

    .g-lg-50p, .gx-lg-50p {
        --gutter-x: 50%
    }

    .g-lg-50p, .gy-lg-50p {
        --gutter-y: 50%
    }

    .g-lg-60p, .gx-lg-60p {
        --gutter-x: 60%
    }

    .g-lg-60p, .gy-lg-60p {
        --gutter-y: 60%
    }

    .g-lg-70p, .gx-lg-70p {
        --gutter-x: 70%
    }

    .g-lg-70p, .gy-lg-70p {
        --gutter-y: 70%
    }

    .g-lg-75p, .gx-lg-75p {
        --gutter-x: 75%
    }

    .g-lg-75p, .gy-lg-75p {
        --gutter-y: 75%
    }

    .g-lg-80p, .gx-lg-80p {
        --gutter-x: 80%
    }

    .g-lg-80p, .gy-lg-80p {
        --gutter-y: 80%
    }

    .g-lg-90p, .gx-lg-90p {
        --gutter-x: 90%
    }

    .g-lg-90p, .gy-lg-90p {
        --gutter-y: 90%
    }

    .g-lg-100p, .gx-lg-100p {
        --gutter-x: 100%
    }

    .g-lg-100p, .gy-lg-100p {
        --gutter-y: 100%
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0, .gx-xl-0 {
        --gutter-x: 0
    }

    .g-xl-0, .gy-xl-0 {
        --gutter-y: 0
    }

    .g-xl-0-5, .gx-xl-0-5 {
        --gutter-x: 0.125rem
    }

    .g-xl-0-5, .gy-xl-0-5 {
        --gutter-y: 0.125rem
    }

    .g-xl-1, .gx-xl-1 {
        --gutter-x: 0.25rem
    }

    .g-xl-1, .gy-xl-1 {
        --gutter-y: 0.25rem
    }

    .g-xl-2, .gx-xl-2 {
        --gutter-x: 0.5rem
    }

    .g-xl-2, .gy-xl-2 {
        --gutter-y: 0.5rem
    }

    .g-xl-3, .gx-xl-3 {
        --gutter-x: 1rem
    }

    .g-xl-3, .gy-xl-3 {
        --gutter-y: 1rem
    }

    .g-xl-4, .gx-xl-4 {
        --gutter-x: 1.5rem
    }

    .g-xl-4, .gy-xl-4 {
        --gutter-y: 1.5rem
    }

    .g-xl-5, .gx-xl-5 {
        --gutter-x: 2rem
    }

    .g-xl-5, .gy-xl-5 {
        --gutter-y: 2rem
    }

    .g-xl-6, .gx-xl-6 {
        --gutter-x: 3.5rem
    }

    .g-xl-6, .gy-xl-6 {
        --gutter-y: 3.5rem
    }

    .g-xl-7, .gx-xl-7 {
        --gutter-x: 4rem
    }

    .g-xl-7, .gy-xl-7 {
        --gutter-y: 4rem
    }

    .g-xl-8, .gx-xl-8 {
        --gutter-x: 4.5rem
    }

    .g-xl-8, .gy-xl-8 {
        --gutter-y: 4.5rem
    }

    .g-xl-9, .gx-xl-9 {
        --gutter-x: 5rem
    }

    .g-xl-9, .gy-xl-9 {
        --gutter-y: 5rem
    }

    .g-xl-10, .gx-xl-10 {
        --gutter-x: 6rem
    }

    .g-xl-10, .gy-xl-10 {
        --gutter-y: 6rem
    }

    .g-xl-11, .gx-xl-11 {
        --gutter-x: 7rem
    }

    .g-xl-11, .gy-xl-11 {
        --gutter-y: 7rem
    }

    .g-xl-12, .gx-xl-12 {
        --gutter-x: 8rem
    }

    .g-xl-12, .gy-xl-12 {
        --gutter-y: 8rem
    }

    .g-xl-gs, .gx-xl-gs {
        --gutter-x: 2rem
    }

    .g-xl-gs, .gy-xl-gs {
        --gutter-y: 2rem
    }

    .g-xl-10p, .gx-xl-10p {
        --gutter-x: 10%
    }

    .g-xl-10p, .gy-xl-10p {
        --gutter-y: 10%
    }

    .g-xl-20p, .gx-xl-20p {
        --gutter-x: 20%
    }

    .g-xl-20p, .gy-xl-20p {
        --gutter-y: 20%
    }

    .g-xl-25p, .gx-xl-25p {
        --gutter-x: 25%
    }

    .g-xl-25p, .gy-xl-25p {
        --gutter-y: 25%
    }

    .g-xl-30p, .gx-xl-30p {
        --gutter-x: 30%
    }

    .g-xl-30p, .gy-xl-30p {
        --gutter-y: 30%
    }

    .g-xl-35p, .gx-xl-35p {
        --gutter-x: 35%
    }

    .g-xl-35p, .gy-xl-35p {
        --gutter-y: 35%
    }

    .g-xl-40p, .gx-xl-40p {
        --gutter-x: 40%
    }

    .g-xl-40p, .gy-xl-40p {
        --gutter-y: 40%
    }

    .g-xl-45p, .gx-xl-45p {
        --gutter-x: 45%
    }

    .g-xl-45p, .gy-xl-45p {
        --gutter-y: 45%
    }

    .g-xl-50p, .gx-xl-50p {
        --gutter-x: 50%
    }

    .g-xl-50p, .gy-xl-50p {
        --gutter-y: 50%
    }

    .g-xl-60p, .gx-xl-60p {
        --gutter-x: 60%
    }

    .g-xl-60p, .gy-xl-60p {
        --gutter-y: 60%
    }

    .g-xl-70p, .gx-xl-70p {
        --gutter-x: 70%
    }

    .g-xl-70p, .gy-xl-70p {
        --gutter-y: 70%
    }

    .g-xl-75p, .gx-xl-75p {
        --gutter-x: 75%
    }

    .g-xl-75p, .gy-xl-75p {
        --gutter-y: 75%
    }

    .g-xl-80p, .gx-xl-80p {
        --gutter-x: 80%
    }

    .g-xl-80p, .gy-xl-80p {
        --gutter-y: 80%
    }

    .g-xl-90p, .gx-xl-90p {
        --gutter-x: 90%
    }

    .g-xl-90p, .gy-xl-90p {
        --gutter-y: 90%
    }

    .g-xl-100p, .gx-xl-100p {
        --gutter-x: 100%
    }

    .g-xl-100p, .gy-xl-100p {
        --gutter-y: 100%
    }
}

@media(min-width: 1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0, .gx-xxl-0 {
        --gutter-x: 0
    }

    .g-xxl-0, .gy-xxl-0 {
        --gutter-y: 0
    }

    .g-xxl-0-5, .gx-xxl-0-5 {
        --gutter-x: 0.125rem
    }

    .g-xxl-0-5, .gy-xxl-0-5 {
        --gutter-y: 0.125rem
    }

    .g-xxl-1, .gx-xxl-1 {
        --gutter-x: 0.25rem
    }

    .g-xxl-1, .gy-xxl-1 {
        --gutter-y: 0.25rem
    }

    .g-xxl-2, .gx-xxl-2 {
        --gutter-x: 0.5rem
    }

    .g-xxl-2, .gy-xxl-2 {
        --gutter-y: 0.5rem
    }

    .g-xxl-3, .gx-xxl-3 {
        --gutter-x: 1rem
    }

    .g-xxl-3, .gy-xxl-3 {
        --gutter-y: 1rem
    }

    .g-xxl-4, .gx-xxl-4 {
        --gutter-x: 1.5rem
    }

    .g-xxl-4, .gy-xxl-4 {
        --gutter-y: 1.5rem
    }

    .g-xxl-5, .gx-xxl-5 {
        --gutter-x: 2rem
    }

    .g-xxl-5, .gy-xxl-5 {
        --gutter-y: 2rem
    }

    .g-xxl-6, .gx-xxl-6 {
        --gutter-x: 3.5rem
    }

    .g-xxl-6, .gy-xxl-6 {
        --gutter-y: 3.5rem
    }

    .g-xxl-7, .gx-xxl-7 {
        --gutter-x: 4rem
    }

    .g-xxl-7, .gy-xxl-7 {
        --gutter-y: 4rem
    }

    .g-xxl-8, .gx-xxl-8 {
        --gutter-x: 4.5rem
    }

    .g-xxl-8, .gy-xxl-8 {
        --gutter-y: 4.5rem
    }

    .g-xxl-9, .gx-xxl-9 {
        --gutter-x: 5rem
    }

    .g-xxl-9, .gy-xxl-9 {
        --gutter-y: 5rem
    }

    .g-xxl-10, .gx-xxl-10 {
        --gutter-x: 6rem
    }

    .g-xxl-10, .gy-xxl-10 {
        --gutter-y: 6rem
    }

    .g-xxl-11, .gx-xxl-11 {
        --gutter-x: 7rem
    }

    .g-xxl-11, .gy-xxl-11 {
        --gutter-y: 7rem
    }

    .g-xxl-12, .gx-xxl-12 {
        --gutter-x: 8rem
    }

    .g-xxl-12, .gy-xxl-12 {
        --gutter-y: 8rem
    }

    .g-xxl-gs, .gx-xxl-gs {
        --gutter-x: 2rem
    }

    .g-xxl-gs, .gy-xxl-gs {
        --gutter-y: 2rem
    }

    .g-xxl-10p, .gx-xxl-10p {
        --gutter-x: 10%
    }

    .g-xxl-10p, .gy-xxl-10p {
        --gutter-y: 10%
    }

    .g-xxl-20p, .gx-xxl-20p {
        --gutter-x: 20%
    }

    .g-xxl-20p, .gy-xxl-20p {
        --gutter-y: 20%
    }

    .g-xxl-25p, .gx-xxl-25p {
        --gutter-x: 25%
    }

    .g-xxl-25p, .gy-xxl-25p {
        --gutter-y: 25%
    }

    .g-xxl-30p, .gx-xxl-30p {
        --gutter-x: 30%
    }

    .g-xxl-30p, .gy-xxl-30p {
        --gutter-y: 30%
    }

    .g-xxl-35p, .gx-xxl-35p {
        --gutter-x: 35%
    }

    .g-xxl-35p, .gy-xxl-35p {
        --gutter-y: 35%
    }

    .g-xxl-40p, .gx-xxl-40p {
        --gutter-x: 40%
    }

    .g-xxl-40p, .gy-xxl-40p {
        --gutter-y: 40%
    }

    .g-xxl-45p, .gx-xxl-45p {
        --gutter-x: 45%
    }

    .g-xxl-45p, .gy-xxl-45p {
        --gutter-y: 45%
    }

    .g-xxl-50p, .gx-xxl-50p {
        --gutter-x: 50%
    }

    .g-xxl-50p, .gy-xxl-50p {
        --gutter-y: 50%
    }

    .g-xxl-60p, .gx-xxl-60p {
        --gutter-x: 60%
    }

    .g-xxl-60p, .gy-xxl-60p {
        --gutter-y: 60%
    }

    .g-xxl-70p, .gx-xxl-70p {
        --gutter-x: 70%
    }

    .g-xxl-70p, .gy-xxl-70p {
        --gutter-y: 70%
    }

    .g-xxl-75p, .gx-xxl-75p {
        --gutter-x: 75%
    }

    .g-xxl-75p, .gy-xxl-75p {
        --gutter-y: 75%
    }

    .g-xxl-80p, .gx-xxl-80p {
        --gutter-x: 80%
    }

    .g-xxl-80p, .gy-xxl-80p {
        --gutter-y: 80%
    }

    .g-xxl-90p, .gx-xxl-90p {
        --gutter-x: 90%
    }

    .g-xxl-90p, .gy-xxl-90p {
        --gutter-y: 90%
    }

    .g-xxl-100p, .gx-xxl-100p {
        --gutter-x: 100%
    }

    .g-xxl-100p, .gy-xxl-100p {
        --gutter-y: 100%
    }
}

.table {
    --table-color: var(--body-color);
    --table-bg: transparent;
    --table-border-color: var(--border-color);
    --table-accent-bg: transparent;
    --table-striped-color: var(--body-color);
    --table-striped-bg: rgba(0, 0, 0, 0.05);
    --table-active-color: var(--body-color);
    --table-active-bg: rgba(0, 0, 0, 0.1);
    --table-hover-color: var(--body-color);
    --table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--table-color);
    vertical-align: top;
    border-color: var(--table-border-color)
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: 2px solid currentcolor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --table-accent-bg: var(--table-striped-bg);
    color: var(--table-striped-color)
}

.table-striped-columns>:not(caption)>tr>:nth-child(even) {
    --table-accent-bg: var(--table-striped-bg);
    color: var(--table-striped-color)
}

.table-active {
    --table-accent-bg: var(--table-active-bg);
    color: var(--table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --table-accent-bg: var(--table-hover-bg);
    color: var(--table-hover-color)
}

.table-primary {
    --table-color: #000;
    --table-bg: #cfe2ff;
    --table-border-color: #bacbe6;
    --table-striped-bg: #c5d7f2;
    --table-striped-color: #000;
    --table-active-bg: #bacbe6;
    --table-active-color: #000;
    --table-hover-bg: #bfd1ec;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-secondary {
    --table-color: #000;
    --table-bg: #e2e3e5;
    --table-border-color: #cbccce;
    --table-striped-bg: #d7d8da;
    --table-striped-color: #000;
    --table-active-bg: #cbccce;
    --table-active-color: #000;
    --table-hover-bg: #d1d2d4;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-success {
    --table-color: #000;
    --table-bg: #d1e7dd;
    --table-border-color: #bcd0c7;
    --table-striped-bg: #c7dbd2;
    --table-striped-color: #000;
    --table-active-bg: #bcd0c7;
    --table-active-color: #000;
    --table-hover-bg: #c1d6cc;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-info {
    --table-color: #000;
    --table-bg: #cff4fc;
    --table-border-color: #badce3;
    --table-striped-bg: #c5e8ef;
    --table-striped-color: #000;
    --table-active-bg: #badce3;
    --table-active-color: #000;
    --table-hover-bg: #bfe2e9;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-warning {
    --table-color: #000;
    --table-bg: #fff3cd;
    --table-border-color: #e6dbb9;
    --table-striped-bg: #f2e7c3;
    --table-striped-color: #000;
    --table-active-bg: #e6dbb9;
    --table-active-color: #000;
    --table-hover-bg: #ece1be;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-danger {
    --table-color: #000;
    --table-bg: #f8d7da;
    --table-border-color: #dfc2c4;
    --table-striped-bg: #eccccf;
    --table-striped-color: #000;
    --table-active-bg: #dfc2c4;
    --table-active-color: #000;
    --table-hover-bg: #e5c7ca;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-light {
    --table-color: #000;
    --table-bg: #f8f9fa;
    --table-border-color: #dfe0e1;
    --table-striped-bg: #ecedee;
    --table-striped-color: #000;
    --table-active-bg: #dfe0e1;
    --table-active-color: #000;
    --table-hover-bg: #e5e6e7;
    --table-hover-color: #000;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-dark {
    --table-color: #fff;
    --table-bg: #212529;
    --table-border-color: #373b3e;
    --table-striped-bg: #2c3034;
    --table-striped-color: #fff;
    --table-active-bg: #373b3e;
    --table-active-color: #fff;
    --table-hover-bg: #323539;
    --table-hover-color: #fff;
    color: var(--table-color);
    border-color: var(--table-border-color)
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media(max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem;
    font-size: 0.875em;
    font-weight: 500
}

.col-form-label {
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 500;
    line-height: 1.5rem
}

.col-form-label-lg {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 0.875rem
}

.col-form-label-sm {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
    font-size: 0.703125rem
}

.form-text {
    margin-top: .25rem;
    font-size: 0.875em;
    color: #6b7989
}

.form-control {
    display: block;
    width: 100%;
    padding: .4375rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #e7e7e7;
    background-color: rgb(255 255 255 / 15%);
    background-clip: padding-box;
    border: .0625rem solid rgb(255 255 255 / 30%);
    appearance: none;
    border-radius: .375rem;
    transition: all 0.15s ease-in-out;
}

@media(prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #d7d8d9;
    background-color: rgb(255 255 255 / 10%);
    border-color: rgba(108, 91, 243, .65);
    outline: 0;
    box-shadow: 0 0 5px 0px rgba(95, 56, 249, .2)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5rem
}

.form-control::placeholder {
    color: #9b9ca2;
    opacity: 1
}

.form-control:disabled {
    background-color: #6b79894d;
    opacity: 0.75;
    color: #fff;
    border: 1px solid #ffffff30;
}

.form-control::file-selector-button {
    padding: .4375rem 1.5rem;
    margin: -0.4375rem -1.5rem;
    margin-inline-end: 1.5rem; color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: .0625rem; border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .4375rem 0;
    margin-bottom: 0;
    line-height: 1.5rem;
    color: #6b7989;
    background-color: rgba(0, 0, 0, 0);
    border: solid rgba(0, 0, 0, 0);
    border-width: .0625rem 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: 1.875rem;
    padding: .125rem .75rem;
    font-size: 0.703125rem;
    border-radius: .25rem
}

.form-control-sm::file-selector-button {
    padding: .125rem .75rem;
    margin: -0.125rem -0.75rem;
    margin-inline-end: .75rem }

.form-control-lg {
    min-height: 3rem;
    padding: .65rem 1.25rem;
    font-size: 1rem;
    border-radius: .5rem
}

.form-control-md {
    min-height: 2.75rem;
    padding: .5rem 1.25rem;
    font-size: 0.9rem;
    border-radius: .5rem;
    line-height: 1.5;
}

.form-control-lg::file-selector-button {
    padding: .6875rem 1.5rem;
    margin: -0.6875rem -1.5rem;
    margin-inline-end: 1.5rem }

textarea.form-control {
    min-height: 2.5rem
}

textarea.form-control-sm {
    min-height: 1.875rem
}

textarea.form-control-lg {
    min-height: 3rem
}

.form-control-color {
    width: 3rem;
    height: 2.5rem;
    padding: .4375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: .375rem
}

.form-control-color::-webkit-color-swatch {
    border-radius: .375rem
}

.form-control-color.form-control-sm {
    height: 1.875rem
}

.form-control-color.form-control-lg {
    height: 3rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 #212529
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: 0.875rem;
    border-radius: .25rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.25rem;
    margin-bottom: .125rem;
    cursor: pointer !important;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.25rem
}

.form-check-reverse {
    padding-right: 1.25rem;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.25rem;
    margin-left: 0
}

.form-check-input {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: .175rem;
    vertical-align: top;
    background-color: rgb(255 255 255 / 15%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 0.1rem solid rgb(255 255 255 / 20%);
    appearance: none;
    print-color-adjust: exact;
    cursor: pointer;
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: rgba(108, 91, 243, .65);
    outline: 0;
    box-shadow: 0 0 5px 0px rgba(95, 56, 249, .2)
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary)
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: var(--primary);
    border-color: var(--primary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c5bf3' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input[disabled]~.form-check-label, .form-check-input:disabled~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5rem
}

.form-switch .form-check-input {
    width: 2rem;
    margin-left: -2.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dce3ed'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2rem;
    transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28108, 91, 243, 0.65%29'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5rem;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5rem;
    margin-left: 0
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check[disabled]+.btn, .btn-check:disabled+.btn {
    pointer-events: none;
    filter: none;
    opacity: #76808e
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-color: #dee2e6;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-color: #dee2e6;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: .0625rem solid rgba(0, 0, 0, 0);
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control, .form-floating>.form-control-plaintext {
    padding: 1rem .75rem
}

.form-floating>.form-control::placeholder, .form-floating>.form-control-plaintext::placeholder {
    color: rgba(0, 0, 0, 0)
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown), .form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill, .form-floating>.form-control-plaintext:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-control-plaintext~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: .0625rem 0
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control, .input-group>.form-select, .input-group>.form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus, .input-group>.form-select:focus, .input-group>.form-floating:focus-within {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: .0625rem solid #ced4da;
    border-radius: .375rem
}

.input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text, .input-group-lg>.btn {
    padding: .6875rem 1.5rem;
    font-size: 0.875rem;
    border-radius: .5rem
}

.input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text, .input-group-sm>.btn {
    padding: .125rem .75rem;
    font-size: 0.703125rem;
    border-radius: .25rem
}

.input-group-lg>.form-select, .input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control, .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -0.0625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control, .input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.875em;
    color: #198754
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, .9);
    border-radius: .375rem
}

.was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip, .is-valid~.valid-feedback, .is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid, .form-control.is-valid {
    border-color: #198754;
    padding-right: 2.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .625rem center;
    background-size: 1.25rem 1.25rem
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: 2.5rem;
    background-position: top .625rem right .625rem
}

.was-validated .form-select:valid, .form-select.is-valid {
    border-color: #198754
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
    width: 5.5rem
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #198754
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
    color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.was-validated .input-group>.form-control:not(:focus):valid, .input-group>.form-control:not(:focus).is-valid, .was-validated .input-group>.form-select:not(:focus):valid, .input-group>.form-select:not(:focus).is-valid, .was-validated .input-group>.form-floating:not(:focus-within):valid, .input-group>.form-floating:not(:focus-within).is-valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .375rem
}

.was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip, .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: 2.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .625rem center;
    background-size: 1.25rem 1.25rem
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: 2.5rem;
    background-position: top .625rem right .625rem
}

.was-validated .form-select:invalid, .form-select.is-invalid {
    border-color: #dc3545
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
    width: 5.5rem
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #dc3545
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.was-validated .input-group>.form-control:not(:focus):invalid, .input-group>.form-control:not(:focus).is-invalid, .was-validated .input-group>.form-select:not(:focus):invalid, .input-group>.form-select:not(:focus).is-invalid, .was-validated .input-group>.form-floating:not(:focus-within):invalid, .input-group>.form-floating:not(:focus-within).is-invalid {
    z-index: 4
}

.btn {
    --btn-padding-x: 1.5rem;
    --btn-padding-y: 0.375rem;
    --btn-font-family: var(--body-font-family);
    --btn-font-size: 0.875rem;
    --btn-font-weight: 500;
    --btn-line-height: 1.5rem;
    --btn-color: #6b7989;
    --btn-bg: transparent;
    --btn-border-width: 0.125rem;
    --btn-border-color: transparent;
    --btn-border-radius: 0.375rem;
    --btn-hover-border-color: transparent;
    --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --btn-disabled-opacity: 0.65;
    --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    line-height: var(--btn-line-height);
    color: var(--btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--btn-border-width) solid var(--btn-border-color);
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--btn-color);
    background-color: var(--btn-bg);
    border-color: var(--btn-border-color)
}

.btn:focus-visible {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow)
}

.btn-check:focus-visible+.btn {
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow)
}

.btn-check:checked+.btn, :not(.btn-check)+.btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible, :not(.btn-check)+.btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
    box-shadow: var(--btn-focus-box-shadow)
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity)
}

.btn-primary {
    --btn-color: #fff;
    --btn-bg: var( --primary);
    --btn-border-color: var( --primary);
    --btn-hover-color: #fff;
    --btn-hover-bg: var( --primary-dark);
    --btn-hover-border-color: var( --primary-dark);
    --btn-focus-shadow-rgb: 130, 116, 245;
    --btn-active-color: #fff;
    --btn-active-bg: #7436cf;
    --btn-active-border-color: #7436cf;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: var( --primary);
    --btn-disabled-border-color: var( --primary)
}

.btn-secondary {
    --btn-color: #fff;
    --btn-bg: var(--secondary);
    --btn-border-color: var(--secondary);
    --btn-hover-color: #fff;
    --btn-hover-bg: #0c111f;
    --btn-hover-border-color: #0b101d;
    --btn-focus-shadow-rgb: 50, 55, 69;
    --btn-active-color: #fff;
    --btn-active-bg: #0b101d;
    --btn-active-border-color: #0b0f1b;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: var(--secondary);
    --btn-disabled-border-color: var(--secondary)
}

.btn-success {
    --btn-color: #000;
    --btn-bg: #55b59e;
    --btn-border-color: #55b59e;
    --btn-hover-color: #000;
    --btn-hover-bg: #6fc0ad;
    --btn-hover-border-color: #66bca8;
    --btn-focus-shadow-rgb: 72, 154, 134;
    --btn-active-color: #000;
    --btn-active-bg: #77c4b1;
    --btn-active-border-color: #66bca8;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #000;
    --btn-disabled-bg: #55b59e;
    --btn-disabled-border-color: #55b59e
}

.btn-info {
    --btn-color: #000;
    --btn-bg: #39baf6;
    --btn-border-color: #39baf6;
    --btn-hover-color: #000;
    --btn-hover-bg: #57c4f7;
    --btn-hover-border-color: #4dc1f7;
    --btn-focus-shadow-rgb: 48, 158, 209;
    --btn-active-color: #000;
    --btn-active-bg: #61c8f8;
    --btn-active-border-color: #4dc1f7;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #000;
    --btn-disabled-bg: #39baf6;
    --btn-disabled-border-color: #39baf6
}

.btn-warning {
    --btn-color: #000;
    --btn-bg: #f7c239;
    --btn-border-color: #f7c239;
    --btn-hover-color: #000;
    --btn-hover-bg: #f8cb57;
    --btn-hover-border-color: #f8c84d;
    --btn-focus-shadow-rgb: 210, 165, 48;
    --btn-active-color: #000;
    --btn-active-bg: #f9ce61;
    --btn-active-border-color: #f8c84d;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #000;
    --btn-disabled-bg: #f7c239;
    --btn-disabled-border-color: #f7c239
}

.btn-danger {
    --btn-color: #fff;
    --btn-bg: #ce5a56;
    --btn-border-color: #ce5a56;
    --btn-hover-color: #fff;
    --btn-hover-bg: #af4d49;
    --btn-hover-border-color: #a54845;
    --btn-focus-shadow-rgb: 213, 115, 111;
    --btn-active-color: #fff;
    --btn-active-bg: #a54845;
    --btn-active-border-color: #9b4441;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: #ce5a56;
    --btn-disabled-border-color: #ce5a56
}

.btn-light {
    --btn-color: #000;
    --btn-bg: #f8f9fa;
    --btn-border-color: #f8f9fa;
    --btn-hover-color: #000;
    --btn-hover-bg: #d3d4d5;
    --btn-hover-border-color: #c6c7c8;
    --btn-focus-shadow-rgb: 211, 212, 213;
    --btn-active-color: #000;
    --btn-active-bg: #c6c7c8;
    --btn-active-border-color: #babbbc;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #000;
    --btn-disabled-bg: #f8f9fa;
    --btn-disabled-border-color: #f8f9fa
}

.btn-dark {
    --btn-color: #fff;
    --btn-bg: #1c263b;
    --btn-border-color: #1c263b;
    --btn-hover-color: #fff;
    --btn-hover-bg: #3e4758;
    --btn-hover-border-color: #333c4f;
    --btn-focus-shadow-rgb: 62, 71, 88;
    --btn-active-color: #fff;
    --btn-active-bg: #495162;
    --btn-active-border-color: #333c4f;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: #1c263b;
    --btn-disabled-border-color: #1c263b
}

.btn-darker {
    --btn-color: #fff;
    --btn-bg: var(--secondary);
    --btn-border-color: var(--secondary);
    --btn-hover-color: #fff;
    --btn-hover-bg: #0c111f;
    --btn-hover-border-color: #0b101d;
    --btn-focus-shadow-rgb: 50, 55, 69;
    --btn-active-color: #fff;
    --btn-active-bg: #0b101d;
    --btn-active-border-color: #0b0f1b;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: var(--secondary);
    --btn-disabled-border-color: var(--secondary)
}

.btn-outline-primary {
    --btn-color: var(--primary);
    --btn-border-color: var(--primary);
    --btn-hover-color: #fff;
    --btn-hover-bg: var(--primary);
    --btn-hover-border-color: var(--primary);
    --btn-focus-shadow-rgb: 108, 91, 243;
    --btn-active-color: #fff;
    --btn-active-bg: var(--primary);
    --btn-active-border-color: var(--primary);
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: var(--primary);
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: var(--primary);
    --gradient: none
}

.btn-outline-secondary {
    --btn-color: var(--secondary);
    --btn-border-color: var(--secondary);
    --btn-hover-color: #fff;
    --btn-hover-bg: var(--secondary);
    --btn-hover-border-color: var(--secondary);
    --btn-focus-shadow-rgb: 14, 20, 36;
    --btn-active-color: #fff;
    --btn-active-bg: var(--secondary);
    --btn-active-border-color: var(--secondary);
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: var(--secondary);
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: var(--secondary);
    --gradient: none
}

.btn-outline-success {
    --btn-color: #55b59e;
    --btn-border-color: #55b59e;
    --btn-hover-color: #000;
    --btn-hover-bg: #55b59e;
    --btn-hover-border-color: #55b59e;
    --btn-focus-shadow-rgb: 85, 181, 158;
    --btn-active-color: #000;
    --btn-active-bg: #55b59e;
    --btn-active-border-color: #55b59e;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #55b59e;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #55b59e;
    --gradient: none
}

.btn-outline-info {
    --btn-color: #39baf6;
    --btn-border-color: #39baf6;
    --btn-hover-color: #000;
    --btn-hover-bg: #39baf6;
    --btn-hover-border-color: #39baf6;
    --btn-focus-shadow-rgb: 57, 186, 246;
    --btn-active-color: #000;
    --btn-active-bg: #39baf6;
    --btn-active-border-color: #39baf6;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #39baf6;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #39baf6;
    --gradient: none
}

.btn-outline-warning {
    --btn-color: #f7c239;
    --btn-border-color: #f7c239;
    --btn-hover-color: #000;
    --btn-hover-bg: #f7c239;
    --btn-hover-border-color: #f7c239;
    --btn-focus-shadow-rgb: 247, 194, 57;
    --btn-active-color: #000;
    --btn-active-bg: #f7c239;
    --btn-active-border-color: #f7c239;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #f7c239;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #f7c239;
    --gradient: none
}

.btn-outline-danger {
    --btn-color: #ce5a56;
    --btn-border-color: #ce5a56;
    --btn-hover-color: #fff;
    --btn-hover-bg: #ce5a56;
    --btn-hover-border-color: #ce5a56;
    --btn-focus-shadow-rgb: 206, 90, 86;
    --btn-active-color: #fff;
    --btn-active-bg: #ce5a56;
    --btn-active-border-color: #ce5a56;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #ce5a56;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #ce5a56;
    --gradient: none
}

.btn-outline-light {
    --btn-color: #f8f9fa;
    --btn-border-color: #f8f9fa;
    --btn-hover-color: #000;
    --btn-hover-bg: #f8f9fa;
    --btn-hover-border-color: #f8f9fa;
    --btn-focus-shadow-rgb: 248, 249, 250;
    --btn-active-color: #000;
    --btn-active-bg: #f8f9fa;
    --btn-active-border-color: #f8f9fa;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #f8f9fa;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #f8f9fa;
    --gradient: none
}

.btn-outline-dark {
    --btn-color: #1c263b;
    --btn-border-color: #1c263b;
    --btn-hover-color: #fff;
    --btn-hover-bg: #1c263b;
    --btn-hover-border-color: #1c263b;
    --btn-focus-shadow-rgb: 28, 38, 59;
    --btn-active-color: #fff;
    --btn-active-bg: #1c263b;
    --btn-active-border-color: #1c263b;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #1c263b;
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: #1c263b;
    --gradient: none
}

.btn-outline-darker {
    --btn-color: var(--secondary);
    --btn-border-color: var(--secondary);
    --btn-hover-color: #fff;
    --btn-hover-bg: var(--secondary);
    --btn-hover-border-color: var(--secondary);
    --btn-focus-shadow-rgb: 14, 20, 36;
    --btn-active-color: #fff;
    --btn-active-bg: var(--secondary);
    --btn-active-border-color: var(--secondary);
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: var(--secondary);
    --btn-disabled-bg: transparent;
    --btn-disabled-border-color: var(--secondary);
    --gradient: none
}

.btn-link {
    --btn-font-weight: 400;
    --btn-color: var(--litw-color);
    --btn-bg: transparent;
    --btn-border-color: transparent;
    --btn-hover-color: var(--litw-hover-color);
    --btn-hover-border-color: transparent;
    --btn-active-color: var(--litw-hover-color);
    --btn-active-border-color: transparent;
    --btn-disabled-color: #6b7989;
    --btn-disabled-border-color: transparent;
    --btn-box-shadow: none;
    --btn-focus-shadow-rgb: 130, 116, 245;
    text-decoration: underline
}

.btn-link:focus-visible {
    color: var(--btn-color)
}

.btn-link:hover {
    color: var(--btn-hover-color)
}

.btn-lg, .btn-group-lg>.btn {
    --btn-padding-y: 0.625rem;
    --btn-padding-x: 1.5rem;
    --btn-font-size: 0.875rem;
    --btn-border-radius: 0.5rem
}

.btn-sm, .btn-group-sm>.btn {
    --btn-padding-y: 0.0625rem;
    --btn-padding-x: 0.75rem;
    --btn-font-size: 0.703125rem;
    --btn-border-radius: 0.25rem
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid rgba(0, 0, 0, 0);
    border-bottom: 0;
    border-left: .3em solid rgba(0, 0, 0, 0)
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    --dropdown-zindex: 1000;
    --dropdown-min-width: 10rem;
    --dropdown-padding-x: 0;
    --dropdown-padding-y: 0.5rem;
    --dropdown-spacer: 0.125rem;
    --dropdown-font-size: 1rem;
    --dropdown-color: #212529;
    --dropdown-bg: #fff;
    --dropdown-border-color: var(--border-color-translucent);
    --dropdown-border-radius: 0.375rem;
    --dropdown-border-width: 1px;
    --dropdown-inner-border-radius: calc(0.375rem - 1px);
    --dropdown-divider-bg: var(--border-color-translucent);
    --dropdown-divider-margin-y: 0.5rem;
    --dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --dropdown-litw-color: #212529;
    --dropdown-litw-hover-color: #1e2125;
    --dropdown-litw-hover-bg: #e9ecef;
    --dropdown-litw-active-color: #fff;
    --dropdown-litw-active-bg: #0d6efd;
    --dropdown-litw-disabled-color: #adb5bd;
    --dropdown-item-padding-x: 1rem;
    --dropdown-item-padding-y: 0.25rem;
    --dropdown-header-color: #6c757d;
    --dropdown-header-padding-x: 1rem;
    --dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--dropdown-zindex);
    display: none;
    min-width: var(--dropdown-min-width);
    padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
    margin: 0;
    font-size: var(--dropdown-font-size);
    color: var(--dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--dropdown-bg);
    background-clip: padding-box;
    border: var(--dropdown-border-width) solid var(--dropdown-border-color);
    border-radius: var(--dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--dropdown-spacer)
}

.dropdown-menu-start {
    --position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media(min-width: 576px) {
    .dropdown-menu-sm-start {
        --position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-start {
        --position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-start {
        --position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-start {
        --position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--dropdown-spacer)
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid rgba(0, 0, 0, 0);
    border-bottom: .3em solid;
    border-left: .3em solid rgba(0, 0, 0, 0)
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-right: 0;
    border-bottom: .3em solid rgba(0, 0, 0, 0);
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--dropdown-spacer)
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-right: .3em solid;
    border-bottom: .3em solid rgba(0, 0, 0, 0)
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--dropdown-litw-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    border: 0
}

.dropdown-item:hover, .dropdown-item:focus {
    color: var(--dropdown-litw-hover-color);
    background-color: var(--dropdown-litw-hover-bg)
}

.dropdown-item.active, .dropdown-item:active {
    color: var(--dropdown-litw-active-color);
    text-decoration: none;
    background-color: var(--dropdown-litw-active-bg)
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--dropdown-litw-disabled-color);
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0)
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: var(--dropdown-header-padding-y) var(--dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: 0.703125rem;
    color: var(--dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
    color: var(--dropdown-litw-color)
}

.dropdown-menu-dark {
    --dropdown-color: #dee2e6;
    --dropdown-bg: #343a40;
    --dropdown-border-color: var(--border-color-translucent);
    --dropdown-box-shadow: ;
    --dropdown-litw-color: #dee2e6;
    --dropdown-litw-hover-color: #fff;
    --dropdown-divider-bg: var(--border-color-translucent);
    --dropdown-litw-hover-bg: rgba(255, 255, 255, 0.15);
    --dropdown-litw-active-color: #fff;
    --dropdown-litw-active-bg: #0d6efd;
    --dropdown-litw-disabled-color: #adb5bd;
    --dropdown-header-color: #adb5bd
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn, .btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn:hover, .btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:focus+.btn, .btn-group-vertical>.btn:hover, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: .375rem
}

.btn-group>:not(.btn-check:first-child)+.btn, .btn-group>.btn-group:not(:first-child) {
    margin-left: -0.125rem
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle), .btn-group>.btn.dropdown-toggle-split:first-child, .btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn, .btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -0.125rem
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn~.btn, .btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --nav-litw-padding-x: 1rem;
    --nav-litw-padding-y: 0.5rem;
    --nav-litw-font-weight: ;
    --nav-litw-color: var(--litw-color);
    --nav-litw-hover-color: var(--litw-hover-color);
    --nav-litw-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--nav-litw-padding-y) var(--nav-litw-padding-x);
    font-size: var(--nav-litw-font-size);
    font-weight: var(--nav-litw-font-weight);
    color: var(--nav-litw-color);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:hover, .nav-link:focus {
    color: var(--nav-litw-hover-color)
}

.nav-link.disabled {
    color: var(--nav-litw-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --nav-tabs-border-width: 1px;
    --nav-tabs-border-color: #dee2e6;
    --nav-tabs-border-radius: 0.375rem;
    --nav-tabs-litw-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --nav-tabs-litw-active-color: #495057;
    --nav-tabs-litw-active-bg: #fff;
    --nav-tabs-litw-active-border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1*var(--nav-tabs-border-width));
    background: none;
    border: var(--nav-tabs-border-width) solid rgba(0, 0, 0, 0);
    border-top-left-radius: var(--nav-tabs-border-radius);
    border-top-right-radius: var(--nav-tabs-border-radius)
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    isolation: isolate;
    border-color: var(--nav-tabs-litw-hover-border-color)
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
    color: var(--nav-litw-disabled-color);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0)
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var(--nav-tabs-litw-active-color);
    background-color: var(--nav-tabs-litw-active-bg);
    border-color: var(--nav-tabs-litw-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(-1*var(--nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --nav-pills-border-radius: 0.375rem;
    --nav-pills-litw-active-color: #fff;
    --nav-pills-litw-active-bg: #0d6efd
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    color: var(--nav-litw-disabled-color);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0)
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--nav-pills-litw-active-color);
    background-color: var(--nav-pills-litw-active-bg)
}

.nav-fill>.nav-link, .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified>.nav-link, .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --navbar-padding-x: 0;
    --navbar-padding-y: 0.5rem;
    --navbar-color: rgba(0, 0, 0, 0.55);
    --navbar-hover-color: rgba(0, 0, 0, 0.7);
    --navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --navbar-active-color: rgba(0, 0, 0, 0.9);
    --navbar-brand-padding-y: 0.3125rem;
    --navbar-brand-margin-end: 1rem;
    --navbar-brand-font-size: 1.25rem;
    --navbar-brand-color: rgba(0, 0, 0, 0.9);
    --navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --navbar-nav-litw-padding-x: 0.5rem;
    --navbar-toggler-padding-y: 0.25rem;
    --navbar-toggler-padding-x: 0.75rem;
    --navbar-toggler-font-size: 1.25rem;
    --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --navbar-toggler-border-radius: 0.375rem;
    --navbar-toggler-focus-width: 0.25rem;
    --navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--navbar-padding-y) var(--navbar-padding-x)
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: var(--navbar-brand-padding-y);
    padding-bottom: var(--navbar-brand-padding-y);
    margin-right: var(--navbar-brand-margin-end);
    font-size: var(--navbar-brand-font-size);
    color: var(--navbar-brand-color);
    text-decoration: none;
    white-space: nowrap
}

.navbar-brand:hover, .navbar-brand:focus {
    color: var(--navbar-brand-hover-color)
}

.navbar-nav {
    --nav-litw-padding-x: 0;
    --nav-litw-padding-y: 0.5rem;
    --nav-litw-font-weight: ;
    --nav-litw-color: var(--navbar-color);
    --nav-litw-hover-color: var(--navbar-hover-color);
    --nav-litw-disabled-color: var(--navbar-disabled-color);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .show>.nav-link, .navbar-nav .nav-link.active {
    color: var(--navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: var(--navbar-color)
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
    color: var(--navbar-active-color)
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: var(--navbar-toggler-padding-y) var(--navbar-toggler-padding-x);
    font-size: var(--navbar-toggler-font-size);
    line-height: 1;
    color: var(--navbar-color);
    background-color: rgba(0, 0, 0, 0);
    border: var(--border-width) solid var(--navbar-toggler-border-color);
    border-radius: var(--navbar-toggler-border-radius);
    transition: var(--navbar-toggler-transition)
}

@media(prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--navbar-toggler-focus-width)
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--scroll-height, 75vh);
    overflow-y: auto
}

@media(min-width: 576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--navbar-nav-litw-padding-x);
        padding-left: var(--navbar-nav-litw-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--navbar-nav-litw-padding-x);
        padding-left: var(--navbar-nav-litw-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--navbar-nav-litw-padding-x);
        padding-left: var(--navbar-nav-litw-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--navbar-nav-litw-padding-x);
        padding-left: var(--navbar-nav-litw-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--navbar-nav-litw-padding-x);
        padding-left: var(--navbar-nav-litw-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-litw-padding-x);
    padding-left: var(--navbar-nav-litw-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-dark {
    --navbar-color: rgba(255, 255, 255, 0.55);
    --navbar-hover-color: rgba(255, 255, 255, 0.75);
    --navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --navbar-active-color: #fff;
    --navbar-brand-color: #fff;
    --navbar-brand-hover-color: #fff;
    --navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
    --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    --card-spacer-y: 2rem;
    --card-spacer-x: 2rem;
    --card-title-spacer-y: 0.5rem;
    --card-border-width: 1px;
    --card-border-color: #dce3ed;
    --card-border-radius: 0.375rem;
    --card-box-shadow: ;
    --card-inner-border-radius: calc(0.375rem - 1px);
    --card-cap-padding-y: 1rem;
    --card-cap-padding-x: 2rem;
    --card-cap-bg: rgba(0, 0, 0, 0.03);
    --card-cap-color: ;
    --card-height: ;
    --card-color: ;
    --card-bg: #fff;
    --card-img-overlay-padding: 1rem;
    --card-group-margin: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--card-height);
    word-wrap: break-word;
    background-color: var(--card-bg);
    background-clip: border-box;
    border: var(--card-border-width) solid var(--card-border-color);
    border-radius: var(--card-border-radius)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--card-inner-border-radius);
    border-top-right-radius: var(--card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--card-inner-border-radius);
    border-bottom-left-radius: var(--card-inner-border-radius)
}

.card>.card-header+.list-group, .card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: var(--card-spacer-y) var(--card-spacer-x);
    color: var(--card-color)
}

.card-title {
    margin-bottom: var(--card-title-spacer-y)
}

.card-subtitle {
    margin-top: calc(-0.5*var(--card-title-spacer-y));
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--card-spacer-x)
}

.card-header {
    padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
    margin-bottom: 0;
    color: var(--card-cap-color);
    background-color: var(--card-cap-bg);
    border-bottom: var(--card-border-width) solid var(--card-border-color)
}

.card-header:first-child {
    border-radius: var(--card-inner-border-radius) var(--card-inner-border-radius) 0 0
}

.card-footer {
    padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
    color: var(--card-cap-color);
    background-color: var(--card-cap-bg);
    border-top: var(--card-border-width) solid var(--card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--card-inner-border-radius) var(--card-inner-border-radius)
}

.card-header-tabs {
    margin-right: calc(-0.5*var(--card-cap-padding-x));
    margin-bottom: calc(-1*var(--card-cap-padding-y));
    margin-left: calc(-0.5*var(--card-cap-padding-x));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--card-bg);
    border-bottom-color: var(--card-bg)
}

.card-header-pills {
    margin-right: calc(-0.5*var(--card-cap-padding-x));
    margin-left: calc(-0.5*var(--card-cap-padding-x))
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--card-img-overlay-padding);
    border-radius: var(--card-inner-border-radius)
}

.card-img, .card-img-top, .card-img-bottom {
    width: 100%
}

.card-img, .card-img-top {
    border-top-left-radius: var(--card-inner-border-radius);
    border-top-right-radius: var(--card-inner-border-radius)
}

.card-img, .card-img-bottom {
    border-bottom-right-radius: var(--card-inner-border-radius);
    border-bottom-left-radius: var(--card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--card-group-margin)
}

@media(min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top, .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom, .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top, .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom, .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --accordion-color: #6b7989;
    --accordion-bg: #fff;
    --accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --accordion-border-color: var(--border-color);
    --accordion-border-width: 1px;
    --accordion-border-radius: 0.375rem;
    --accordion-inner-border-radius: calc(0.375rem - 1px);
    --accordion-btn-padding-x: 1.25rem;
    --accordion-btn-padding-y: 1.5rem;
    --accordion-btn-color: #6b7989;
    --accordion-btn-bg: var(--accordion-bg);
    --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236b7989'%3e%3cpath d='M12 15.5c-0 0-0.001 0-0.001 0-0.276 0-0.525-0.112-0.706-0.293l-5-5c-0.174-0.18-0.281-0.425-0.281-0.695 0-0.552 0.448-1 1-1 0.27 0 0.515 0.107 0.695 0.281l4.293 4.293 4.293-4.293c0.18-0.174 0.425-0.281 0.695-0.281 0.552 0 1 0.448 1 1 0 0.27-0.107 0.515-0.281 0.695l-5 5c-0.18 0.181-0.43 0.293-0.706 0.293-0 0-0.001 0-0.001 0h0z'%3e%3c/path%3e%3c/svg%3e");
    --accordion-btn-icon-width: 1.25rem;
    --accordion-btn-icon-transform: rotate(-180deg);
    --accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%236152db'%3e%3cpath d='M12 15.5c-0 0-0.001 0-0.001 0-0.276 0-0.525-0.112-0.706-0.293l-5-5c-0.174-0.18-0.281-0.425-0.281-0.695 0-0.552 0.448-1 1-1 0.27 0 0.515 0.107 0.695 0.281l4.293 4.293 4.293-4.293c0.18-0.174 0.425-0.281 0.695-0.281 0.552 0 1 0.448 1 1 0 0.27-0.107 0.515-0.281 0.695l-5 5c-0.18 0.181-0.43 0.293-0.706 0.293-0 0-0.001 0-0.001 0h0z'%3e%3c/path%3e%3c/svg%3e");
    --accordion-btn-focus-border-color: rgba(108, 91, 243, 0.65);
    --accordion-btn-focus-box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
    --accordion-body-padding-x: 1.25rem;
    --accordion-body-padding-y: 1.5rem;
    --accordion-active-color: #6152db;
    --accordion-active-bg: #f0effe
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
    font-size: 0.9375rem;
    color: var(--accordion-btn-color);
    text-align: left;
    background-color: var(--accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--accordion-transition)
}

@media(prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--accordion-active-color);
    background-color: var(--accordion-active-bg);
    box-shadow: inset 0 calc(-1*var(--accordion-border-width)) 0 var(--accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--accordion-btn-active-icon);
    transform: var(--accordion-btn-icon-transform)
}

.accordion-button::after {
    flex-shrink: 0;
    width: var(--accordion-btn-icon-width);
    height: var(--accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--accordion-btn-icon-width);
    transition: var(--accordion-btn-icon-transition)
}

@media(prefers-reduced-motion: reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--accordion-color);
    background-color: var(--accordion-bg);
    border: var(--accordion-border-width) solid var(--accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--accordion-border-radius);
    border-top-right-radius: var(--accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--accordion-inner-border-radius);
    border-top-right-radius: var(--accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--accordion-border-radius);
    border-bottom-left-radius: var(--accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--accordion-inner-border-radius);
    border-bottom-left-radius: var(--accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--accordion-border-radius);
    border-bottom-left-radius: var(--accordion-border-radius)
}

.accordion-body {
    padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0
}

.breadcrumb {
    --breadcrumb-padding-x: 0;
    --breadcrumb-padding-y: 0;
    --breadcrumb-margin-bottom: 1rem;
    --breadcrumb-bg: ;
    --breadcrumb-border-radius: ;
    --breadcrumb-divider-color: #6c757d;
    --breadcrumb-item-padding-x: 0.5rem;
    --breadcrumb-item-active-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
    margin-bottom: var(--breadcrumb-margin-bottom);
    font-size: var(--breadcrumb-font-size);
    list-style: none;
    background-color: var(--breadcrumb-bg);
    border-radius: var(--breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--breadcrumb-item-padding-x);
    color: var(--breadcrumb-divider-color);
    content: var(--breadcrumb-divider, "../../index-2.html") /* rtl: var(--breadcrumb-divider, "/") */
}

.breadcrumb-item.active {
    color: var(--breadcrumb-item-active-color)
}

.pagination {
    --pagination-padding-x: 0.75rem;
    --pagination-padding-y: 0.375rem;
    --pagination-font-size: 1rem;
    --pagination-color: var(--litw-color);
    --pagination-bg: #fff;
    --pagination-border-width: 1px;
    --pagination-border-color: #dee2e6;
    --pagination-border-radius: 0.375rem;
    --pagination-hover-color: var(--litw-hover-color);
    --pagination-hover-bg: #e9ecef;
    --pagination-hover-border-color: #dee2e6;
    --pagination-focus-color: var(--litw-hover-color);
    --pagination-focus-bg: #e9ecef;
    --pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --pagination-active-color: #fff;
    --pagination-active-bg: #0d6efd;
    --pagination-active-border-color: #0d6efd;
    --pagination-disabled-color: #6c757d;
    --pagination-disabled-bg: #fff;
    --pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--pagination-padding-y) var(--pagination-padding-x);
    font-size: var(--pagination-font-size);
    color: var(--pagination-color);
    text-decoration: none;
    background-color: var(--pagination-bg);
    border: var(--pagination-border-width) solid var(--pagination-border-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--pagination-hover-color);
    background-color: var(--pagination-hover-bg);
    border-color: var(--pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--pagination-focus-color);
    background-color: var(--pagination-focus-bg);
    outline: 0;
    box-shadow: var(--pagination-focus-box-shadow)
}

.page-link.active, .active>.page-link {
    z-index: 3;
    color: var(--pagination-active-color);
    background-color: var(--pagination-active-bg);
    border-color: var(--pagination-active-border-color)
}

.page-link.disabled, .disabled>.page-link {
    color: var(--pagination-disabled-color);
    pointer-events: none;
    background-color: var(--pagination-disabled-bg);
    border-color: var(--pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--pagination-border-radius);
    border-bottom-left-radius: var(--pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--pagination-border-radius);
    border-bottom-right-radius: var(--pagination-border-radius)
}

.pagination-lg {
    --pagination-padding-x: 1.5rem;
    --pagination-padding-y: 0.75rem;
    --pagination-font-size: 1.171875rem;
    --pagination-border-radius: 0.5rem
}

.pagination-sm {
    --pagination-padding-x: 0.5rem;
    --pagination-padding-y: 0.25rem;
    --pagination-font-size: 0.703125rem;
    --pagination-border-radius: 0.25rem
}

.badge {
    --badge-padding-x: 0.65em;
    --badge-padding-y: 0.35em;
    --badge-font-size: 0.75em;
    --badge-font-weight: 700;
    --badge-color: #fff;
    --badge-border-radius: 0.375rem;
    display: inline-block;
    padding: var(--badge-padding-y) var(--badge-padding-x);
    font-size: var(--badge-font-size);
    font-weight: var(--badge-font-weight);
    line-height: 1;
    color: var(--badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--badge-border-radius)
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    --alert-bg: transparent;
    --alert-padding-x: 15px;
    --alert-padding-y: 12px;
    --alert-margin-bottom: 1rem;
    --alert-color: inherit;
    --alert-border-color: transparent;
    --alert-border: 1px solid var( --alert-border-color);
    --alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--alert-padding-y) var(--alert-padding-x);
    margin-bottom: var(--alert-margin-bottom);
    color: var(--alert-color);
    background-color: var(--alert-bg);
    border: var(--alert-border);
    border-radius: var(--alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    --alert-color: #413792;
    --alert-bg: #e2defd;
    --alert-border-color: #d3cefb
}

.alert-primary .alert-link {
    color: #342c75
}

.alert-secondary {
    --alert-color: #080c16;
    --alert-bg: #cfd0d3;
    --alert-border-color: #b7b9bd
}

.alert-secondary .alert-link {
    color: #060a12
}

.alert-success {
    --alert-color: #336d5f;
    --alert-bg: #ddf0ec;
    --alert-border-color: #cce9e2
}

.alert-success .alert-link {
    color: #29574c
}

.alert-info {
    --alert-color: #227094;
    --alert-bg: #d7f1fd;
    --alert-border-color: #c4eafc
}

.alert-info .alert-link {
    color: #1b5a76
}

.alert-warning {
    --alert-color: #947422;
    --alert-bg: #fdf3d7;
    --alert-border-color: #fdedc4
}

.alert-warning .alert-link {
    color: #765d1b
}

.alert-danger {
    --alert-color: #7c3634;
    --alert-bg: #f5dedd;
    --alert-border-color: #f0cecc
}

.alert-danger .alert-link {
    color: #632b2a
}

.alert-light {
    --alert-color: #959596;
    --alert-bg: #fefefe;
    --alert-border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #777778
}

.alert-dark {
    --alert-color: #111723;
    --alert-bg: #d2d4d8;
    --alert-border-color: #bbbec4
}

.alert-dark .alert-link {
    color: #0e121c
}

.alert-darker {
    --alert-color: #080c16;
    --alert-bg: #cfd0d3;
    --alert-border-color: #b7b9bd
}

.alert-darker .alert-link {
    color: #060a12
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    --progress-height: 1rem;
    --progress-font-size: 0.75rem;
    --progress-bg: #e9ecef;
    --progress-border-radius: 0.375rem;
    --progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --progress-bar-color: #fff;
    --progress-bar-bg: #0d6efd;
    --progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--progress-height);
    overflow: hidden;
    font-size: var(--progress-font-size);
    background-color: var(--progress-bg);
    border-radius: var(--progress-border-radius)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--progress-bar-bg);
    transition: var(--progress-bar-transition)
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: var(--progress-height) var(--progress-height)
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    --list-group-color: #212529;
    --list-group-bg: #fff;
    --list-group-border-color: rgba(0, 0, 0, 0.125);
    --list-group-border-width: 1px;
    --list-group-border-radius: 0.375rem;
    --list-group-item-padding-x: 1rem;
    --list-group-item-padding-y: 0.5rem;
    --list-group-action-color: #495057;
    --list-group-action-hover-color: #495057;
    --list-group-action-hover-bg: #f8f9fa;
    --list-group-action-active-color: #212529;
    --list-group-action-active-bg: #e9ecef;
    --list-group-disabled-color: #6c757d;
    --list-group-disabled-bg: #fff;
    --list-group-active-color: #fff;
    --list-group-active-bg: #0d6efd;
    --list-group-active-border-color: #0d6efd;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: var(--list-group-action-color);
    text-align: inherit
}

.list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--list-group-action-active-color);
    background-color: var(--list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--list-group-item-padding-y) var(--list-group-item-padding-x);
    color: var(--list-group-color);
    text-decoration: none;
    background-color: var(--list-group-bg);
    border: var(--list-group-border-width) solid var(--list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
    color: var(--list-group-disabled-color);
    pointer-events: none;
    background-color: var(--list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--list-group-active-color);
    background-color: var(--list-group-active-bg);
    border-color: var(--list-group-active-border-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1*var(--list-group-border-width));
    border-top-width: var(--list-group-border-width)
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1*var(--list-group-border-width));
    border-left-width: var(--list-group-border-width)
}

@media(min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1*var(--list-group-border-width));
        border-left-width: var(--list-group-border-width)
    }
}

@media(min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1*var(--list-group-border-width));
        border-left-width: var(--list-group-border-width)
    }
}

@media(min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1*var(--list-group-border-width));
        border-left-width: var(--list-group-border-width)
    }
}

@media(min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1*var(--list-group-border-width));
        border-left-width: var(--list-group-border-width)
    }
}

@media(min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1*var(--list-group-border-width));
        border-left-width: var(--list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #413792;
    background-color: #e2defd
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #413792;
    background-color: #cbc8e4
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #413792;
    border-color: #413792
}

.list-group-item-secondary {
    color: #080c16;
    background-color: #cfd0d3
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #080c16;
    background-color: #babbbe
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #080c16;
    border-color: #080c16
}

.list-group-item-success {
    color: #336d5f;
    background-color: #ddf0ec
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #336d5f;
    background-color: #c7d8d4
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #336d5f;
    border-color: #336d5f
}

.list-group-item-info {
    color: #227094;
    background-color: #d7f1fd
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #227094;
    background-color: #c2d9e4
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #227094;
    border-color: #227094
}

.list-group-item-warning {
    color: #947422;
    background-color: #fdf3d7
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #947422;
    background-color: #e4dbc2
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #947422;
    border-color: #947422
}

.list-group-item-danger {
    color: #7c3634;
    background-color: #f5dedd
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c3634;
    background-color: #ddc8c7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c3634;
    border-color: #7c3634
}

.list-group-item-light {
    color: #959596;
    background-color: #fefefe
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #959596;
    background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #959596;
    border-color: #959596
}

.list-group-item-dark {
    color: #111723;
    background-color: #d2d4d8
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #111723;
    background-color: #bdbfc2
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #111723;
    border-color: #111723
}

.list-group-item-darker {
    color: #080c16;
    background-color: #cfd0d3
}

.list-group-item-darker.list-group-item-action:hover, .list-group-item-darker.list-group-item-action:focus {
    color: #080c16;
    background-color: #babbbe
}

.list-group-item-darker.list-group-item-action.active {
    color: #fff;
    background-color: #080c16;
    border-color: #080c16
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: rgba(0, 0, 0, 0) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    opacity: 1
}

.btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    --toast-zindex: 1090;
    --toast-padding-x: 0.75rem;
    --toast-padding-y: 0.5rem;
    --toast-spacing: 1.5rem;
    --toast-max-width: 350px;
    --toast-font-size: 0.875rem;
    --toast-color: ;
    --toast-bg: rgba(255, 255, 255, 0.85);
    --toast-border-width: 1px;
    --toast-border-color: var(--border-color-translucent);
    --toast-border-radius: 0.375rem;
    --toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --toast-header-color: #6c757d;
    --toast-header-bg: rgba(255, 255, 255, 0.85);
    --toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--toast-max-width);
    max-width: 100%;
    font-size: var(--toast-font-size);
    color: var(--toast-color);
    pointer-events: auto;
    background-color: var(--toast-bg);
    background-clip: padding-box;
    border: var(--toast-border-width) solid var(--toast-border-color);
    box-shadow: var(--toast-box-shadow);
    border-radius: var(--toast-border-radius)
}

.toast.showing {
    opacity: 0
}

.toast-container {
    --toast-zindex: 1090;
    position: absolute;
    z-index: var(--toast-zindex);
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--toast-spacing)
}

.toast-header {
    display: flex;
    align-items: center;
    padding: var(--toast-padding-y) var(--toast-padding-x);
    color: var(--toast-header-color);
    background-color: var(--toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--toast-border-width) solid var(--toast-header-border-color);
    border-top-left-radius: calc(var(--toast-border-radius) - var(--toast-border-width));
    border-top-right-radius: calc(var(--toast-border-radius) - var(--toast-border-width))
}

.toast-header .btn-close {
    margin-right: calc(-0.5*var(--toast-padding-x));
    margin-left: var(--toast-padding-x)
}

.toast-body {
    padding: var(--toast-padding-x);
    word-wrap: break-word
}

.modal {
    --modal-zindex: 1055;
    --modal-width: 500px;
    --modal-padding: 1rem;
    --modal-margin: 0.5rem;
    --modal-color: ;
    --modal-bg: #fff;
    --modal-border-color: var(--border-color-translucent);
    --modal-border-width: 1px;
    --modal-border-radius: 0.5rem;
    --modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --modal-inner-border-radius: calc(0.5rem - 1px);
    --modal-header-padding-x: 1rem;
    --modal-header-padding-y: 1rem;
    --modal-header-padding: 1rem 1rem;
    --modal-header-border-color: var(--border-color);
    --modal-header-border-width: 1px;
    --modal-title-line-height: 1.5;
    --modal-footer-gap: 0.5rem;
    --modal-footer-bg: ;
    --modal-footer-border-color: var(--border-color);
    --modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media(prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--modal-margin)*2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--modal-margin)*2)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--modal-color);
    pointer-events: auto;
    background-color: var(--modal-bg);
    background-clip: padding-box;
    border: var(--modal-border-width) solid var(--modal-border-color);
    border-radius: var(--modal-border-radius);
    outline: 0
}

.modal-backdrop {
    --backdrop-zindex: 1050;
    --backdrop-bg: #000;
    --backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--backdrop-opacity)
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--modal-header-padding);
    border-bottom: var(--modal-header-border-width) solid var(--modal-header-border-color);
    border-top-left-radius: var(--modal-inner-border-radius);
    border-top-right-radius: var(--modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--modal-header-padding-y)*.5) calc(var(--modal-header-padding-x)*.5);
    margin: calc(-0.5*var(--modal-header-padding-y)) calc(-0.5*var(--modal-header-padding-x)) calc(-0.5*var(--modal-header-padding-y)) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--modal-title-line-height)
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--modal-padding)
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--modal-padding) - var(--modal-footer-gap)*.5);
    background-color: var(--modal-footer-bg);
    border-top: var(--modal-footer-border-width) solid var(--modal-footer-border-color);
    border-bottom-right-radius: var(--modal-inner-border-radius);
    border-bottom-left-radius: var(--modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--modal-footer-gap)*.5)
}

@media(min-width: 576px) {
    .modal {
        --modal-margin: 1.75rem;
        --modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)
    }

    .modal-dialog {
        max-width: var(--modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --modal-width: 300px
    }
}

@media(min-width: 992px) {
    .modal-lg, .modal-xl {
        --modal-width: 800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        --modal-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media(max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media(max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media(max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media(max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media(max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --tooltip-zindex: 1080;
    --tooltip-max-width: 200px;
    --tooltip-padding-x: 0.5rem;
    --tooltip-padding-y: 0.25rem;
    --tooltip-margin: ;
    --tooltip-font-size: 0.875rem;
    --tooltip-color: #fff;
    --tooltip-bg: #000;
    --tooltip-border-radius: 0.375rem;
    --tooltip-opacity: 0.9;
    --tooltip-arrow-width: 0.8rem;
    --tooltip-arrow-height: 0.4rem;
    z-index: var(--tooltip-zindex);
    display: block;
    padding: var(--tooltip-arrow-height);
    margin: var(--tooltip-margin);
    font-family: var(--font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--tooltip-font-size);
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: var(--tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--tooltip-arrow-width);
    height: var(--tooltip-arrow-height)
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: rgba(0, 0, 0, 0);
    border-style: solid
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--tooltip-arrow-height) calc(var(--tooltip-arrow-width)*.5) 0;
    border-top-color: var(--tooltip-bg)
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    left: 0;
    width: var(--tooltip-arrow-height);
    height: var(--tooltip-arrow-width)
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--tooltip-arrow-width)*.5) var(--tooltip-arrow-height) calc(var(--tooltip-arrow-width)*.5) 0;
    border-right-color: var(--tooltip-bg)
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--tooltip-arrow-width)*.5) var(--tooltip-arrow-height);
    border-bottom-color: var(--tooltip-bg)
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    right: 0;
    width: var(--tooltip-arrow-height);
    height: var(--tooltip-arrow-width)
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--tooltip-arrow-width)*.5) 0 calc(var(--tooltip-arrow-width)*.5) var(--tooltip-arrow-height);
    border-left-color: var(--tooltip-bg)
}

.tooltip-inner {
    max-width: var(--tooltip-max-width);
    padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
    color: var(--tooltip-color);
    text-align: center;
    background-color: var(--tooltip-bg);
    border-radius: var(--tooltip-border-radius)
}

.popover {
    --popover-zindex: 1070;
    --popover-max-width: 276px;
    --popover-font-size: 0.875rem;
    --popover-bg: #fff;
    --popover-border-width: 1px;
    --popover-border-color: var(--border-color-translucent);
    --popover-border-radius: 0.5rem;
    --popover-inner-border-radius: calc(0.5rem - 1px);
    --popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --popover-header-padding-x: 1rem;
    --popover-header-padding-y: 0.5rem;
    --popover-header-font-size: 1rem;
    --popover-header-color: #1c263b;
    --popover-header-bg: #f0f0f0;
    --popover-body-padding-x: 1rem;
    --popover-body-padding-y: 1rem;
    --popover-body-color: #212529;
    --popover-arrow-width: 1rem;
    --popover-arrow-height: 0.5rem;
    --popover-arrow-border: var(--popover-border-color);
    z-index: var(--popover-zindex);
    display: block;
    max-width: var(--popover-max-width);
    font-family: var(--font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--popover-font-size);
    word-wrap: break-word;
    background-color: var(--popover-bg);
    background-clip: padding-box;
    border: var(--popover-border-width) solid var(--popover-border-color);
    border-radius: var(--popover-border-radius)
}

.popover .popover-arrow {
    display: block;
    width: var(--popover-arrow-width);
    height: var(--popover-arrow-height)
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 0
}

.bs-popover-top>.popover-arrow, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
    bottom: calc(-1*(var(--popover-arrow-height)) - var(--popover-border-width))
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before, .bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    border-width: var(--popover-arrow-height) calc(var(--popover-arrow-width)*.5) 0
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    bottom: 0;
    border-top-color: var(--popover-arrow-border)
}

.bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    bottom: var(--popover-border-width);
    border-top-color: var(--popover-bg)
}

.bs-popover-end>.popover-arrow, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
    left: calc(-1*(var(--popover-arrow-height)) - var(--popover-border-width));
    width: var(--popover-arrow-height);
    height: var(--popover-arrow-width)
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before, .bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    border-width: calc(var(--popover-arrow-width)*.5) var(--popover-arrow-height) calc(var(--popover-arrow-width)*.5) 0
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    left: 0;
    border-right-color: var(--popover-arrow-border)
}

.bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    left: var(--popover-border-width);
    border-right-color: var(--popover-bg)
}

.bs-popover-bottom>.popover-arrow, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
    top: calc(-1*(var(--popover-arrow-height)) - var(--popover-border-width))
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, .bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-width: 0 calc(var(--popover-arrow-width)*.5) var(--popover-arrow-height)
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    top: 0;
    border-bottom-color: var(--popover-arrow-border)
}

.bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    top: var(--popover-border-width);
    border-bottom-color: var(--popover-bg)
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--popover-arrow-width);
    margin-left: calc(-0.5*var(--popover-arrow-width));
    content: "";
    border-bottom: var(--popover-border-width) solid var(--popover-header-bg)
}

.bs-popover-start>.popover-arrow, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
    right: calc(-1*(var(--popover-arrow-height)) - var(--popover-border-width));
    width: var(--popover-arrow-height);
    height: var(--popover-arrow-width)
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, .bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    border-width: calc(var(--popover-arrow-width)*.5) 0 calc(var(--popover-arrow-width)*.5) var(--popover-arrow-height)
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    right: 0;
    border-left-color: var(--popover-arrow-border)
}

.bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    right: var(--popover-border-width);
    border-left-color: var(--popover-bg)
}

.popover-header {
    padding: var(--popover-header-padding-y) var(--popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--popover-header-font-size);
    color: var(--popover-header-color);
    background-color: var(--popover-header-bg);
    border-bottom: var(--popover-border-width) solid var(--popover-border-color);
    border-top-left-radius: var(--popover-inner-border-radius);
    border-top-right-radius: var(--popover-inner-border-radius)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: var(--popover-body-padding-y) var(--popover-body-padding-x);
    color: var(--popover-body-color)
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
        transition: none
    }
}

.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion: reduce) {
    .carousel-control-prev, .carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 10px solid rgba(0, 0, 0, 0);
    opacity: .5;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

.spinner-grow, .spinner-border {
    display: inline-block;
    width: var(--spinner-width);
    height: var(--spinner-height);
    vertical-align: var(--spinner-vertical-align);
    border-radius: 50%;
    animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name)
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg) /* rtl:ignore */
    }
}

.spinner-border {
    --spinner-width: 2rem;
    --spinner-height: 2rem;
    --spinner-vertical-align: -0.125em;
    --spinner-border-width: 0.25em;
    --spinner-animation-speed: 0.75s;
    --spinner-animation-name: spinner-border;
    border: var(--spinner-border-width) solid currentcolor;
    border-right-color: rgba(0, 0, 0, 0)
}

.spinner-border-sm {
    --spinner-width: 1rem;
    --spinner-height: 1rem;
    --spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    --spinner-width: 2rem;
    --spinner-height: 2rem;
    --spinner-vertical-align: -0.125em;
    --spinner-animation-speed: 0.75s;
    --spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --spinner-width: 1rem;
    --spinner-height: 1rem
}

@media(prefers-reduced-motion: reduce) {
    .spinner-border, .spinner-grow {
        --spinner-animation-speed: 1.5s
    }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
    --offcanvas-zindex: 1045;
    --offcanvas-width: 400px;
    --offcanvas-height: 30vh;
    --offcanvas-padding-x: 1rem;
    --offcanvas-padding-y: 1rem;
    --offcanvas-color: ;
    --offcanvas-bg: #fff;
    --offcanvas-border-width: 1px;
    --offcanvas-border-color: var(--border-color-translucent);
    --offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)
}

@media(max-width: 575.98px) {
    .offcanvas-sm {
        position: fixed;
        bottom: 0;
        z-index: var(--offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--offcanvas-color);
        visibility: hidden;
        background-color: var(--offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media(max-width: 575.98px)and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
        transition: none
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--offcanvas-width);
        border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--offcanvas-width);
        border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
        transform: none
    }
}

@media(max-width: 575.98px) {
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
        visibility: visible
    }
}

@media(min-width: 576px) {
    .offcanvas-sm {
        --offcanvas-height: auto;
        --offcanvas-border-width: 0;
        background-color: rgba(0, 0, 0, 0) !important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0) !important
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md {
        position: fixed;
        bottom: 0;
        z-index: var(--offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--offcanvas-color);
        visibility: hidden;
        background-color: var(--offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media(max-width: 767.98px)and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
        transition: none
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--offcanvas-width);
        border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--offcanvas-width);
        border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
        transform: none
    }
}

@media(max-width: 767.98px) {
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
        visibility: visible
    }
}

@media(min-width: 768px) {
    .offcanvas-md {
        --offcanvas-height: auto;
        --offcanvas-border-width: 0;
        background-color: rgba(0, 0, 0, 0) !important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0) !important
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg {
        position: fixed;
        bottom: 0;
        z-index: var(--offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--offcanvas-color);
        visibility: hidden;
        background-color: var(--offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media(max-width: 991.98px)and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
        transition: none
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--offcanvas-width);
        border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--offcanvas-width);
        border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
        transform: none
    }
}

@media(max-width: 991.98px) {
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
        visibility: visible
    }
}

@media(min-width: 992px) {
    .offcanvas-lg {
        --offcanvas-height: auto;
        --offcanvas-border-width: 0;
        background-color: rgba(0, 0, 0, 0) !important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0) !important
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl {
        position: fixed;
        bottom: 0;
        z-index: var(--offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--offcanvas-color);
        visibility: hidden;
        background-color: var(--offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media(max-width: 1199.98px)and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
        transition: none
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--offcanvas-width);
        border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--offcanvas-width);
        border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
        transform: none
    }
}

@media(max-width: 1199.98px) {
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
        visibility: visible
    }
}

@media(min-width: 1200px) {
    .offcanvas-xl {
        --offcanvas-height: auto;
        --offcanvas-border-width: 0;
        background-color: rgba(0, 0, 0, 0) !important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0) !important
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl {
        position: fixed;
        bottom: 0;
        z-index: var(--offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--offcanvas-color);
        visibility: hidden;
        background-color: var(--offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media(max-width: 1399.98px)and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
        transition: none
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--offcanvas-width);
        border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--offcanvas-width);
        border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--offcanvas-height);
        max-height: 100%;
        border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
        transform: none
    }
}

@media(max-width: 1399.98px) {
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
        visibility: visible
    }
}

@media(min-width: 1400px) {
    .offcanvas-xxl {
        --offcanvas-height: auto;
        --offcanvas-border-width: 0;
        background-color: rgba(0, 0, 0, 0) !important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: rgba(0, 0, 0, 0) !important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%)
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--offcanvas-padding-y)*.5) calc(var(--offcanvas-padding-x)*.5);
    margin-top: calc(-0.5*var(--offcanvas-padding-y));
    margin-right: calc(-0.5*var(--offcanvas-padding-x));
    margin-bottom: calc(-0.5*var(--offcanvas-padding-y))
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    flex-grow: 1;
    padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        mask-position: -200% 0%
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-primary {
    color: #fff !important;
    background-color: RGBA(108, 91, 243, var(--bg-opacity, 1)) !important
}

.text-bg-secondary {
    color: #fff !important;
    background-color: RGBA(14, 20, 36, var(--bg-opacity, 1)) !important
}

.text-bg-success {
    color: #000 !important;
    background-color: RGBA(85, 181, 158, var(--bg-opacity, 1)) !important
}

.text-bg-info {
    color: #000 !important;
    background-color: RGBA(57, 186, 246, var(--bg-opacity, 1)) !important
}

.text-bg-warning {
    color: #000 !important;
    background-color: RGBA(247, 194, 57, var(--bg-opacity, 1)) !important
}

.text-bg-danger {
    color: #fff !important;
    background-color: RGBA(206, 90, 86, var(--bg-opacity, 1)) !important
}

.text-bg-light {
    color: #000 !important;
    background-color: RGBA(248, 249, 250, var(--bg-opacity, 1)) !important
}

.text-bg-dark {
    color: #fff !important;
    background-color: RGBA(28, 38, 59, var(--bg-opacity, 1)) !important
}

.text-bg-darker {
    color: #fff !important;
    background-color: RGBA(14, 20, 36, var(--bg-opacity, 1)) !important
}

.litw-primary {
    color: var(--primary) !important
}

.litw-primary:hover, .litw-primary:focus {
    color: var(--primary-dark) !important
}

.litw-secondary {
    color: var(--secondary) !important
}

.litw-secondary:hover, .litw-secondary:focus {
    color: #0b101d !important
}

.litw-success {
    color: #55b59e !important
}

.litw-success:hover, .litw-success:focus {
    color: #77c4b1 !important
}

.litw-info {
    color: #39baf6 !important
}

.litw-info:hover, .litw-info:focus {
    color: #61c8f8 !important
}

.litw-warning {
    color: #f7c239 !important
}

.litw-warning:hover, .litw-warning:focus {
    color: #f9ce61 !important
}

.litw-danger {
    color: #ce5a56 !important
}

.litw-danger:hover, .litw-danger:focus {
    color: #a54845 !important
}

.litw-light {
    color: #f8f9fa !important
}

.litw-light:hover, .litw-light:focus {
    color: #f9fafb !important
}

.litw-dark {
    color: #1c263b !important
}

.litw-dark:hover, .litw-dark:focus {
    color: #161e2f !important
}

.litw-darker {
    color: var(--secondary) !important
}

.litw-darker:hover, .litw-darker:focus {
    color: #0b101d !important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --aspect-ratio: 100%
}

.ratio-4x3 {
    --aspect-ratio: 75%
}

.ratio-16x9 {
    --aspect-ratio: 56.25%
}

.ratio-21x9 {
    --aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media(min-width: 576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media(min-width: 768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media(min-width: 992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media(min-width: 1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media(min-width: 1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-10 {
    opacity: .1 !important
}

.opacity-20 {
    opacity: .2 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-30 {
    opacity: .3 !important
}

.opacity-40 {
    opacity: .4 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-60 {
    opacity: .6 !important
}

.opacity-70 {
    opacity: .7 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-80 {
    opacity: .8 !important
}

.opacity-90 {
    opacity: .9 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow-none {
    box-shadow: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-tiny {
    box-shadow: 0px 4px 10px 0px rgba(108, 91, 243, .05) !important
}

.shadow-xs {
    box-shadow: 0px 4px 12px 0px rgba(108, 91, 243, .1) !important
}

.shadow-sm {
    box-shadow: 0px 5px 36px rgba(0, 0, 0, .06) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-auto {
    top: auto !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-75 {
    top: 75% !important
}

.top-100 {
    top: 100% !important
}

.bottom-auto {
    bottom: auto !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-auto {
    left: auto !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-auto {
    right: auto !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: var(--border-width) var(--border-style) var(--border-color) !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: var(--border-width) var(--border-style) var(--border-color) !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: var(--border-width) var(--border-style) var(--border-color) !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: var(--border-width) var(--border-style) var(--border-color) !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: var(--border-width) var(--border-style) var(--border-color) !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    --border-opacity: 1;
    border-color: rgba(var(--primary-rgb), var(--border-opacity)) !important
}

.border-secondary {
    --border-opacity: 1;
    border-color: rgba(var(--secondary-rgb), var(--border-opacity)) !important
}

.border-success {
    --border-opacity: 1;
    border-color: rgba(var(--success-rgb), var(--border-opacity)) !important
}

.border-info {
    --border-opacity: 1;
    border-color: rgba(var(--info-rgb), var(--border-opacity)) !important
}

.border-warning {
    --border-opacity: 1;
    border-color: rgba(var(--warning-rgb), var(--border-opacity)) !important
}

.border-danger {
    --border-opacity: 1;
    border-color: rgba(var(--danger-rgb), var(--border-opacity)) !important
}

.border-light {
    --border-opacity: 1;
    border-color: rgba(var(--light-rgb), var(--border-opacity)) !important
}
.border-transparent {
	border-color: transparent !important
}

.border-dark {
    --border-opacity: 1;
    border-color: rgba(var(--dark-rgb), var(--border-opacity)) !important
}

.border-darker {
    --border-opacity: 1;
    border-color: rgba(var(--darker-rgb), var(--border-opacity)) !important
}

.border-white {
    --border-opacity: 1;
    border-color: rgba(var(--white-rgb), var(--border-opacity)) !important
}

.border-1 {
    --border-width: 1px
}

.border-2 {
    --border-width: 2px
}

.border-3 {
    --border-width: 3px
}

.border-4 {
    --border-width: 4px
}

.border-5 {
    --border-width: 5px
}

.border-opacity-10 {
    --border-opacity: 0.1
}

.border-opacity-25 {
    --border-opacity: 0.25
}

.border-opacity-50 {
    --border-opacity: 0.5
}

.border-opacity-75 {
    --border-opacity: 0.75
}

.border-opacity-100 {
    --border-opacity: 1
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-1rem {
    width: 1rem !important
}

.w-1-5rem {
    width: 1.5rem !important
}

.w-2rem {
    width: 2rem !important
}

.w-3rem {
    width: 3rem !important
}

.w-4rem {
    width: 4rem !important
}

.w-5rem {
    width: 5rem !important
}

.w-6rem {
    width: 6rem !important
}

.w-7rem {
    width: 7rem !important
}

.w-8rem {
    width: 8rem !important
}

.w-10rem {
    width: 10rem !important
}

.w-12rem {
    width: 12rem !important
}

.w-16rem {
    width: 16rem !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-1rem {
    height: 1rem !important
}

.h-1-5rem {
    height: 1.5rem !important
}

.h-2rem {
    height: 2rem !important
}

.h-3rem {
    height: 3rem !important
}

.h-4rem {
    height: 4rem !important
}

.h-5rem {
    height: 5rem !important
}

.h-6rem {
    height: 6rem !important
}

.h-7rem {
    height: 7rem !important
}

.h-8rem {
    height: 8rem !important
}

.h-10rem {
    height: 10rem !important
}

.h-12rem {
    height: 12rem !important
}

.h-16rem {
    height: 16rem !important
}

.mh-100 {
    max-height: 100% !important
}

.mh-75vh {
    max-height: 75vh !important
}

.mh-50vh {
    max-height: 50vh !important
}

.mh-100vh {
    max-height: 100vh !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shritw-0 {
    flex-shrink: 0 !important
}

.flex-shritw-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-0-5 {
    margin: .125rem !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 2rem !important
}

.m-6 {
    margin: 3.5rem !important
}

.m-7 {
    margin: 4rem !important
}

.m-8 {
    margin: 4.5rem !important
}

.m-9 {
    margin: 5rem !important
}

.m-10 {
    margin: 6rem !important
}

.m-11 {
    margin: 7rem !important
}

.m-12 {
    margin: 8rem !important
}

.m-gs {
    margin: 2rem !important
}

.m-10p {
    margin: 10% !important
}

.m-20p {
    margin: 20% !important
}

.m-25p {
    margin: 25% !important
}

.m-30p {
    margin: 30% !important
}

.m-35p {
    margin: 35% !important
}

.m-40p {
    margin: 40% !important
}

.m-45p {
    margin: 45% !important
}

.m-50p {
    margin: 50% !important
}

.m-60p {
    margin: 60% !important
}

.m-70p {
    margin: 70% !important
}

.m-75p {
    margin: 75% !important
}

.m-80p {
    margin: 80% !important
}

.m-90p {
    margin: 90% !important
}

.m-100p {
    margin: 100% !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-0-5 {
    margin-right: .125rem !important;
    margin-left: .125rem !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important
}

.mx-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important
}

.mx-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important
}

.mx-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
}

.mx-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important
}

.mx-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important
}

.mx-11 {
    margin-right: 7rem !important;
    margin-left: 7rem !important
}

.mx-12 {
    margin-right: 8rem !important;
    margin-left: 8rem !important
}

.mx-gs {
    margin-right: 2rem !important;
    margin-left: 2rem !important
}

.mx-10p {
    margin-right: 10% !important;
    margin-left: 10% !important
}

.mx-20p {
    margin-right: 20% !important;
    margin-left: 20% !important
}

.mx-25p {
    margin-right: 25% !important;
    margin-left: 25% !important
}

.mx-30p {
    margin-right: 30% !important;
    margin-left: 30% !important
}

.mx-35p {
    margin-right: 35% !important;
    margin-left: 35% !important
}

.mx-40p {
    margin-right: 40% !important;
    margin-left: 40% !important
}

.mx-45p {
    margin-right: 45% !important;
    margin-left: 45% !important
}

.mx-50p {
    margin-right: 50% !important;
    margin-left: 50% !important
}

.mx-60p {
    margin-right: 60% !important;
    margin-left: 60% !important
}

.mx-70p {
    margin-right: 70% !important;
    margin-left: 70% !important
}

.mx-75p {
    margin-right: 75% !important;
    margin-left: 75% !important
}

.mx-80p {
    margin-right: 80% !important;
    margin-left: 80% !important
}

.mx-90p {
    margin-right: 90% !important;
    margin-left: 90% !important
}

.mx-100p {
    margin-right: 100% !important;
    margin-left: 100% !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-0-5 {
    margin-top: .125rem !important;
    margin-bottom: .125rem !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
}

.my-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important
}

.my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
}

.my-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
}

.my-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
}

.my-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
}

.my-11 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important
}

.my-12 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
}

.my-gs {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
}

.my-10p {
    margin-top: 10% !important;
    margin-bottom: 10% !important
}

.my-20p {
    margin-top: 20% !important;
    margin-bottom: 20% !important
}

.my-25p {
    margin-top: 25% !important;
    margin-bottom: 25% !important
}

.my-30p {
    margin-top: 30% !important;
    margin-bottom: 30% !important
}

.my-35p {
    margin-top: 35% !important;
    margin-bottom: 35% !important
}

.my-40p {
    margin-top: 40% !important;
    margin-bottom: 40% !important
}

.my-45p {
    margin-top: 45% !important;
    margin-bottom: 45% !important
}

.my-50p {
    margin-top: 50% !important;
    margin-bottom: 50% !important
}

.my-60p {
    margin-top: 60% !important;
    margin-bottom: 60% !important
}

.my-70p {
    margin-top: 70% !important;
    margin-bottom: 70% !important
}

.my-75p {
    margin-top: 75% !important;
    margin-bottom: 75% !important
}

.my-80p {
    margin-top: 80% !important;
    margin-bottom: 80% !important
}

.my-90p {
    margin-top: 90% !important;
    margin-bottom: 90% !important
}

.my-100p {
    margin-top: 100% !important;
    margin-bottom: 100% !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-0-5 {
    margin-top: .125rem !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 2rem !important
}

.mt-6 {
    margin-top: 3.5rem !important
}

.mt-7 {
    margin-top: 4rem !important
}

.mt-8 {
    margin-top: 4.5rem !important
}

.mt-9 {
    margin-top: 5rem !important
}

.mt-10 {
    margin-top: 6rem !important
}

.mt-11 {
    margin-top: 7rem !important
}

.mt-12 {
    margin-top: 8rem !important
}

.mt-gs {
    margin-top: 2rem !important
}

.mt-10p {
    margin-top: 10% !important
}

.mt-20p {
    margin-top: 20% !important
}

.mt-25p {
    margin-top: 25% !important
}

.mt-30p {
    margin-top: 30% !important
}

.mt-35p {
    margin-top: 35% !important
}

.mt-40p {
    margin-top: 40% !important
}

.mt-45p {
    margin-top: 45% !important
}

.mt-50p {
    margin-top: 50% !important
}

.mt-60p {
    margin-top: 60% !important
}

.mt-70p {
    margin-top: 70% !important
}

.mt-75p {
    margin-top: 75% !important
}

.mt-80p {
    margin-top: 80% !important
}

.mt-90p {
    margin-top: 90% !important
}

.mt-100p {
    margin-top: 100% !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-0-5 {
    margin-right: .125rem !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 2rem !important
}

.me-6 {
    margin-right: 3.5rem !important
}

.me-7 {
    margin-right: 4rem !important
}

.me-8 {
    margin-right: 4.5rem !important
}

.me-9 {
    margin-right: 5rem !important
}

.me-10 {
    margin-right: 6rem !important
}

.me-11 {
    margin-right: 7rem !important
}

.me-12 {
    margin-right: 8rem !important
}

.me-gs {
    margin-right: 2rem !important
}

.me-10p {
    margin-right: 10% !important
}

.me-20p {
    margin-right: 20% !important
}

.me-25p {
    margin-right: 25% !important
}

.me-30p {
    margin-right: 30% !important
}

.me-35p {
    margin-right: 35% !important
}

.me-40p {
    margin-right: 40% !important
}

.me-45p {
    margin-right: 45% !important
}

.me-50p {
    margin-right: 50% !important
}

.me-60p {
    margin-right: 60% !important
}

.me-70p {
    margin-right: 70% !important
}

.me-75p {
    margin-right: 75% !important
}

.me-80p {
    margin-right: 80% !important
}

.me-90p {
    margin-right: 90% !important
}

.me-100p {
    margin-right: 100% !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-0-5 {
    margin-bottom: .125rem !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 2rem !important
}

.mb-6 {
    margin-bottom: 3.5rem !important
}

.mb-7 {
    margin-bottom: 4rem !important
}

.mb-8 {
    margin-bottom: 4.5rem !important
}

.mb-9 {
    margin-bottom: 5rem !important
}

.mb-10 {
    margin-bottom: 6rem !important
}

.mb-11 {
    margin-bottom: 7rem !important
}

.mb-12 {
    margin-bottom: 8rem !important
}

.mb-gs {
    margin-bottom: 2rem !important
}

.mb-10p {
    margin-bottom: 10% !important
}

.mb-20p {
    margin-bottom: 20% !important
}

.mb-25p {
    margin-bottom: 25% !important
}

.mb-30p {
    margin-bottom: 30% !important
}

.mb-35p {
    margin-bottom: 35% !important
}

.mb-40p {
    margin-bottom: 40% !important
}

.mb-45p {
    margin-bottom: 45% !important
}

.mb-50p {
    margin-bottom: 50% !important
}

.mb-60p {
    margin-bottom: 60% !important
}

.mb-70p {
    margin-bottom: 70% !important
}

.mb-75p {
    margin-bottom: 75% !important
}

.mb-80p {
    margin-bottom: 80% !important
}

.mb-90p {
    margin-bottom: 90% !important
}

.mb-100p {
    margin-bottom: 100% !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-0-5 {
    margin-left: .125rem !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 2rem !important
}

.ms-6 {
    margin-left: 3.5rem !important
}

.ms-7 {
    margin-left: 4rem !important
}

.ms-8 {
    margin-left: 4.5rem !important
}

.ms-9 {
    margin-left: 5rem !important
}

.ms-10 {
    margin-left: 6rem !important
}

.ms-11 {
    margin-left: 7rem !important
}

.ms-12 {
    margin-left: 8rem !important
}

.ms-gs {
    margin-left: 2rem !important
}

.ms-10p {
    margin-left: 10% !important
}

.ms-20p {
    margin-left: 20% !important
}

.ms-25p {
    margin-left: 25% !important
}

.ms-30p {
    margin-left: 30% !important
}

.ms-35p {
    margin-left: 35% !important
}

.ms-40p {
    margin-left: 40% !important
}

.ms-45p {
    margin-left: 45% !important
}

.ms-50p {
    margin-left: 50% !important
}

.ms-60p {
    margin-left: 60% !important
}

.ms-70p {
    margin-left: 70% !important
}

.ms-75p {
    margin-left: 75% !important
}

.ms-80p {
    margin-left: 80% !important
}

.ms-90p {
    margin-left: 90% !important
}

.ms-100p {
    margin-left: 100% !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n0-5 {
    margin: -0.125rem !important
}

.m-n1 {
    margin: -0.25rem !important
}

.m-n2 {
    margin: -0.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -2rem !important
}

.m-n6 {
    margin: -3.5rem !important
}

.m-n7 {
    margin: -4rem !important
}

.m-n8 {
    margin: -4.5rem !important
}

.m-n9 {
    margin: -5rem !important
}

.m-n10 {
    margin: -6rem !important
}

.m-n11 {
    margin: -7rem !important
}

.m-n12 {
    margin: -8rem !important
}

.m-ngs {
    margin: -2rem !important
}

.m-n10p {
    margin: -10% !important
}

.m-n20p {
    margin: -20% !important
}

.m-n25p {
    margin: -25% !important
}

.m-n30p {
    margin: -30% !important
}

.m-n35p {
    margin: -35% !important
}

.m-n40p {
    margin: -40% !important
}

.m-n45p {
    margin: -45% !important
}

.m-n50p {
    margin: -50% !important
}

.m-n60p {
    margin: -60% !important
}

.m-n70p {
    margin: -70% !important
}

.m-n75p {
    margin: -75% !important
}

.m-n80p {
    margin: -80% !important
}

.m-n90p {
    margin: -90% !important
}

.m-n100p {
    margin: -100% !important
}

.mx-n0-5 {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important
}

.mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important
}

.mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
}

.mx-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important
}

.mx-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important
}

.mx-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important
}

.mx-n8 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
}

.mx-n9 {
    margin-right: -5rem !important;
    margin-left: -5rem !important
}

.mx-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important
}

.mx-n11 {
    margin-right: -7rem !important;
    margin-left: -7rem !important
}

.mx-n12 {
    margin-right: -8rem !important;
    margin-left: -8rem !important
}

.mx-ngs {
    margin-right: -2rem !important;
    margin-left: -2rem !important
}

.mx-n10p {
    margin-right: -10% !important;
    margin-left: -10% !important
}

.mx-n20p {
    margin-right: -20% !important;
    margin-left: -20% !important
}

.mx-n25p {
    margin-right: -25% !important;
    margin-left: -25% !important
}

.mx-n30p {
    margin-right: -30% !important;
    margin-left: -30% !important
}

.mx-n35p {
    margin-right: -35% !important;
    margin-left: -35% !important
}

.mx-n40p {
    margin-right: -40% !important;
    margin-left: -40% !important
}

.mx-n45p {
    margin-right: -45% !important;
    margin-left: -45% !important
}

.mx-n50p {
    margin-right: -50% !important;
    margin-left: -50% !important
}

.mx-n60p {
    margin-right: -60% !important;
    margin-left: -60% !important
}

.mx-n70p {
    margin-right: -70% !important;
    margin-left: -70% !important
}

.mx-n75p {
    margin-right: -75% !important;
    margin-left: -75% !important
}

.mx-n80p {
    margin-right: -80% !important;
    margin-left: -80% !important
}

.mx-n90p {
    margin-right: -90% !important;
    margin-left: -90% !important
}

.mx-n100p {
    margin-right: -100% !important;
    margin-left: -100% !important
}

.my-n0-5 {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important
}

.my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important
}

.my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
}

.my-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
}

.my-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important
}

.my-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
}

.my-n8 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
}

.my-n9 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important
}

.my-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important
}

.my-n11 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important
}

.my-n12 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important
}

.my-ngs {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
}

.my-n10p {
    margin-top: -10% !important;
    margin-bottom: -10% !important
}

.my-n20p {
    margin-top: -20% !important;
    margin-bottom: -20% !important
}

.my-n25p {
    margin-top: -25% !important;
    margin-bottom: -25% !important
}

.my-n30p {
    margin-top: -30% !important;
    margin-bottom: -30% !important
}

.my-n35p {
    margin-top: -35% !important;
    margin-bottom: -35% !important
}

.my-n40p {
    margin-top: -40% !important;
    margin-bottom: -40% !important
}

.my-n45p {
    margin-top: -45% !important;
    margin-bottom: -45% !important
}

.my-n50p {
    margin-top: -50% !important;
    margin-bottom: -50% !important
}

.my-n60p {
    margin-top: -60% !important;
    margin-bottom: -60% !important
}

.my-n70p {
    margin-top: -70% !important;
    margin-bottom: -70% !important
}

.my-n75p {
    margin-top: -75% !important;
    margin-bottom: -75% !important
}

.my-n80p {
    margin-top: -80% !important;
    margin-bottom: -80% !important
}

.my-n90p {
    margin-top: -90% !important;
    margin-bottom: -90% !important
}

.my-n100p {
    margin-top: -100% !important;
    margin-bottom: -100% !important
}

.mt-n0-5 {
    margin-top: -0.125rem !important
}

.mt-n1 {
    margin-top: -0.25rem !important
}

.mt-n2 {
    margin-top: -0.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -2rem !important
}

.mt-n6 {
    margin-top: -3.5rem !important
}

.mt-n7 {
    margin-top: -4rem !important
}

.mt-n8 {
    margin-top: -4.5rem !important
}

.mt-n9 {
    margin-top: -5rem !important
}

.mt-n10 {
    margin-top: -6rem !important
}

.mt-n11 {
    margin-top: -7rem !important
}

.mt-n12 {
    margin-top: -8rem !important
}

.mt-ngs {
    margin-top: -2rem !important
}

.mt-n10p {
    margin-top: -10% !important
}

.mt-n20p {
    margin-top: -20% !important
}

.mt-n25p {
    margin-top: -25% !important
}

.mt-n30p {
    margin-top: -30% !important
}

.mt-n35p {
    margin-top: -35% !important
}

.mt-n40p {
    margin-top: -40% !important
}

.mt-n45p {
    margin-top: -45% !important
}

.mt-n50p {
    margin-top: -50% !important
}

.mt-n60p {
    margin-top: -60% !important
}

.mt-n70p {
    margin-top: -70% !important
}

.mt-n75p {
    margin-top: -75% !important
}

.mt-n80p {
    margin-top: -80% !important
}

.mt-n90p {
    margin-top: -90% !important
}

.mt-n100p {
    margin-top: -100% !important
}

.me-n0-5 {
    margin-right: -0.125rem !important
}

.me-n1 {
    margin-right: -0.25rem !important
}

.me-n2 {
    margin-right: -0.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -2rem !important
}

.me-n6 {
    margin-right: -3.5rem !important
}

.me-n7 {
    margin-right: -4rem !important
}

.me-n8 {
    margin-right: -4.5rem !important
}

.me-n9 {
    margin-right: -5rem !important
}

.me-n10 {
    margin-right: -6rem !important
}

.me-n11 {
    margin-right: -7rem !important
}

.me-n12 {
    margin-right: -8rem !important
}

.me-ngs {
    margin-right: -2rem !important
}

.me-n10p {
    margin-right: -10% !important
}

.me-n20p {
    margin-right: -20% !important
}

.me-n25p {
    margin-right: -25% !important
}

.me-n30p {
    margin-right: -30% !important
}

.me-n35p {
    margin-right: -35% !important
}

.me-n40p {
    margin-right: -40% !important
}

.me-n45p {
    margin-right: -45% !important
}

.me-n50p {
    margin-right: -50% !important
}

.me-n60p {
    margin-right: -60% !important
}

.me-n70p {
    margin-right: -70% !important
}

.me-n75p {
    margin-right: -75% !important
}

.me-n80p {
    margin-right: -80% !important
}

.me-n90p {
    margin-right: -90% !important
}

.me-n100p {
    margin-right: -100% !important
}

.mb-n0-5 {
    margin-bottom: -0.125rem !important
}

.mb-n1 {
    margin-bottom: -0.25rem !important
}

.mb-n2 {
    margin-bottom: -0.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -2rem !important
}

.mb-n6 {
    margin-bottom: -3.5rem !important
}

.mb-n7 {
    margin-bottom: -4rem !important
}

.mb-n8 {
    margin-bottom: -4.5rem !important
}

.mb-n9 {
    margin-bottom: -5rem !important
}

.mb-n10 {
    margin-bottom: -6rem !important
}

.mb-n11 {
    margin-bottom: -7rem !important
}

.mb-n12 {
    margin-bottom: -8rem !important
}

.mb-ngs {
    margin-bottom: -2rem !important
}

.mb-n10p {
    margin-bottom: -10% !important
}

.mb-n20p {
    margin-bottom: -20% !important
}

.mb-n25p {
    margin-bottom: -25% !important
}

.mb-n30p {
    margin-bottom: -30% !important
}

.mb-n35p {
    margin-bottom: -35% !important
}

.mb-n40p {
    margin-bottom: -40% !important
}

.mb-n45p {
    margin-bottom: -45% !important
}

.mb-n50p {
    margin-bottom: -50% !important
}

.mb-n60p {
    margin-bottom: -60% !important
}

.mb-n70p {
    margin-bottom: -70% !important
}

.mb-n75p {
    margin-bottom: -75% !important
}

.mb-n80p {
    margin-bottom: -80% !important
}

.mb-n90p {
    margin-bottom: -90% !important
}

.mb-n100p {
    margin-bottom: -100% !important
}

.ms-n0-5 {
    margin-left: -0.125rem !important
}

.ms-n1 {
    margin-left: -0.25rem !important
}

.ms-n2 {
    margin-left: -0.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -2rem !important
}

.ms-n6 {
    margin-left: -3.5rem !important
}

.ms-n7 {
    margin-left: -4rem !important
}

.ms-n8 {
    margin-left: -4.5rem !important
}

.ms-n9 {
    margin-left: -5rem !important
}

.ms-n10 {
    margin-left: -6rem !important
}

.ms-n11 {
    margin-left: -7rem !important
}

.ms-n12 {
    margin-left: -8rem !important
}

.ms-ngs {
    margin-left: -2rem !important
}

.ms-n10p {
    margin-left: -10% !important
}

.ms-n20p {
    margin-left: -20% !important
}

.ms-n25p {
    margin-left: -25% !important
}

.ms-n30p {
    margin-left: -30% !important
}

.ms-n35p {
    margin-left: -35% !important
}

.ms-n40p {
    margin-left: -40% !important
}

.ms-n45p {
    margin-left: -45% !important
}

.ms-n50p {
    margin-left: -50% !important
}

.ms-n60p {
    margin-left: -60% !important
}

.ms-n70p {
    margin-left: -70% !important
}

.ms-n75p {
    margin-left: -75% !important
}

.ms-n80p {
    margin-left: -80% !important
}

.ms-n90p {
    margin-left: -90% !important
}

.ms-n100p {
    margin-left: -100% !important
}

.p-0 {
    padding: 0 !important
}

.p-0-5 {
    padding: .125rem !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 2rem !important
}

.p-6 {
    padding: 3.5rem !important
}

.p-7 {
    padding: 4rem !important
}

.p-8 {
    padding: 4.5rem !important
}

.p-9 {
    padding: 5rem !important
}

.p-10 {
    padding: 6rem !important
}

.p-11 {
    padding: 7rem !important
}

.p-12 {
    padding: 8rem !important
}

.p-gs {
    padding: 2rem !important
}

.p-10p {
    padding: 10% !important
}

.p-20p {
    padding: 20% !important
}

.p-25p {
    padding: 25% !important
}

.p-30p {
    padding: 30% !important
}

.p-35p {
    padding: 35% !important
}

.p-40p {
    padding: 40% !important
}

.p-45p {
    padding: 45% !important
}

.p-50p {
    padding: 50% !important
}

.p-60p {
    padding: 60% !important
}

.p-70p {
    padding: 70% !important
}

.p-75p {
    padding: 75% !important
}

.p-80p {
    padding: 80% !important
}

.p-90p {
    padding: 90% !important
}

.p-100p {
    padding: 100% !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-0-5 {
    padding-right: .125rem !important;
    padding-left: .125rem !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important
}

.px-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important
}

.px-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important
}

.px-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
}

.px-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important
}

.px-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important
}

.px-11 {
    padding-right: 7rem !important;
    padding-left: 7rem !important
}

.px-12 {
    padding-right: 8rem !important;
    padding-left: 8rem !important
}

.px-gs {
    padding-right: 2rem !important;
    padding-left: 2rem !important
}

.px-10p {
    padding-right: 10% !important;
    padding-left: 10% !important
}

.px-20p {
    padding-right: 20% !important;
    padding-left: 20% !important
}

.px-25p {
    padding-right: 25% !important;
    padding-left: 25% !important
}

.px-30p {
    padding-right: 30% !important;
    padding-left: 30% !important
}

.px-35p {
    padding-right: 35% !important;
    padding-left: 35% !important
}

.px-40p {
    padding-right: 40% !important;
    padding-left: 40% !important
}

.px-45p {
    padding-right: 45% !important;
    padding-left: 45% !important
}

.px-50p {
    padding-right: 50% !important;
    padding-left: 50% !important
}

.px-60p {
    padding-right: 60% !important;
    padding-left: 60% !important
}

.px-70p {
    padding-right: 70% !important;
    padding-left: 70% !important
}

.px-75p {
    padding-right: 75% !important;
    padding-left: 75% !important
}

.px-80p {
    padding-right: 80% !important;
    padding-left: 80% !important
}

.px-90p {
    padding-right: 90% !important;
    padding-left: 90% !important
}

.px-100p {
    padding-right: 100% !important;
    padding-left: 100% !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-0-5 {
    padding-top: .125rem !important;
    padding-bottom: .125rem !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-60 {
    padding-top: .6rem !important;
    padding-bottom: .6rem !important
}

.py-65 {
    padding-top: .65rem !important;
    padding-bottom: .65rem !important
}

.py-75 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
}

.py-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important
}

.py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
}

.py-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
}

.py-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
}

.py-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
}

.py-11 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important
}

.py-12 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
}

.py-gs {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
}

.py-10p {
    padding-top: 10% !important;
    padding-bottom: 10% !important
}

.py-20p {
    padding-top: 20% !important;
    padding-bottom: 20% !important
}

.py-25p {
    padding-top: 25% !important;
    padding-bottom: 25% !important
}

.py-30p {
    padding-top: 30% !important;
    padding-bottom: 30% !important
}

.py-35p {
    padding-top: 35% !important;
    padding-bottom: 35% !important
}

.py-40p {
    padding-top: 40% !important;
    padding-bottom: 40% !important
}

.py-45p {
    padding-top: 45% !important;
    padding-bottom: 45% !important
}

.py-50p {
    padding-top: 50% !important;
    padding-bottom: 50% !important
}

.py-60p {
    padding-top: 60% !important;
    padding-bottom: 60% !important
}

.py-70p {
    padding-top: 70% !important;
    padding-bottom: 70% !important
}

.py-75p {
    padding-top: 75% !important;
    padding-bottom: 75% !important
}

.py-80p {
    padding-top: 80% !important;
    padding-bottom: 80% !important
}

.py-90p {
    padding-top: 90% !important;
    padding-bottom: 90% !important
}

.py-100p {
    padding-top: 100% !important;
    padding-bottom: 100% !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-0-5 {
    padding-top: .125rem !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 2rem !important
}

.pt-6 {
    padding-top: 3.5rem !important
}

.pt-7 {
    padding-top: 4rem !important
}

.pt-8 {
    padding-top: 4.5rem !important
}

.pt-9 {
    padding-top: 5rem !important
}

.pt-10 {
    padding-top: 6rem !important
}

.pt-11 {
    padding-top: 7rem !important
}

.pt-12 {
    padding-top: 8rem !important
}

.pt-gs {
    padding-top: 2rem !important
}

.pt-10p {
    padding-top: 10% !important
}

.pt-20p {
    padding-top: 20% !important
}

.pt-25p {
    padding-top: 25% !important
}

.pt-30p {
    padding-top: 30% !important
}

.pt-35p {
    padding-top: 35% !important
}

.pt-40p {
    padding-top: 40% !important
}

.pt-45p {
    padding-top: 45% !important
}

.pt-50p {
    padding-top: 50% !important
}

.pt-60p {
    padding-top: 60% !important
}

.pt-70p {
    padding-top: 70% !important
}

.pt-75p {
    padding-top: 75% !important
}

.pt-80p {
    padding-top: 80% !important
}

.pt-90p {
    padding-top: 90% !important
}

.pt-100p {
    padding-top: 100% !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-0-5 {
    padding-right: .125rem !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 2rem !important
}

.pe-6 {
    padding-right: 3.5rem !important
}

.pe-7 {
    padding-right: 4rem !important
}

.pe-8 {
    padding-right: 4.5rem !important
}

.pe-9 {
    padding-right: 5rem !important
}

.pe-10 {
    padding-right: 6rem !important
}

.pe-11 {
    padding-right: 7rem !important
}

.pe-12 {
    padding-right: 8rem !important
}

.pe-gs {
    padding-right: 2rem !important
}

.pe-10p {
    padding-right: 10% !important
}

.pe-20p {
    padding-right: 20% !important
}

.pe-25p {
    padding-right: 25% !important
}

.pe-30p {
    padding-right: 30% !important
}

.pe-35p {
    padding-right: 35% !important
}

.pe-40p {
    padding-right: 40% !important
}

.pe-45p {
    padding-right: 45% !important
}

.pe-50p {
    padding-right: 50% !important
}

.pe-60p {
    padding-right: 60% !important
}

.pe-70p {
    padding-right: 70% !important
}

.pe-75p {
    padding-right: 75% !important
}

.pe-80p {
    padding-right: 80% !important
}

.pe-90p {
    padding-right: 90% !important
}

.pe-100p {
    padding-right: 100% !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-0-5 {
    padding-bottom: .125rem !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 2rem !important
}

.pb-6 {
    padding-bottom: 3.5rem !important
}

.pb-7 {
    padding-bottom: 4rem !important
}

.pb-8 {
    padding-bottom: 4.5rem !important
}

.pb-9 {
    padding-bottom: 5rem !important
}

.pb-10 {
    padding-bottom: 6rem !important
}

.pb-11 {
    padding-bottom: 7rem !important
}

.pb-12 {
    padding-bottom: 8rem !important
}

.pb-gs {
    padding-bottom: 2rem !important
}

.pb-10p {
    padding-bottom: 10% !important
}

.pb-20p {
    padding-bottom: 20% !important
}

.pb-25p {
    padding-bottom: 25% !important
}

.pb-30p {
    padding-bottom: 30% !important
}

.pb-35p {
    padding-bottom: 35% !important
}

.pb-40p {
    padding-bottom: 40% !important
}

.pb-45p {
    padding-bottom: 45% !important
}

.pb-50p {
    padding-bottom: 50% !important
}

.pb-60p {
    padding-bottom: 60% !important
}

.pb-70p {
    padding-bottom: 70% !important
}

.pb-75p {
    padding-bottom: 75% !important
}

.pb-80p {
    padding-bottom: 80% !important
}

.pb-90p {
    padding-bottom: 90% !important
}

.pb-100p {
    padding-bottom: 100% !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-0-5 {
    padding-left: .125rem !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 2rem !important
}

.ps-6 {
    padding-left: 3.5rem !important
}

.ps-7 {
    padding-left: 4rem !important
}

.ps-8 {
    padding-left: 4.5rem !important
}

.ps-9 {
    padding-left: 5rem !important
}

.ps-10 {
    padding-left: 6rem !important
}

.ps-11 {
    padding-left: 7rem !important
}

.ps-12 {
    padding-left: 8rem !important
}

.ps-gs {
    padding-left: 2rem !important
}

.ps-10p {
    padding-left: 10% !important
}

.ps-20p {
    padding-left: 20% !important
}

.ps-25p {
    padding-left: 25% !important
}

.ps-30p {
    padding-left: 30% !important
}

.ps-35p {
    padding-left: 35% !important
}

.ps-40p {
    padding-left: 40% !important
}

.ps-45p {
    padding-left: 45% !important
}

.ps-50p {
    padding-left: 50% !important
}

.ps-60p {
    padding-left: 60% !important
}

.ps-70p {
    padding-left: 70% !important
}

.ps-75p {
    padding-left: 75% !important
}

.ps-80p {
    padding-left: 80% !important
}

.ps-90p {
    padding-left: 90% !important
}

.ps-100p {
    padding-left: 100% !important
}

.gap-0 {
    gap: 0 !important
}

.gap-0-5 {
    gap: .125rem !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 2rem !important
}

.gap-6 {
    gap: 3.5rem !important
}

.gap-7 {
    gap: 4rem !important
}

.gap-8 {
    gap: 4.5rem !important
}

.gap-9 {
    gap: 5rem !important
}

.gap-10 {
    gap: 6rem !important
}

.gap-11 {
    gap: 7rem !important
}

.gap-12 {
    gap: 8rem !important
}

.gap-gs {
    gap: 2rem !important
}

.gap-10p {
    gap: 10% !important
}

.gap-20p {
    gap: 20% !important
}

.gap-25p {
    gap: 25% !important
}

.gap-30p {
    gap: 30% !important
}

.gap-35p {
    gap: 35% !important
}

.gap-40p {
    gap: 40% !important
}

.gap-45p {
    gap: 45% !important
}

.gap-50p {
    gap: 50% !important
}

.gap-60p {
    gap: 60% !important
}

.gap-70p {
    gap: 70% !important
}

.gap-75p {
    gap: 75% !important
}

.gap-80p {
    gap: 80% !important
}

.gap-90p {
    gap: 90% !important
}

.gap-100p {
    gap: 100% !important
}

.font-monospace {
    font-family: var(--font-monospace) !important
}

.fs-1 {
    font-size: calc(1.425rem + 2.1vw) !important
}

.fs-2 {
    font-size: calc(1.35rem + 1.2vw) !important
}

.fs-3 {
    font-size: calc(1.275rem + 0.3vw) !important
}

.fs-4 {
    font-size: 1.25rem !important
}

.fs-5 {
    font-size: 1.125rem !important
}

.fs-6 {
    font-size: 1rem !important
}
.fs-12 {
	font-size: 12px !important;
}
.fs-13 {
	font-size: 13px !important;
}
.fs-14 {
	font-size: 14px !important;
}
.fs-15 {
	font-size: 15px !important;
}
.fs-16 {
	font-size: 16px !important;
}
.fs-17 {
	font-size: 17px !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-19 {
	font-size: 19px !important;
}
.fs-20 {
	font-size: 20px !important;
}
.fs-21 {
	font-size: 21px !important;
}
.fs-22 {
	font-size: 22px !important;
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-medium {
    font-weight: 500 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.6 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --text-opacity: 1;
    color: rgba(var(--primary-rgb), var(--text-opacity)) !important
}

.text-secondary {
    --text-opacity: 1;
    color: rgba(var(--secondary-rgb), var(--text-opacity)) !important
}

.text-success {
    --text-opacity: 1;
    color: rgba(var(--success-rgb), var(--text-opacity)) !important
}

.text-info {
    --text-opacity: 1;
    color: rgba(var(--info-rgb), var(--text-opacity)) !important
}

.text-warning {
    --text-opacity: 1;
    color: rgba(var(--warning-rgb), var(--text-opacity)) !important
}

.text-danger {
    --text-opacity: 1;
    color: rgba(var(--danger-rgb), var(--text-opacity)) !important
}

.text-light {
    --text-opacity: 1;
    color: rgba(var(--light-rgb), var(--text-opacity)) !important
}

.text-dark {
    --text-opacity: 1;
    color: rgba(var(--dark-rgb), var(--text-opacity)) !important
}

.text-darker {
    --text-opacity: 1;
    color: rgba(var(--darker-rgb), var(--text-opacity)) !important
}

.text-black {
    --text-opacity: 1;
    color: rgba(var(--black-rgb), var(--text-opacity)) !important
}

.text-white {
    --text-opacity: 1;
    color: rgba(var(--white-rgb), var(--text-opacity)) !important
}

.text-body {
    --text-opacity: 1;
    color: rgba(var(--body-color-rgb), var(--text-opacity)) !important
}

.text-muted {
    --text-opacity: 1;
    color: #6b7989 !important
}

.text-black-50 {
    --text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --text-opacity: 0.25
}

.text-opacity-50 {
    --text-opacity: 0.5
}

.text-opacity-75 {
    --text-opacity: 0.75
}

.text-opacity-100 {
    --text-opacity: 1
}

.bg-primary {
    --bg-opacity: 1;
    background-color: rgba(var(--primary-rgb), var(--bg-opacity)) !important
}

.bg-secondary {
    --bg-opacity: 1;
    background-color: rgba(var(--secondary-rgb), var(--bg-opacity)) !important
}

.bg-success {
    --bg-opacity: 1;
    background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important
}

.bg-info {
    --bg-opacity: 1;
    background-color: rgba(var(--info-rgb), var(--bg-opacity)) !important
}

.bg-warning {
    --bg-opacity: 1;
    background-color: rgba(var(--warning-rgb), var(--bg-opacity)) !important
}

.bg-danger {
    --bg-opacity: 1;
    background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important
}

.bg-light {
    --bg-opacity: 1;
    background-color: rgba(var(--light-rgb), var(--bg-opacity)) !important
}

.bg-dark {
    --bg-opacity: 1;
    background-color: rgba(var(--dark-rgb), var(--bg-opacity)) !important
}

.bg-darker {
    --bg-opacity: 1;
    background-color: rgba(var(--darker-rgb), var(--bg-opacity)) !important
}

.bg-black {
    --bg-opacity: 1;
    background-color: rgba(var(--black-rgb), var(--bg-opacity)) !important
}

.bg-white {
    --bg-opacity: 1;
    background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important
}

.bg-body {
    --bg-opacity: 1;
    background-color: rgba(var(--body-bg-rgb), var(--bg-opacity)) !important
}

.bg-transparent {
    --bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0) !important
}

.bg-opacity-10 {
    --bg-opacity: 0.1
}

.bg-opacity-20 {
    --bg-opacity: 0.2
}

.bg-opacity-25 {
    --bg-opacity: 0.25
}

.bg-opacity-30 {
    --bg-opacity: 0.3
}

.bg-opacity-40 {
    --bg-opacity: 0.4
}

.bg-opacity-50 {
    --bg-opacity: 0.5
}

.bg-opacity-60 {
    --bg-opacity: 0.6
}

.bg-opacity-70 {
    --bg-opacity: 0.7
}

.bg-opacity-75 {
    --bg-opacity: 0.75
}

.bg-opacity-80 {
    --bg-opacity: 0.8
}

.bg-opacity-90 {
    --bg-opacity: 0.9
}

.bg-opacity-100 {
    --bg-opacity: 1
}

.bg-gradient {
    background-image: var(--gradient) !important
}

.user-select-all {
    user-select: all !important
}

.user-select-auto {
    user-select: auto !important
}

.user-select-none {
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: var(--border-radius) !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: var(--border-radius-sm) !important
}

.rounded-2 {
    border-radius: var(--border-radius) !important
}

.rounded-3 {
    border-radius: var(--border-radius-lg) !important
}

.rounded-4 {
    border-radius: var(--border-radius-xl) !important
}

.rounded-5 {
    border-radius: var(--border-radius-2xl) !important
}

.rounded-6 {
    border-radius: var(--border-radius-3xl) !important
}

.rounded-7 {
    border-radius: var(--border-radius-4xl) !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: var(--border-radius-pill) !important
}

.rounded-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-1 {
    border-top-left-radius: var(--border-radius-sm) !important;
    border-top-right-radius: var(--border-radius-sm) !important
}

.rounded-top-2 {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important
}

.rounded-top-3 {
    border-top-left-radius: var(--border-radius-lg) !important;
    border-top-right-radius: var(--border-radius-lg) !important
}

.rounded-top-4 {
    border-top-left-radius: var(--border-radius-xl) !important;
    border-top-right-radius: var(--border-radius-xl) !important
}

.rounded-top-5 {
    border-top-left-radius: var(--border-radius-2xl) !important;
    border-top-right-radius: var(--border-radius-2xl) !important
}

.rounded-top-6 {
    border-top-left-radius: var(--border-radius-3xl) !important;
    border-top-right-radius: var(--border-radius-3xl) !important
}

.rounded-top-7 {
    border-top-left-radius: var(--border-radius-4xl) !important;
    border-top-right-radius: var(--border-radius-4xl) !important
}

.rounded-top-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-top-pill {
    border-top-left-radius: var(--border-radius-pill) !important;
    border-top-right-radius: var(--border-radius-pill) !important
}

.rounded-end {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important
}

.rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-end-1 {
    border-top-right-radius: var(--border-radius-sm) !important;
    border-bottom-right-radius: var(--border-radius-sm) !important
}

.rounded-end-2 {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important
}

.rounded-end-3 {
    border-top-right-radius: var(--border-radius-lg) !important;
    border-bottom-right-radius: var(--border-radius-lg) !important
}

.rounded-end-4 {
    border-top-right-radius: var(--border-radius-xl) !important;
    border-bottom-right-radius: var(--border-radius-xl) !important
}

.rounded-end-5 {
    border-top-right-radius: var(--border-radius-2xl) !important;
    border-bottom-right-radius: var(--border-radius-2xl) !important
}

.rounded-end-6 {
    border-top-right-radius: var(--border-radius-3xl) !important;
    border-bottom-right-radius: var(--border-radius-3xl) !important
}

.rounded-end-7 {
    border-top-right-radius: var(--border-radius-4xl) !important;
    border-bottom-right-radius: var(--border-radius-4xl) !important
}

.rounded-end-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important
}

.rounded-end-pill {
    border-top-right-radius: var(--border-radius-pill) !important;
    border-bottom-right-radius: var(--border-radius-pill) !important
}

.rounded-bottom {
    border-bottom-right-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.rounded-bottom-1 {
    border-bottom-right-radius: var(--border-radius-sm) !important;
    border-bottom-left-radius: var(--border-radius-sm) !important
}

.rounded-bottom-2 {
    border-bottom-right-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important
}

.rounded-bottom-3 {
    border-bottom-right-radius: var(--border-radius-lg) !important;
    border-bottom-left-radius: var(--border-radius-lg) !important
}

.rounded-bottom-4 {
    border-bottom-right-radius: var(--border-radius-xl) !important;
    border-bottom-left-radius: var(--border-radius-xl) !important
}

.rounded-bottom-5 {
    border-bottom-right-radius: var(--border-radius-2xl) !important;
    border-bottom-left-radius: var(--border-radius-2xl) !important
}

.rounded-bottom-6 {
    border-bottom-right-radius: var(--border-radius-3xl) !important;
    border-bottom-left-radius: var(--border-radius-3xl) !important
}

.rounded-bottom-7 {
    border-bottom-right-radius: var(--border-radius-4xl) !important;
    border-bottom-left-radius: var(--border-radius-4xl) !important
}

.rounded-bottom-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important
}

.rounded-bottom-pill {
    border-bottom-right-radius: var(--border-radius-pill) !important;
    border-bottom-left-radius: var(--border-radius-pill) !important
}

.rounded-start {
    border-bottom-left-radius: var(--border-radius) !important;
    border-top-left-radius: var(--border-radius) !important
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.rounded-start-1 {
    border-bottom-left-radius: var(--border-radius-sm) !important;
    border-top-left-radius: var(--border-radius-sm) !important
}

.rounded-start-2 {
    border-bottom-left-radius: var(--border-radius) !important;
    border-top-left-radius: var(--border-radius) !important
}

.rounded-start-3 {
    border-bottom-left-radius: var(--border-radius-lg) !important;
    border-top-left-radius: var(--border-radius-lg) !important
}

.rounded-start-4 {
    border-bottom-left-radius: var(--border-radius-xl) !important;
    border-top-left-radius: var(--border-radius-xl) !important
}

.rounded-start-5 {
    border-bottom-left-radius: var(--border-radius-2xl) !important;
    border-top-left-radius: var(--border-radius-2xl) !important
}

.rounded-start-6 {
    border-bottom-left-radius: var(--border-radius-3xl) !important;
    border-top-left-radius: var(--border-radius-3xl) !important
}

.rounded-start-7 {
    border-bottom-left-radius: var(--border-radius-4xl) !important;
    border-top-left-radius: var(--border-radius-4xl) !important
}

.rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important
}

.rounded-start-pill {
    border-bottom-left-radius: var(--border-radius-pill) !important;
    border-top-left-radius: var(--border-radius-pill) !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.text-tracking-1 {
    letter-spacing: .05em !important
}

.text-tracking-2 {
    letter-spacing: .1em !important
}

.text-tracking-n1 {
    letter-spacing: -0.05em !important
}

.text-tracking-n2 {
    letter-spacing: -0.1em !important
}

@media(min-width: 576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .position-sm-static {
        position: static !important
    }

    .position-sm-relative {
        position: relative !important
    }

    .position-sm-absolute {
        position: absolute !important
    }

    .position-sm-fixed {
        position: fixed !important
    }

    .position-sm-sticky {
        position: sticky !important
    }

    .top-sm-auto {
        top: auto !important
    }

    .top-sm-0 {
        top: 0 !important
    }

    .top-sm-50 {
        top: 50% !important
    }

    .top-sm-100 {
        top: 100% !important
    }

    .bottom-sm-auto {
        bottom: auto !important
    }

    .bottom-sm-0 {
        bottom: 0 !important
    }

    .bottom-sm-50 {
        bottom: 50% !important
    }

    .bottom-sm-100 {
        bottom: 100% !important
    }

    .start-sm-auto {
        left: auto !important
    }

    .start-sm-0 {
        left: 0 !important
    }

    .start-sm-50 {
        left: 50% !important
    }

    .start-sm-100 {
        left: 100% !important
    }

    .end-sm-auto {
        right: auto !important
    }

    .end-sm-0 {
        right: 0 !important
    }

    .end-sm-50 {
        right: 50% !important
    }

    .end-sm-100 {
        right: 100% !important
    }

    .translate-middle-sm {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-sm-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-sm-y {
        transform: translateY(-50%) !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .w-sm-1rem {
        width: 1rem !important
    }

    .w-sm-1-5rem {
        width: 1.5rem !important
    }

    .w-sm-2rem {
        width: 2rem !important
    }

    .w-sm-3rem {
        width: 3rem !important
    }

    .w-sm-4rem {
        width: 4rem !important
    }

    .w-sm-5rem {
        width: 5rem !important
    }

    .w-sm-6rem {
        width: 6rem !important
    }

    .w-sm-7rem {
        width: 7rem !important
    }

    .w-sm-8rem {
        width: 8rem !important
    }

    .w-sm-10rem {
        width: 10rem !important
    }

    .w-sm-12rem {
        width: 12rem !important
    }

    .w-sm-16rem {
        width: 16rem !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .h-sm-1rem {
        height: 1rem !important
    }

    .h-sm-1-5rem {
        height: 1.5rem !important
    }

    .h-sm-2rem {
        height: 2rem !important
    }

    .h-sm-3rem {
        height: 3rem !important
    }

    .h-sm-4rem {
        height: 4rem !important
    }

    .h-sm-5rem {
        height: 5rem !important
    }

    .h-sm-6rem {
        height: 6rem !important
    }

    .h-sm-7rem {
        height: 7rem !important
    }

    .h-sm-8rem {
        height: 8rem !important
    }

    .h-sm-10rem {
        height: 10rem !important
    }

    .h-sm-12rem {
        height: 12rem !important
    }

    .h-sm-16rem {
        height: 16rem !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shritw-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shritw-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-0-5 {
        margin: .125rem !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 2rem !important
    }

    .m-sm-6 {
        margin: 3.5rem !important
    }

    .m-sm-7 {
        margin: 4rem !important
    }

    .m-sm-8 {
        margin: 4.5rem !important
    }

    .m-sm-9 {
        margin: 5rem !important
    }

    .m-sm-10 {
        margin: 6rem !important
    }

    .m-sm-11 {
        margin: 7rem !important
    }

    .m-sm-12 {
        margin: 8rem !important
    }

    .m-sm-gs {
        margin: 2rem !important
    }

    .m-sm-10p {
        margin: 10% !important
    }

    .m-sm-20p {
        margin: 20% !important
    }

    .m-sm-25p {
        margin: 25% !important
    }

    .m-sm-30p {
        margin: 30% !important
    }

    .m-sm-35p {
        margin: 35% !important
    }

    .m-sm-40p {
        margin: 40% !important
    }

    .m-sm-45p {
        margin: 45% !important
    }

    .m-sm-50p {
        margin: 50% !important
    }

    .m-sm-60p {
        margin: 60% !important
    }

    .m-sm-70p {
        margin: 70% !important
    }

    .m-sm-75p {
        margin: 75% !important
    }

    .m-sm-80p {
        margin: 80% !important
    }

    .m-sm-90p {
        margin: 90% !important
    }

    .m-sm-100p {
        margin: 100% !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-0-5 {
        margin-right: .125rem !important;
        margin-left: .125rem !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-sm-6 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-sm-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-sm-8 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-sm-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important
    }

    .mx-sm-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important
    }

    .mx-sm-11 {
        margin-right: 7rem !important;
        margin-left: 7rem !important
    }

    .mx-sm-12 {
        margin-right: 8rem !important;
        margin-left: 8rem !important
    }

    .mx-sm-gs {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-sm-10p {
        margin-right: 10% !important;
        margin-left: 10% !important
    }

    .mx-sm-20p {
        margin-right: 20% !important;
        margin-left: 20% !important
    }

    .mx-sm-25p {
        margin-right: 25% !important;
        margin-left: 25% !important
    }

    .mx-sm-30p {
        margin-right: 30% !important;
        margin-left: 30% !important
    }

    .mx-sm-35p {
        margin-right: 35% !important;
        margin-left: 35% !important
    }

    .mx-sm-40p {
        margin-right: 40% !important;
        margin-left: 40% !important
    }

    .mx-sm-45p {
        margin-right: 45% !important;
        margin-left: 45% !important
    }

    .mx-sm-50p {
        margin-right: 50% !important;
        margin-left: 50% !important
    }

    .mx-sm-60p {
        margin-right: 60% !important;
        margin-left: 60% !important
    }

    .mx-sm-70p {
        margin-right: 70% !important;
        margin-left: 70% !important
    }

    .mx-sm-75p {
        margin-right: 75% !important;
        margin-left: 75% !important
    }

    .mx-sm-80p {
        margin-right: 80% !important;
        margin-left: 80% !important
    }

    .mx-sm-90p {
        margin-right: 90% !important;
        margin-left: 90% !important
    }

    .mx-sm-100p {
        margin-right: 100% !important;
        margin-left: 100% !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-0-5 {
        margin-top: .125rem !important;
        margin-bottom: .125rem !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-sm-6 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-sm-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-sm-8 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-sm-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important
    }

    .my-sm-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important
    }

    .my-sm-11 {
        margin-top: 7rem !important;
        margin-bottom: 7rem !important
    }

    .my-sm-12 {
        margin-top: 8rem !important;
        margin-bottom: 8rem !important
    }

    .my-sm-gs {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-sm-10p {
        margin-top: 10% !important;
        margin-bottom: 10% !important
    }

    .my-sm-20p {
        margin-top: 20% !important;
        margin-bottom: 20% !important
    }

    .my-sm-25p {
        margin-top: 25% !important;
        margin-bottom: 25% !important
    }

    .my-sm-30p {
        margin-top: 30% !important;
        margin-bottom: 30% !important
    }

    .my-sm-35p {
        margin-top: 35% !important;
        margin-bottom: 35% !important
    }

    .my-sm-40p {
        margin-top: 40% !important;
        margin-bottom: 40% !important
    }

    .my-sm-45p {
        margin-top: 45% !important;
        margin-bottom: 45% !important
    }

    .my-sm-50p {
        margin-top: 50% !important;
        margin-bottom: 50% !important
    }

    .my-sm-60p {
        margin-top: 60% !important;
        margin-bottom: 60% !important
    }

    .my-sm-70p {
        margin-top: 70% !important;
        margin-bottom: 70% !important
    }

    .my-sm-75p {
        margin-top: 75% !important;
        margin-bottom: 75% !important
    }

    .my-sm-80p {
        margin-top: 80% !important;
        margin-bottom: 80% !important
    }

    .my-sm-90p {
        margin-top: 90% !important;
        margin-bottom: 90% !important
    }

    .my-sm-100p {
        margin-top: 100% !important;
        margin-bottom: 100% !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-0-5 {
        margin-top: .125rem !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 2rem !important
    }

    .mt-sm-6 {
        margin-top: 3.5rem !important
    }

    .mt-sm-7 {
        margin-top: 4rem !important
    }

    .mt-sm-8 {
        margin-top: 4.5rem !important
    }

    .mt-sm-9 {
        margin-top: 5rem !important
    }

    .mt-sm-10 {
        margin-top: 6rem !important
    }

    .mt-sm-11 {
        margin-top: 7rem !important
    }

    .mt-sm-12 {
        margin-top: 8rem !important
    }

    .mt-sm-gs {
        margin-top: 2rem !important
    }

    .mt-sm-10p {
        margin-top: 10% !important
    }

    .mt-sm-20p {
        margin-top: 20% !important
    }

    .mt-sm-25p {
        margin-top: 25% !important
    }

    .mt-sm-30p {
        margin-top: 30% !important
    }

    .mt-sm-35p {
        margin-top: 35% !important
    }

    .mt-sm-40p {
        margin-top: 40% !important
    }

    .mt-sm-45p {
        margin-top: 45% !important
    }

    .mt-sm-50p {
        margin-top: 50% !important
    }

    .mt-sm-60p {
        margin-top: 60% !important
    }

    .mt-sm-70p {
        margin-top: 70% !important
    }

    .mt-sm-75p {
        margin-top: 75% !important
    }

    .mt-sm-80p {
        margin-top: 80% !important
    }

    .mt-sm-90p {
        margin-top: 90% !important
    }

    .mt-sm-100p {
        margin-top: 100% !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-0-5 {
        margin-right: .125rem !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 2rem !important
    }

    .me-sm-6 {
        margin-right: 3.5rem !important
    }

    .me-sm-7 {
        margin-right: 4rem !important
    }

    .me-sm-8 {
        margin-right: 4.5rem !important
    }

    .me-sm-9 {
        margin-right: 5rem !important
    }

    .me-sm-10 {
        margin-right: 6rem !important
    }

    .me-sm-11 {
        margin-right: 7rem !important
    }

    .me-sm-12 {
        margin-right: 8rem !important
    }

    .me-sm-gs {
        margin-right: 2rem !important
    }

    .me-sm-10p {
        margin-right: 10% !important
    }

    .me-sm-20p {
        margin-right: 20% !important
    }

    .me-sm-25p {
        margin-right: 25% !important
    }

    .me-sm-30p {
        margin-right: 30% !important
    }

    .me-sm-35p {
        margin-right: 35% !important
    }

    .me-sm-40p {
        margin-right: 40% !important
    }

    .me-sm-45p {
        margin-right: 45% !important
    }

    .me-sm-50p {
        margin-right: 50% !important
    }

    .me-sm-60p {
        margin-right: 60% !important
    }

    .me-sm-70p {
        margin-right: 70% !important
    }

    .me-sm-75p {
        margin-right: 75% !important
    }

    .me-sm-80p {
        margin-right: 80% !important
    }

    .me-sm-90p {
        margin-right: 90% !important
    }

    .me-sm-100p {
        margin-right: 100% !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-0-5 {
        margin-bottom: .125rem !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 2rem !important
    }

    .mb-sm-6 {
        margin-bottom: 3.5rem !important
    }

    .mb-sm-7 {
        margin-bottom: 4rem !important
    }

    .mb-sm-8 {
        margin-bottom: 4.5rem !important
    }

    .mb-sm-9 {
        margin-bottom: 5rem !important
    }

    .mb-sm-10 {
        margin-bottom: 6rem !important
    }

    .mb-sm-11 {
        margin-bottom: 7rem !important
    }

    .mb-sm-12 {
        margin-bottom: 8rem !important
    }

    .mb-sm-gs {
        margin-bottom: 2rem !important
    }

    .mb-sm-10p {
        margin-bottom: 10% !important
    }

    .mb-sm-20p {
        margin-bottom: 20% !important
    }

    .mb-sm-25p {
        margin-bottom: 25% !important
    }

    .mb-sm-30p {
        margin-bottom: 30% !important
    }

    .mb-sm-35p {
        margin-bottom: 35% !important
    }

    .mb-sm-40p {
        margin-bottom: 40% !important
    }

    .mb-sm-45p {
        margin-bottom: 45% !important
    }

    .mb-sm-50p {
        margin-bottom: 50% !important
    }

    .mb-sm-60p {
        margin-bottom: 60% !important
    }

    .mb-sm-70p {
        margin-bottom: 70% !important
    }

    .mb-sm-75p {
        margin-bottom: 75% !important
    }

    .mb-sm-80p {
        margin-bottom: 80% !important
    }

    .mb-sm-90p {
        margin-bottom: 90% !important
    }

    .mb-sm-100p {
        margin-bottom: 100% !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-0-5 {
        margin-left: .125rem !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 2rem !important
    }

    .ms-sm-6 {
        margin-left: 3.5rem !important
    }

    .ms-sm-7 {
        margin-left: 4rem !important
    }

    .ms-sm-8 {
        margin-left: 4.5rem !important
    }

    .ms-sm-9 {
        margin-left: 5rem !important
    }

    .ms-sm-10 {
        margin-left: 6rem !important
    }

    .ms-sm-11 {
        margin-left: 7rem !important
    }

    .ms-sm-12 {
        margin-left: 8rem !important
    }

    .ms-sm-gs {
        margin-left: 2rem !important
    }

    .ms-sm-10p {
        margin-left: 10% !important
    }

    .ms-sm-20p {
        margin-left: 20% !important
    }

    .ms-sm-25p {
        margin-left: 25% !important
    }

    .ms-sm-30p {
        margin-left: 30% !important
    }

    .ms-sm-35p {
        margin-left: 35% !important
    }

    .ms-sm-40p {
        margin-left: 40% !important
    }

    .ms-sm-45p {
        margin-left: 45% !important
    }

    .ms-sm-50p {
        margin-left: 50% !important
    }

    .ms-sm-60p {
        margin-left: 60% !important
    }

    .ms-sm-70p {
        margin-left: 70% !important
    }

    .ms-sm-75p {
        margin-left: 75% !important
    }

    .ms-sm-80p {
        margin-left: 80% !important
    }

    .ms-sm-90p {
        margin-left: 90% !important
    }

    .ms-sm-100p {
        margin-left: 100% !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n0-5 {
        margin: -0.125rem !important
    }

    .m-sm-n1 {
        margin: -0.25rem !important
    }

    .m-sm-n2 {
        margin: -0.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -2rem !important
    }

    .m-sm-n6 {
        margin: -3.5rem !important
    }

    .m-sm-n7 {
        margin: -4rem !important
    }

    .m-sm-n8 {
        margin: -4.5rem !important
    }

    .m-sm-n9 {
        margin: -5rem !important
    }

    .m-sm-n10 {
        margin: -6rem !important
    }

    .m-sm-n11 {
        margin: -7rem !important
    }

    .m-sm-n12 {
        margin: -8rem !important
    }

    .m-sm-ngs {
        margin: -2rem !important
    }

    .m-sm-n10p {
        margin: -10% !important
    }

    .m-sm-n20p {
        margin: -20% !important
    }

    .m-sm-n25p {
        margin: -25% !important
    }

    .m-sm-n30p {
        margin: -30% !important
    }

    .m-sm-n35p {
        margin: -35% !important
    }

    .m-sm-n40p {
        margin: -40% !important
    }

    .m-sm-n45p {
        margin: -45% !important
    }

    .m-sm-n50p {
        margin: -50% !important
    }

    .m-sm-n60p {
        margin: -60% !important
    }

    .m-sm-n70p {
        margin: -70% !important
    }

    .m-sm-n75p {
        margin: -75% !important
    }

    .m-sm-n80p {
        margin: -80% !important
    }

    .m-sm-n90p {
        margin: -90% !important
    }

    .m-sm-n100p {
        margin: -100% !important
    }

    .mx-sm-n0-5 {
        margin-right: -0.125rem !important;
        margin-left: -0.125rem !important
    }

    .mx-sm-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-sm-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-sm-n6 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-sm-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-sm-n8 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .mx-sm-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important
    }

    .mx-sm-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important
    }

    .mx-sm-n11 {
        margin-right: -7rem !important;
        margin-left: -7rem !important
    }

    .mx-sm-n12 {
        margin-right: -8rem !important;
        margin-left: -8rem !important
    }

    .mx-sm-ngs {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-sm-n10p {
        margin-right: -10% !important;
        margin-left: -10% !important
    }

    .mx-sm-n20p {
        margin-right: -20% !important;
        margin-left: -20% !important
    }

    .mx-sm-n25p {
        margin-right: -25% !important;
        margin-left: -25% !important
    }

    .mx-sm-n30p {
        margin-right: -30% !important;
        margin-left: -30% !important
    }

    .mx-sm-n35p {
        margin-right: -35% !important;
        margin-left: -35% !important
    }

    .mx-sm-n40p {
        margin-right: -40% !important;
        margin-left: -40% !important
    }

    .mx-sm-n45p {
        margin-right: -45% !important;
        margin-left: -45% !important
    }

    .mx-sm-n50p {
        margin-right: -50% !important;
        margin-left: -50% !important
    }

    .mx-sm-n60p {
        margin-right: -60% !important;
        margin-left: -60% !important
    }

    .mx-sm-n70p {
        margin-right: -70% !important;
        margin-left: -70% !important
    }

    .mx-sm-n75p {
        margin-right: -75% !important;
        margin-left: -75% !important
    }

    .mx-sm-n80p {
        margin-right: -80% !important;
        margin-left: -80% !important
    }

    .mx-sm-n90p {
        margin-right: -90% !important;
        margin-left: -90% !important
    }

    .mx-sm-n100p {
        margin-right: -100% !important;
        margin-left: -100% !important
    }

    .my-sm-n0-5 {
        margin-top: -0.125rem !important;
        margin-bottom: -0.125rem !important
    }

    .my-sm-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-sm-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-sm-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-sm-n6 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-sm-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-sm-n8 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .my-sm-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important
    }

    .my-sm-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important
    }

    .my-sm-n11 {
        margin-top: -7rem !important;
        margin-bottom: -7rem !important
    }

    .my-sm-n12 {
        margin-top: -8rem !important;
        margin-bottom: -8rem !important
    }

    .my-sm-ngs {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-sm-n10p {
        margin-top: -10% !important;
        margin-bottom: -10% !important
    }

    .my-sm-n20p {
        margin-top: -20% !important;
        margin-bottom: -20% !important
    }

    .my-sm-n25p {
        margin-top: -25% !important;
        margin-bottom: -25% !important
    }

    .my-sm-n30p {
        margin-top: -30% !important;
        margin-bottom: -30% !important
    }

    .my-sm-n35p {
        margin-top: -35% !important;
        margin-bottom: -35% !important
    }

    .my-sm-n40p {
        margin-top: -40% !important;
        margin-bottom: -40% !important
    }

    .my-sm-n45p {
        margin-top: -45% !important;
        margin-bottom: -45% !important
    }

    .my-sm-n50p {
        margin-top: -50% !important;
        margin-bottom: -50% !important
    }

    .my-sm-n60p {
        margin-top: -60% !important;
        margin-bottom: -60% !important
    }

    .my-sm-n70p {
        margin-top: -70% !important;
        margin-bottom: -70% !important
    }

    .my-sm-n75p {
        margin-top: -75% !important;
        margin-bottom: -75% !important
    }

    .my-sm-n80p {
        margin-top: -80% !important;
        margin-bottom: -80% !important
    }

    .my-sm-n90p {
        margin-top: -90% !important;
        margin-bottom: -90% !important
    }

    .my-sm-n100p {
        margin-top: -100% !important;
        margin-bottom: -100% !important
    }

    .mt-sm-n0-5 {
        margin-top: -0.125rem !important
    }

    .mt-sm-n1 {
        margin-top: -0.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -0.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -2rem !important
    }

    .mt-sm-n6 {
        margin-top: -3.5rem !important
    }

    .mt-sm-n7 {
        margin-top: -4rem !important
    }

    .mt-sm-n8 {
        margin-top: -4.5rem !important
    }

    .mt-sm-n9 {
        margin-top: -5rem !important
    }

    .mt-sm-n10 {
        margin-top: -6rem !important
    }

    .mt-sm-n11 {
        margin-top: -7rem !important
    }

    .mt-sm-n12 {
        margin-top: -8rem !important
    }

    .mt-sm-ngs {
        margin-top: -2rem !important
    }

    .mt-sm-n10p {
        margin-top: -10% !important
    }

    .mt-sm-n20p {
        margin-top: -20% !important
    }

    .mt-sm-n25p {
        margin-top: -25% !important
    }

    .mt-sm-n30p {
        margin-top: -30% !important
    }

    .mt-sm-n35p {
        margin-top: -35% !important
    }

    .mt-sm-n40p {
        margin-top: -40% !important
    }

    .mt-sm-n45p {
        margin-top: -45% !important
    }

    .mt-sm-n50p {
        margin-top: -50% !important
    }

    .mt-sm-n60p {
        margin-top: -60% !important
    }

    .mt-sm-n70p {
        margin-top: -70% !important
    }

    .mt-sm-n75p {
        margin-top: -75% !important
    }

    .mt-sm-n80p {
        margin-top: -80% !important
    }

    .mt-sm-n90p {
        margin-top: -90% !important
    }

    .mt-sm-n100p {
        margin-top: -100% !important
    }

    .me-sm-n0-5 {
        margin-right: -0.125rem !important
    }

    .me-sm-n1 {
        margin-right: -0.25rem !important
    }

    .me-sm-n2 {
        margin-right: -0.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -2rem !important
    }

    .me-sm-n6 {
        margin-right: -3.5rem !important
    }

    .me-sm-n7 {
        margin-right: -4rem !important
    }

    .me-sm-n8 {
        margin-right: -4.5rem !important
    }

    .me-sm-n9 {
        margin-right: -5rem !important
    }

    .me-sm-n10 {
        margin-right: -6rem !important
    }

    .me-sm-n11 {
        margin-right: -7rem !important
    }

    .me-sm-n12 {
        margin-right: -8rem !important
    }

    .me-sm-ngs {
        margin-right: -2rem !important
    }

    .me-sm-n10p {
        margin-right: -10% !important
    }

    .me-sm-n20p {
        margin-right: -20% !important
    }

    .me-sm-n25p {
        margin-right: -25% !important
    }

    .me-sm-n30p {
        margin-right: -30% !important
    }

    .me-sm-n35p {
        margin-right: -35% !important
    }

    .me-sm-n40p {
        margin-right: -40% !important
    }

    .me-sm-n45p {
        margin-right: -45% !important
    }

    .me-sm-n50p {
        margin-right: -50% !important
    }

    .me-sm-n60p {
        margin-right: -60% !important
    }

    .me-sm-n70p {
        margin-right: -70% !important
    }

    .me-sm-n75p {
        margin-right: -75% !important
    }

    .me-sm-n80p {
        margin-right: -80% !important
    }

    .me-sm-n90p {
        margin-right: -90% !important
    }

    .me-sm-n100p {
        margin-right: -100% !important
    }

    .mb-sm-n0-5 {
        margin-bottom: -0.125rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -2rem !important
    }

    .mb-sm-n6 {
        margin-bottom: -3.5rem !important
    }

    .mb-sm-n7 {
        margin-bottom: -4rem !important
    }

    .mb-sm-n8 {
        margin-bottom: -4.5rem !important
    }

    .mb-sm-n9 {
        margin-bottom: -5rem !important
    }

    .mb-sm-n10 {
        margin-bottom: -6rem !important
    }

    .mb-sm-n11 {
        margin-bottom: -7rem !important
    }

    .mb-sm-n12 {
        margin-bottom: -8rem !important
    }

    .mb-sm-ngs {
        margin-bottom: -2rem !important
    }

    .mb-sm-n10p {
        margin-bottom: -10% !important
    }

    .mb-sm-n20p {
        margin-bottom: -20% !important
    }

    .mb-sm-n25p {
        margin-bottom: -25% !important
    }

    .mb-sm-n30p {
        margin-bottom: -30% !important
    }

    .mb-sm-n35p {
        margin-bottom: -35% !important
    }

    .mb-sm-n40p {
        margin-bottom: -40% !important
    }

    .mb-sm-n45p {
        margin-bottom: -45% !important
    }

    .mb-sm-n50p {
        margin-bottom: -50% !important
    }

    .mb-sm-n60p {
        margin-bottom: -60% !important
    }

    .mb-sm-n70p {
        margin-bottom: -70% !important
    }

    .mb-sm-n75p {
        margin-bottom: -75% !important
    }

    .mb-sm-n80p {
        margin-bottom: -80% !important
    }

    .mb-sm-n90p {
        margin-bottom: -90% !important
    }

    .mb-sm-n100p {
        margin-bottom: -100% !important
    }

    .ms-sm-n0-5 {
        margin-left: -0.125rem !important
    }

    .ms-sm-n1 {
        margin-left: -0.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -0.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -2rem !important
    }

    .ms-sm-n6 {
        margin-left: -3.5rem !important
    }

    .ms-sm-n7 {
        margin-left: -4rem !important
    }

    .ms-sm-n8 {
        margin-left: -4.5rem !important
    }

    .ms-sm-n9 {
        margin-left: -5rem !important
    }

    .ms-sm-n10 {
        margin-left: -6rem !important
    }

    .ms-sm-n11 {
        margin-left: -7rem !important
    }

    .ms-sm-n12 {
        margin-left: -8rem !important
    }

    .ms-sm-ngs {
        margin-left: -2rem !important
    }

    .ms-sm-n10p {
        margin-left: -10% !important
    }

    .ms-sm-n20p {
        margin-left: -20% !important
    }

    .ms-sm-n25p {
        margin-left: -25% !important
    }

    .ms-sm-n30p {
        margin-left: -30% !important
    }

    .ms-sm-n35p {
        margin-left: -35% !important
    }

    .ms-sm-n40p {
        margin-left: -40% !important
    }

    .ms-sm-n45p {
        margin-left: -45% !important
    }

    .ms-sm-n50p {
        margin-left: -50% !important
    }

    .ms-sm-n60p {
        margin-left: -60% !important
    }

    .ms-sm-n70p {
        margin-left: -70% !important
    }

    .ms-sm-n75p {
        margin-left: -75% !important
    }

    .ms-sm-n80p {
        margin-left: -80% !important
    }

    .ms-sm-n90p {
        margin-left: -90% !important
    }

    .ms-sm-n100p {
        margin-left: -100% !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-0-5 {
        padding: .125rem !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 2rem !important
    }

    .p-sm-6 {
        padding: 3.5rem !important
    }

    .p-sm-7 {
        padding: 4rem !important
    }

    .p-sm-8 {
        padding: 4.5rem !important
    }

    .p-sm-9 {
        padding: 5rem !important
    }

    .p-sm-10 {
        padding: 6rem !important
    }

    .p-sm-11 {
        padding: 7rem !important
    }

    .p-sm-12 {
        padding: 8rem !important
    }

    .p-sm-gs {
        padding: 2rem !important
    }

    .p-sm-10p {
        padding: 10% !important
    }

    .p-sm-20p {
        padding: 20% !important
    }

    .p-sm-25p {
        padding: 25% !important
    }

    .p-sm-30p {
        padding: 30% !important
    }

    .p-sm-35p {
        padding: 35% !important
    }

    .p-sm-40p {
        padding: 40% !important
    }

    .p-sm-45p {
        padding: 45% !important
    }

    .p-sm-50p {
        padding: 50% !important
    }

    .p-sm-60p {
        padding: 60% !important
    }

    .p-sm-70p {
        padding: 70% !important
    }

    .p-sm-75p {
        padding: 75% !important
    }

    .p-sm-80p {
        padding: 80% !important
    }

    .p-sm-90p {
        padding: 90% !important
    }

    .p-sm-100p {
        padding: 100% !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-0-5 {
        padding-right: .125rem !important;
        padding-left: .125rem !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-sm-6 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-sm-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-sm-8 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .px-sm-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important
    }

    .px-sm-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important
    }

    .px-sm-11 {
        padding-right: 7rem !important;
        padding-left: 7rem !important
    }

    .px-sm-12 {
        padding-right: 8rem !important;
        padding-left: 8rem !important
    }

    .px-sm-gs {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-sm-10p {
        padding-right: 10% !important;
        padding-left: 10% !important
    }

    .px-sm-20p {
        padding-right: 20% !important;
        padding-left: 20% !important
    }

    .px-sm-25p {
        padding-right: 25% !important;
        padding-left: 25% !important
    }

    .px-sm-30p {
        padding-right: 30% !important;
        padding-left: 30% !important
    }

    .px-sm-35p {
        padding-right: 35% !important;
        padding-left: 35% !important
    }

    .px-sm-40p {
        padding-right: 40% !important;
        padding-left: 40% !important
    }

    .px-sm-45p {
        padding-right: 45% !important;
        padding-left: 45% !important
    }

    .px-sm-50p {
        padding-right: 50% !important;
        padding-left: 50% !important
    }

    .px-sm-60p {
        padding-right: 60% !important;
        padding-left: 60% !important
    }

    .px-sm-70p {
        padding-right: 70% !important;
        padding-left: 70% !important
    }

    .px-sm-75p {
        padding-right: 75% !important;
        padding-left: 75% !important
    }

    .px-sm-80p {
        padding-right: 80% !important;
        padding-left: 80% !important
    }

    .px-sm-90p {
        padding-right: 90% !important;
        padding-left: 90% !important
    }

    .px-sm-100p {
        padding-right: 100% !important;
        padding-left: 100% !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-0-5 {
        padding-top: .125rem !important;
        padding-bottom: .125rem !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-sm-6 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-sm-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-sm-8 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .py-sm-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .py-sm-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important
    }

    .py-sm-11 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important
    }

    .py-sm-12 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important
    }

    .py-sm-gs {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-sm-10p {
        padding-top: 10% !important;
        padding-bottom: 10% !important
    }

    .py-sm-20p {
        padding-top: 20% !important;
        padding-bottom: 20% !important
    }

    .py-sm-25p {
        padding-top: 25% !important;
        padding-bottom: 25% !important
    }

    .py-sm-30p {
        padding-top: 30% !important;
        padding-bottom: 30% !important
    }

    .py-sm-35p {
        padding-top: 35% !important;
        padding-bottom: 35% !important
    }

    .py-sm-40p {
        padding-top: 40% !important;
        padding-bottom: 40% !important
    }

    .py-sm-45p {
        padding-top: 45% !important;
        padding-bottom: 45% !important
    }

    .py-sm-50p {
        padding-top: 50% !important;
        padding-bottom: 50% !important
    }

    .py-sm-60p {
        padding-top: 60% !important;
        padding-bottom: 60% !important
    }

    .py-sm-70p {
        padding-top: 70% !important;
        padding-bottom: 70% !important
    }

    .py-sm-75p {
        padding-top: 75% !important;
        padding-bottom: 75% !important
    }

    .py-sm-80p {
        padding-top: 80% !important;
        padding-bottom: 80% !important
    }

    .py-sm-90p {
        padding-top: 90% !important;
        padding-bottom: 90% !important
    }

    .py-sm-100p {
        padding-top: 100% !important;
        padding-bottom: 100% !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-0-5 {
        padding-top: .125rem !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 2rem !important
    }

    .pt-sm-6 {
        padding-top: 3.5rem !important
    }

    .pt-sm-7 {
        padding-top: 4rem !important
    }

    .pt-sm-8 {
        padding-top: 4.5rem !important
    }

    .pt-sm-9 {
        padding-top: 5rem !important
    }

    .pt-sm-10 {
        padding-top: 6rem !important
    }

    .pt-sm-11 {
        padding-top: 7rem !important
    }

    .pt-sm-12 {
        padding-top: 8rem !important
    }

    .pt-sm-gs {
        padding-top: 2rem !important
    }

    .pt-sm-10p {
        padding-top: 10% !important
    }

    .pt-sm-20p {
        padding-top: 20% !important
    }

    .pt-sm-25p {
        padding-top: 25% !important
    }

    .pt-sm-30p {
        padding-top: 30% !important
    }

    .pt-sm-35p {
        padding-top: 35% !important
    }

    .pt-sm-40p {
        padding-top: 40% !important
    }

    .pt-sm-45p {
        padding-top: 45% !important
    }

    .pt-sm-50p {
        padding-top: 50% !important
    }

    .pt-sm-60p {
        padding-top: 60% !important
    }

    .pt-sm-70p {
        padding-top: 70% !important
    }

    .pt-sm-75p {
        padding-top: 75% !important
    }

    .pt-sm-80p {
        padding-top: 80% !important
    }

    .pt-sm-90p {
        padding-top: 90% !important
    }

    .pt-sm-100p {
        padding-top: 100% !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-0-5 {
        padding-right: .125rem !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 2rem !important
    }

    .pe-sm-6 {
        padding-right: 3.5rem !important
    }

    .pe-sm-7 {
        padding-right: 4rem !important
    }

    .pe-sm-8 {
        padding-right: 4.5rem !important
    }

    .pe-sm-9 {
        padding-right: 5rem !important
    }

    .pe-sm-10 {
        padding-right: 6rem !important
    }

    .pe-sm-11 {
        padding-right: 7rem !important
    }

    .pe-sm-12 {
        padding-right: 8rem !important
    }

    .pe-sm-gs {
        padding-right: 2rem !important
    }

    .pe-sm-10p {
        padding-right: 10% !important
    }

    .pe-sm-20p {
        padding-right: 20% !important
    }

    .pe-sm-25p {
        padding-right: 25% !important
    }

    .pe-sm-30p {
        padding-right: 30% !important
    }

    .pe-sm-35p {
        padding-right: 35% !important
    }

    .pe-sm-40p {
        padding-right: 40% !important
    }

    .pe-sm-45p {
        padding-right: 45% !important
    }

    .pe-sm-50p {
        padding-right: 50% !important
    }

    .pe-sm-60p {
        padding-right: 60% !important
    }

    .pe-sm-70p {
        padding-right: 70% !important
    }

    .pe-sm-75p {
        padding-right: 75% !important
    }

    .pe-sm-80p {
        padding-right: 80% !important
    }

    .pe-sm-90p {
        padding-right: 90% !important
    }

    .pe-sm-100p {
        padding-right: 100% !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-0-5 {
        padding-bottom: .125rem !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 2rem !important
    }

    .pb-sm-6 {
        padding-bottom: 3.5rem !important
    }

    .pb-sm-7 {
        padding-bottom: 4rem !important
    }

    .pb-sm-8 {
        padding-bottom: 4.5rem !important
    }

    .pb-sm-9 {
        padding-bottom: 5rem !important
    }

    .pb-sm-10 {
        padding-bottom: 6rem !important
    }

    .pb-sm-11 {
        padding-bottom: 7rem !important
    }

    .pb-sm-12 {
        padding-bottom: 8rem !important
    }

    .pb-sm-gs {
        padding-bottom: 2rem !important
    }

    .pb-sm-10p {
        padding-bottom: 10% !important
    }

    .pb-sm-20p {
        padding-bottom: 20% !important
    }

    .pb-sm-25p {
        padding-bottom: 25% !important
    }

    .pb-sm-30p {
        padding-bottom: 30% !important
    }

    .pb-sm-35p {
        padding-bottom: 35% !important
    }

    .pb-sm-40p {
        padding-bottom: 40% !important
    }

    .pb-sm-45p {
        padding-bottom: 45% !important
    }

    .pb-sm-50p {
        padding-bottom: 50% !important
    }

    .pb-sm-60p {
        padding-bottom: 60% !important
    }

    .pb-sm-70p {
        padding-bottom: 70% !important
    }

    .pb-sm-75p {
        padding-bottom: 75% !important
    }

    .pb-sm-80p {
        padding-bottom: 80% !important
    }

    .pb-sm-90p {
        padding-bottom: 90% !important
    }

    .pb-sm-100p {
        padding-bottom: 100% !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-0-5 {
        padding-left: .125rem !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 2rem !important
    }

    .ps-sm-6 {
        padding-left: 3.5rem !important
    }

    .ps-sm-7 {
        padding-left: 4rem !important
    }

    .ps-sm-8 {
        padding-left: 4.5rem !important
    }

    .ps-sm-9 {
        padding-left: 5rem !important
    }

    .ps-sm-10 {
        padding-left: 6rem !important
    }

    .ps-sm-11 {
        padding-left: 7rem !important
    }

    .ps-sm-12 {
        padding-left: 8rem !important
    }

    .ps-sm-gs {
        padding-left: 2rem !important
    }

    .ps-sm-10p {
        padding-left: 10% !important
    }

    .ps-sm-20p {
        padding-left: 20% !important
    }

    .ps-sm-25p {
        padding-left: 25% !important
    }

    .ps-sm-30p {
        padding-left: 30% !important
    }

    .ps-sm-35p {
        padding-left: 35% !important
    }

    .ps-sm-40p {
        padding-left: 40% !important
    }

    .ps-sm-45p {
        padding-left: 45% !important
    }

    .ps-sm-50p {
        padding-left: 50% !important
    }

    .ps-sm-60p {
        padding-left: 60% !important
    }

    .ps-sm-70p {
        padding-left: 70% !important
    }

    .ps-sm-75p {
        padding-left: 75% !important
    }

    .ps-sm-80p {
        padding-left: 80% !important
    }

    .ps-sm-90p {
        padding-left: 90% !important
    }

    .ps-sm-100p {
        padding-left: 100% !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-0-5 {
        gap: .125rem !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 2rem !important
    }

    .gap-sm-6 {
        gap: 3.5rem !important
    }

    .gap-sm-7 {
        gap: 4rem !important
    }

    .gap-sm-8 {
        gap: 4.5rem !important
    }

    .gap-sm-9 {
        gap: 5rem !important
    }

    .gap-sm-10 {
        gap: 6rem !important
    }

    .gap-sm-11 {
        gap: 7rem !important
    }

    .gap-sm-12 {
        gap: 8rem !important
    }

    .gap-sm-gs {
        gap: 2rem !important
    }

    .gap-sm-10p {
        gap: 10% !important
    }

    .gap-sm-20p {
        gap: 20% !important
    }

    .gap-sm-25p {
        gap: 25% !important
    }

    .gap-sm-30p {
        gap: 30% !important
    }

    .gap-sm-35p {
        gap: 35% !important
    }

    .gap-sm-40p {
        gap: 40% !important
    }

    .gap-sm-45p {
        gap: 45% !important
    }

    .gap-sm-50p {
        gap: 50% !important
    }

    .gap-sm-60p {
        gap: 60% !important
    }

    .gap-sm-70p {
        gap: 70% !important
    }

    .gap-sm-75p {
        gap: 75% !important
    }

    .gap-sm-80p {
        gap: 80% !important
    }

    .gap-sm-90p {
        gap: 90% !important
    }

    .gap-sm-100p {
        gap: 100% !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width: 768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .position-md-static {
        position: static !important
    }

    .position-md-relative {
        position: relative !important
    }

    .position-md-absolute {
        position: absolute !important
    }

    .position-md-fixed {
        position: fixed !important
    }

    .position-md-sticky {
        position: sticky !important
    }

    .top-md-auto {
        top: auto !important
    }

    .top-md-0 {
        top: 0 !important
    }

    .top-md-50 {
        top: 50% !important
    }

    .top-md-100 {
        top: 100% !important
    }

    .bottom-md-auto {
        bottom: auto !important
    }

    .bottom-md-0 {
        bottom: 0 !important
    }

    .bottom-md-50 {
        bottom: 50% !important
    }

    .bottom-md-100 {
        bottom: 100% !important
    }

    .start-md-auto {
        left: auto !important
    }

    .start-md-0 {
        left: 0 !important
    }

    .start-md-50 {
        left: 50% !important
    }

    .start-md-100 {
        left: 100% !important
    }

    .end-md-auto {
        right: auto !important
    }

    .end-md-0 {
        right: 0 !important
    }

    .end-md-50 {
        right: 50% !important
    }

    .end-md-100 {
        right: 100% !important
    }

    .translate-middle-md {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-md-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-md-y {
        transform: translateY(-50%) !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .w-md-1rem {
        width: 1rem !important
    }

    .w-md-1-5rem {
        width: 1.5rem !important
    }

    .w-md-2rem {
        width: 2rem !important
    }

    .w-md-3rem {
        width: 3rem !important
    }

    .w-md-4rem {
        width: 4rem !important
    }

    .w-md-5rem {
        width: 5rem !important
    }

    .w-md-6rem {
        width: 6rem !important
    }

    .w-md-7rem {
        width: 7rem !important
    }

    .w-md-8rem {
        width: 8rem !important
    }

    .w-md-10rem {
        width: 10rem !important
    }

    .w-md-12rem {
        width: 12rem !important
    }

    .w-md-16rem {
        width: 16rem !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .h-md-1rem {
        height: 1rem !important
    }

    .h-md-1-5rem {
        height: 1.5rem !important
    }

    .h-md-2rem {
        height: 2rem !important
    }

    .h-md-3rem {
        height: 3rem !important
    }

    .h-md-4rem {
        height: 4rem !important
    }

    .h-md-5rem {
        height: 5rem !important
    }

    .h-md-6rem {
        height: 6rem !important
    }

    .h-md-7rem {
        height: 7rem !important
    }

    .h-md-8rem {
        height: 8rem !important
    }

    .h-md-10rem {
        height: 10rem !important
    }

    .h-md-12rem {
        height: 12rem !important
    }

    .h-md-16rem {
        height: 16rem !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shritw-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shritw-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-0-5 {
        margin: .125rem !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 2rem !important
    }

    .m-md-6 {
        margin: 3.5rem !important
    }

    .m-md-7 {
        margin: 4rem !important
    }

    .m-md-8 {
        margin: 4.5rem !important
    }

    .m-md-9 {
        margin: 5rem !important
    }

    .m-md-10 {
        margin: 6rem !important
    }

    .m-md-11 {
        margin: 7rem !important
    }

    .m-md-12 {
        margin: 8rem !important
    }

    .m-md-gs {
        margin: 2rem !important
    }

    .m-md-10p {
        margin: 10% !important
    }

    .m-md-20p {
        margin: 20% !important
    }

    .m-md-25p {
        margin: 25% !important
    }

    .m-md-30p {
        margin: 30% !important
    }

    .m-md-35p {
        margin: 35% !important
    }

    .m-md-40p {
        margin: 40% !important
    }

    .m-md-45p {
        margin: 45% !important
    }

    .m-md-50p {
        margin: 50% !important
    }

    .m-md-60p {
        margin: 60% !important
    }

    .m-md-70p {
        margin: 70% !important
    }

    .m-md-75p {
        margin: 75% !important
    }

    .m-md-80p {
        margin: 80% !important
    }

    .m-md-90p {
        margin: 90% !important
    }

    .m-md-100p {
        margin: 100% !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-0-5 {
        margin-right: .125rem !important;
        margin-left: .125rem !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-md-6 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-md-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-md-8 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-md-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important
    }

    .mx-md-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important
    }

    .mx-md-11 {
        margin-right: 7rem !important;
        margin-left: 7rem !important
    }

    .mx-md-12 {
        margin-right: 8rem !important;
        margin-left: 8rem !important
    }

    .mx-md-gs {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-md-10p {
        margin-right: 10% !important;
        margin-left: 10% !important
    }

    .mx-md-20p {
        margin-right: 20% !important;
        margin-left: 20% !important
    }

    .mx-md-25p {
        margin-right: 25% !important;
        margin-left: 25% !important
    }

    .mx-md-30p {
        margin-right: 30% !important;
        margin-left: 30% !important
    }

    .mx-md-35p {
        margin-right: 35% !important;
        margin-left: 35% !important
    }

    .mx-md-40p {
        margin-right: 40% !important;
        margin-left: 40% !important
    }

    .mx-md-45p {
        margin-right: 45% !important;
        margin-left: 45% !important
    }

    .mx-md-50p {
        margin-right: 50% !important;
        margin-left: 50% !important
    }

    .mx-md-60p {
        margin-right: 60% !important;
        margin-left: 60% !important
    }

    .mx-md-70p {
        margin-right: 70% !important;
        margin-left: 70% !important
    }

    .mx-md-75p {
        margin-right: 75% !important;
        margin-left: 75% !important
    }

    .mx-md-80p {
        margin-right: 80% !important;
        margin-left: 80% !important
    }

    .mx-md-90p {
        margin-right: 90% !important;
        margin-left: 90% !important
    }

    .mx-md-100p {
        margin-right: 100% !important;
        margin-left: 100% !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-0-5 {
        margin-top: .125rem !important;
        margin-bottom: .125rem !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-md-6 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-md-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-md-8 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-md-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important
    }

    .my-md-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important
    }

    .my-md-11 {
        margin-top: 7rem !important;
        margin-bottom: 7rem !important
    }

    .my-md-12 {
        margin-top: 8rem !important;
        margin-bottom: 8rem !important
    }

    .my-md-gs {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-md-10p {
        margin-top: 10% !important;
        margin-bottom: 10% !important
    }

    .my-md-20p {
        margin-top: 20% !important;
        margin-bottom: 20% !important
    }

    .my-md-25p {
        margin-top: 25% !important;
        margin-bottom: 25% !important
    }

    .my-md-30p {
        margin-top: 30% !important;
        margin-bottom: 30% !important
    }

    .my-md-35p {
        margin-top: 35% !important;
        margin-bottom: 35% !important
    }

    .my-md-40p {
        margin-top: 40% !important;
        margin-bottom: 40% !important
    }

    .my-md-45p {
        margin-top: 45% !important;
        margin-bottom: 45% !important
    }

    .my-md-50p {
        margin-top: 50% !important;
        margin-bottom: 50% !important
    }

    .my-md-60p {
        margin-top: 60% !important;
        margin-bottom: 60% !important
    }

    .my-md-70p {
        margin-top: 70% !important;
        margin-bottom: 70% !important
    }

    .my-md-75p {
        margin-top: 75% !important;
        margin-bottom: 75% !important
    }

    .my-md-80p {
        margin-top: 80% !important;
        margin-bottom: 80% !important
    }

    .my-md-90p {
        margin-top: 90% !important;
        margin-bottom: 90% !important
    }

    .my-md-100p {
        margin-top: 100% !important;
        margin-bottom: 100% !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-0-5 {
        margin-top: .125rem !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 2rem !important
    }

    .mt-md-6 {
        margin-top: 3.5rem !important
    }

    .mt-md-7 {
        margin-top: 4rem !important
    }

    .mt-md-8 {
        margin-top: 4.5rem !important
    }

    .mt-md-9 {
        margin-top: 5rem !important
    }

    .mt-md-10 {
        margin-top: 6rem !important
    }

    .mt-md-11 {
        margin-top: 7rem !important
    }

    .mt-md-12 {
        margin-top: 8rem !important
    }

    .mt-md-gs {
        margin-top: 2rem !important
    }

    .mt-md-10p {
        margin-top: 10% !important
    }

    .mt-md-20p {
        margin-top: 20% !important
    }

    .mt-md-25p {
        margin-top: 25% !important
    }

    .mt-md-30p {
        margin-top: 30% !important
    }

    .mt-md-35p {
        margin-top: 35% !important
    }

    .mt-md-40p {
        margin-top: 40% !important
    }

    .mt-md-45p {
        margin-top: 45% !important
    }

    .mt-md-50p {
        margin-top: 50% !important
    }

    .mt-md-60p {
        margin-top: 60% !important
    }

    .mt-md-70p {
        margin-top: 70% !important
    }

    .mt-md-75p {
        margin-top: 75% !important
    }

    .mt-md-80p {
        margin-top: 80% !important
    }

    .mt-md-90p {
        margin-top: 90% !important
    }

    .mt-md-100p {
        margin-top: 100% !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-0-5 {
        margin-right: .125rem !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 2rem !important
    }

    .me-md-6 {
        margin-right: 3.5rem !important
    }

    .me-md-7 {
        margin-right: 4rem !important
    }

    .me-md-8 {
        margin-right: 4.5rem !important
    }

    .me-md-9 {
        margin-right: 5rem !important
    }

    .me-md-10 {
        margin-right: 6rem !important
    }

    .me-md-11 {
        margin-right: 7rem !important
    }

    .me-md-12 {
        margin-right: 8rem !important
    }

    .me-md-gs {
        margin-right: 2rem !important
    }

    .me-md-10p {
        margin-right: 10% !important
    }

    .me-md-20p {
        margin-right: 20% !important
    }

    .me-md-25p {
        margin-right: 25% !important
    }

    .me-md-30p {
        margin-right: 30% !important
    }

    .me-md-35p {
        margin-right: 35% !important
    }

    .me-md-40p {
        margin-right: 40% !important
    }

    .me-md-45p {
        margin-right: 45% !important
    }

    .me-md-50p {
        margin-right: 50% !important
    }

    .me-md-60p {
        margin-right: 60% !important
    }

    .me-md-70p {
        margin-right: 70% !important
    }

    .me-md-75p {
        margin-right: 75% !important
    }

    .me-md-80p {
        margin-right: 80% !important
    }

    .me-md-90p {
        margin-right: 90% !important
    }

    .me-md-100p {
        margin-right: 100% !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-0-5 {
        margin-bottom: .125rem !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 2rem !important
    }

    .mb-md-6 {
        margin-bottom: 3.5rem !important
    }

    .mb-md-7 {
        margin-bottom: 4rem !important
    }

    .mb-md-8 {
        margin-bottom: 4.5rem !important
    }

    .mb-md-9 {
        margin-bottom: 5rem !important
    }

    .mb-md-10 {
        margin-bottom: 6rem !important
    }

    .mb-md-11 {
        margin-bottom: 7rem !important
    }

    .mb-md-12 {
        margin-bottom: 8rem !important
    }

    .mb-md-gs {
        margin-bottom: 2rem !important
    }

    .mb-md-10p {
        margin-bottom: 10% !important
    }

    .mb-md-20p {
        margin-bottom: 20% !important
    }

    .mb-md-25p {
        margin-bottom: 25% !important
    }

    .mb-md-30p {
        margin-bottom: 30% !important
    }

    .mb-md-35p {
        margin-bottom: 35% !important
    }

    .mb-md-40p {
        margin-bottom: 40% !important
    }

    .mb-md-45p {
        margin-bottom: 45% !important
    }

    .mb-md-50p {
        margin-bottom: 50% !important
    }

    .mb-md-60p {
        margin-bottom: 60% !important
    }

    .mb-md-70p {
        margin-bottom: 70% !important
    }

    .mb-md-75p {
        margin-bottom: 75% !important
    }

    .mb-md-80p {
        margin-bottom: 80% !important
    }

    .mb-md-90p {
        margin-bottom: 90% !important
    }

    .mb-md-100p {
        margin-bottom: 100% !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-0-5 {
        margin-left: .125rem !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 2rem !important
    }

    .ms-md-6 {
        margin-left: 3.5rem !important
    }

    .ms-md-7 {
        margin-left: 4rem !important
    }

    .ms-md-8 {
        margin-left: 4.5rem !important
    }

    .ms-md-9 {
        margin-left: 5rem !important
    }

    .ms-md-10 {
        margin-left: 6rem !important
    }

    .ms-md-11 {
        margin-left: 7rem !important
    }

    .ms-md-12 {
        margin-left: 8rem !important
    }

    .ms-md-gs {
        margin-left: 2rem !important
    }

    .ms-md-10p {
        margin-left: 10% !important
    }

    .ms-md-20p {
        margin-left: 20% !important
    }

    .ms-md-25p {
        margin-left: 25% !important
    }

    .ms-md-30p {
        margin-left: 30% !important
    }

    .ms-md-35p {
        margin-left: 35% !important
    }

    .ms-md-40p {
        margin-left: 40% !important
    }

    .ms-md-45p {
        margin-left: 45% !important
    }

    .ms-md-50p {
        margin-left: 50% !important
    }

    .ms-md-60p {
        margin-left: 60% !important
    }

    .ms-md-70p {
        margin-left: 70% !important
    }

    .ms-md-75p {
        margin-left: 75% !important
    }

    .ms-md-80p {
        margin-left: 80% !important
    }

    .ms-md-90p {
        margin-left: 90% !important
    }

    .ms-md-100p {
        margin-left: 100% !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n0-5 {
        margin: -0.125rem !important
    }

    .m-md-n1 {
        margin: -0.25rem !important
    }

    .m-md-n2 {
        margin: -0.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -2rem !important
    }

    .m-md-n6 {
        margin: -3.5rem !important
    }

    .m-md-n7 {
        margin: -4rem !important
    }

    .m-md-n8 {
        margin: -4.5rem !important
    }

    .m-md-n9 {
        margin: -5rem !important
    }

    .m-md-n10 {
        margin: -6rem !important
    }

    .m-md-n11 {
        margin: -7rem !important
    }

    .m-md-n12 {
        margin: -8rem !important
    }

    .m-md-ngs {
        margin: -2rem !important
    }

    .m-md-n10p {
        margin: -10% !important
    }

    .m-md-n20p {
        margin: -20% !important
    }

    .m-md-n25p {
        margin: -25% !important
    }

    .m-md-n30p {
        margin: -30% !important
    }

    .m-md-n35p {
        margin: -35% !important
    }

    .m-md-n40p {
        margin: -40% !important
    }

    .m-md-n45p {
        margin: -45% !important
    }

    .m-md-n50p {
        margin: -50% !important
    }

    .m-md-n60p {
        margin: -60% !important
    }

    .m-md-n70p {
        margin: -70% !important
    }

    .m-md-n75p {
        margin: -75% !important
    }

    .m-md-n80p {
        margin: -80% !important
    }

    .m-md-n90p {
        margin: -90% !important
    }

    .m-md-n100p {
        margin: -100% !important
    }

    .mx-md-n0-5 {
        margin-right: -0.125rem !important;
        margin-left: -0.125rem !important
    }

    .mx-md-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-md-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-md-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-md-n6 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-md-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-md-n8 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .mx-md-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important
    }

    .mx-md-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important
    }

    .mx-md-n11 {
        margin-right: -7rem !important;
        margin-left: -7rem !important
    }

    .mx-md-n12 {
        margin-right: -8rem !important;
        margin-left: -8rem !important
    }

    .mx-md-ngs {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-md-n10p {
        margin-right: -10% !important;
        margin-left: -10% !important
    }

    .mx-md-n20p {
        margin-right: -20% !important;
        margin-left: -20% !important
    }

    .mx-md-n25p {
        margin-right: -25% !important;
        margin-left: -25% !important
    }

    .mx-md-n30p {
        margin-right: -30% !important;
        margin-left: -30% !important
    }

    .mx-md-n35p {
        margin-right: -35% !important;
        margin-left: -35% !important
    }

    .mx-md-n40p {
        margin-right: -40% !important;
        margin-left: -40% !important
    }

    .mx-md-n45p {
        margin-right: -45% !important;
        margin-left: -45% !important
    }

    .mx-md-n50p {
        margin-right: -50% !important;
        margin-left: -50% !important
    }

    .mx-md-n60p {
        margin-right: -60% !important;
        margin-left: -60% !important
    }

    .mx-md-n70p {
        margin-right: -70% !important;
        margin-left: -70% !important
    }

    .mx-md-n75p {
        margin-right: -75% !important;
        margin-left: -75% !important
    }

    .mx-md-n80p {
        margin-right: -80% !important;
        margin-left: -80% !important
    }

    .mx-md-n90p {
        margin-right: -90% !important;
        margin-left: -90% !important
    }

    .mx-md-n100p {
        margin-right: -100% !important;
        margin-left: -100% !important
    }

    .my-md-n0-5 {
        margin-top: -0.125rem !important;
        margin-bottom: -0.125rem !important
    }

    .my-md-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-md-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-md-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-md-n6 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-md-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-md-n8 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .my-md-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important
    }

    .my-md-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important
    }

    .my-md-n11 {
        margin-top: -7rem !important;
        margin-bottom: -7rem !important
    }

    .my-md-n12 {
        margin-top: -8rem !important;
        margin-bottom: -8rem !important
    }

    .my-md-ngs {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-md-n10p {
        margin-top: -10% !important;
        margin-bottom: -10% !important
    }

    .my-md-n20p {
        margin-top: -20% !important;
        margin-bottom: -20% !important
    }

    .my-md-n25p {
        margin-top: -25% !important;
        margin-bottom: -25% !important
    }

    .my-md-n30p {
        margin-top: -30% !important;
        margin-bottom: -30% !important
    }

    .my-md-n35p {
        margin-top: -35% !important;
        margin-bottom: -35% !important
    }

    .my-md-n40p {
        margin-top: -40% !important;
        margin-bottom: -40% !important
    }

    .my-md-n45p {
        margin-top: -45% !important;
        margin-bottom: -45% !important
    }

    .my-md-n50p {
        margin-top: -50% !important;
        margin-bottom: -50% !important
    }

    .my-md-n60p {
        margin-top: -60% !important;
        margin-bottom: -60% !important
    }

    .my-md-n70p {
        margin-top: -70% !important;
        margin-bottom: -70% !important
    }

    .my-md-n75p {
        margin-top: -75% !important;
        margin-bottom: -75% !important
    }

    .my-md-n80p {
        margin-top: -80% !important;
        margin-bottom: -80% !important
    }

    .my-md-n90p {
        margin-top: -90% !important;
        margin-bottom: -90% !important
    }

    .my-md-n100p {
        margin-top: -100% !important;
        margin-bottom: -100% !important
    }

    .mt-md-n0-5 {
        margin-top: -0.125rem !important
    }

    .mt-md-n1 {
        margin-top: -0.25rem !important
    }

    .mt-md-n2 {
        margin-top: -0.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -2rem !important
    }

    .mt-md-n6 {
        margin-top: -3.5rem !important
    }

    .mt-md-n7 {
        margin-top: -4rem !important
    }

    .mt-md-n8 {
        margin-top: -4.5rem !important
    }

    .mt-md-n9 {
        margin-top: -5rem !important
    }

    .mt-md-n10 {
        margin-top: -6rem !important
    }

    .mt-md-n11 {
        margin-top: -7rem !important
    }

    .mt-md-n12 {
        margin-top: -8rem !important
    }

    .mt-md-ngs {
        margin-top: -2rem !important
    }

    .mt-md-n10p {
        margin-top: -10% !important
    }

    .mt-md-n20p {
        margin-top: -20% !important
    }

    .mt-md-n25p {
        margin-top: -25% !important
    }

    .mt-md-n30p {
        margin-top: -30% !important
    }

    .mt-md-n35p {
        margin-top: -35% !important
    }

    .mt-md-n40p {
        margin-top: -40% !important
    }

    .mt-md-n45p {
        margin-top: -45% !important
    }

    .mt-md-n50p {
        margin-top: -50% !important
    }

    .mt-md-n60p {
        margin-top: -60% !important
    }

    .mt-md-n70p {
        margin-top: -70% !important
    }

    .mt-md-n75p {
        margin-top: -75% !important
    }

    .mt-md-n80p {
        margin-top: -80% !important
    }

    .mt-md-n90p {
        margin-top: -90% !important
    }

    .mt-md-n100p {
        margin-top: -100% !important
    }

    .me-md-n0-5 {
        margin-right: -0.125rem !important
    }

    .me-md-n1 {
        margin-right: -0.25rem !important
    }

    .me-md-n2 {
        margin-right: -0.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -2rem !important
    }

    .me-md-n6 {
        margin-right: -3.5rem !important
    }

    .me-md-n7 {
        margin-right: -4rem !important
    }

    .me-md-n8 {
        margin-right: -4.5rem !important
    }

    .me-md-n9 {
        margin-right: -5rem !important
    }

    .me-md-n10 {
        margin-right: -6rem !important
    }

    .me-md-n11 {
        margin-right: -7rem !important
    }

    .me-md-n12 {
        margin-right: -8rem !important
    }

    .me-md-ngs {
        margin-right: -2rem !important
    }

    .me-md-n10p {
        margin-right: -10% !important
    }

    .me-md-n20p {
        margin-right: -20% !important
    }

    .me-md-n25p {
        margin-right: -25% !important
    }

    .me-md-n30p {
        margin-right: -30% !important
    }

    .me-md-n35p {
        margin-right: -35% !important
    }

    .me-md-n40p {
        margin-right: -40% !important
    }

    .me-md-n45p {
        margin-right: -45% !important
    }

    .me-md-n50p {
        margin-right: -50% !important
    }

    .me-md-n60p {
        margin-right: -60% !important
    }

    .me-md-n70p {
        margin-right: -70% !important
    }

    .me-md-n75p {
        margin-right: -75% !important
    }

    .me-md-n80p {
        margin-right: -80% !important
    }

    .me-md-n90p {
        margin-right: -90% !important
    }

    .me-md-n100p {
        margin-right: -100% !important
    }

    .mb-md-n0-5 {
        margin-bottom: -0.125rem !important
    }

    .mb-md-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -2rem !important
    }

    .mb-md-n6 {
        margin-bottom: -3.5rem !important
    }

    .mb-md-n7 {
        margin-bottom: -4rem !important
    }

    .mb-md-n8 {
        margin-bottom: -4.5rem !important
    }

    .mb-md-n9 {
        margin-bottom: -5rem !important
    }

    .mb-md-n10 {
        margin-bottom: -6rem !important
    }

    .mb-md-n11 {
        margin-bottom: -7rem !important
    }

    .mb-md-n12 {
        margin-bottom: -8rem !important
    }

    .mb-md-ngs {
        margin-bottom: -2rem !important
    }

    .mb-md-n10p {
        margin-bottom: -10% !important
    }

    .mb-md-n20p {
        margin-bottom: -20% !important
    }

    .mb-md-n25p {
        margin-bottom: -25% !important
    }

    .mb-md-n30p {
        margin-bottom: -30% !important
    }

    .mb-md-n35p {
        margin-bottom: -35% !important
    }

    .mb-md-n40p {
        margin-bottom: -40% !important
    }

    .mb-md-n45p {
        margin-bottom: -45% !important
    }

    .mb-md-n50p {
        margin-bottom: -50% !important
    }

    .mb-md-n60p {
        margin-bottom: -60% !important
    }

    .mb-md-n70p {
        margin-bottom: -70% !important
    }

    .mb-md-n75p {
        margin-bottom: -75% !important
    }

    .mb-md-n80p {
        margin-bottom: -80% !important
    }

    .mb-md-n90p {
        margin-bottom: -90% !important
    }

    .mb-md-n100p {
        margin-bottom: -100% !important
    }

    .ms-md-n0-5 {
        margin-left: -0.125rem !important
    }

    .ms-md-n1 {
        margin-left: -0.25rem !important
    }

    .ms-md-n2 {
        margin-left: -0.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -2rem !important
    }

    .ms-md-n6 {
        margin-left: -3.5rem !important
    }

    .ms-md-n7 {
        margin-left: -4rem !important
    }

    .ms-md-n8 {
        margin-left: -4.5rem !important
    }

    .ms-md-n9 {
        margin-left: -5rem !important
    }

    .ms-md-n10 {
        margin-left: -6rem !important
    }

    .ms-md-n11 {
        margin-left: -7rem !important
    }

    .ms-md-n12 {
        margin-left: -8rem !important
    }

    .ms-md-ngs {
        margin-left: -2rem !important
    }

    .ms-md-n10p {
        margin-left: -10% !important
    }

    .ms-md-n20p {
        margin-left: -20% !important
    }

    .ms-md-n25p {
        margin-left: -25% !important
    }

    .ms-md-n30p {
        margin-left: -30% !important
    }

    .ms-md-n35p {
        margin-left: -35% !important
    }

    .ms-md-n40p {
        margin-left: -40% !important
    }

    .ms-md-n45p {
        margin-left: -45% !important
    }

    .ms-md-n50p {
        margin-left: -50% !important
    }

    .ms-md-n60p {
        margin-left: -60% !important
    }

    .ms-md-n70p {
        margin-left: -70% !important
    }

    .ms-md-n75p {
        margin-left: -75% !important
    }

    .ms-md-n80p {
        margin-left: -80% !important
    }

    .ms-md-n90p {
        margin-left: -90% !important
    }

    .ms-md-n100p {
        margin-left: -100% !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-0-5 {
        padding: .125rem !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 2rem !important
    }

    .p-md-6 {
        padding: 3.5rem !important
    }

    .p-md-7 {
        padding: 4rem !important
    }

    .p-md-8 {
        padding: 4.5rem !important
    }

    .p-md-9 {
        padding: 5rem !important
    }

    .p-md-10 {
        padding: 6rem !important
    }

    .p-md-11 {
        padding: 7rem !important
    }

    .p-md-12 {
        padding: 8rem !important
    }

    .p-md-gs {
        padding: 2rem !important
    }

    .p-md-10p {
        padding: 10% !important
    }

    .p-md-20p {
        padding: 20% !important
    }

    .p-md-25p {
        padding: 25% !important
    }

    .p-md-30p {
        padding: 30% !important
    }

    .p-md-35p {
        padding: 35% !important
    }

    .p-md-40p {
        padding: 40% !important
    }

    .p-md-45p {
        padding: 45% !important
    }

    .p-md-50p {
        padding: 50% !important
    }

    .p-md-60p {
        padding: 60% !important
    }

    .p-md-70p {
        padding: 70% !important
    }

    .p-md-75p {
        padding: 75% !important
    }

    .p-md-80p {
        padding: 80% !important
    }

    .p-md-90p {
        padding: 90% !important
    }

    .p-md-100p {
        padding: 100% !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-0-5 {
        padding-right: .125rem !important;
        padding-left: .125rem !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-md-6 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-md-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-md-8 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .px-md-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important
    }

    .px-md-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important
    }

    .px-md-11 {
        padding-right: 7rem !important;
        padding-left: 7rem !important
    }

    .px-md-12 {
        padding-right: 8rem !important;
        padding-left: 8rem !important
    }

    .px-md-gs {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-md-10p {
        padding-right: 10% !important;
        padding-left: 10% !important
    }

    .px-md-20p {
        padding-right: 20% !important;
        padding-left: 20% !important
    }

    .px-md-25p {
        padding-right: 25% !important;
        padding-left: 25% !important
    }

    .px-md-30p {
        padding-right: 30% !important;
        padding-left: 30% !important
    }

    .px-md-35p {
        padding-right: 35% !important;
        padding-left: 35% !important
    }

    .px-md-40p {
        padding-right: 40% !important;
        padding-left: 40% !important
    }

    .px-md-45p {
        padding-right: 45% !important;
        padding-left: 45% !important
    }

    .px-md-50p {
        padding-right: 50% !important;
        padding-left: 50% !important
    }

    .px-md-60p {
        padding-right: 60% !important;
        padding-left: 60% !important
    }

    .px-md-70p {
        padding-right: 70% !important;
        padding-left: 70% !important
    }

    .px-md-75p {
        padding-right: 75% !important;
        padding-left: 75% !important
    }

    .px-md-80p {
        padding-right: 80% !important;
        padding-left: 80% !important
    }

    .px-md-90p {
        padding-right: 90% !important;
        padding-left: 90% !important
    }

    .px-md-100p {
        padding-right: 100% !important;
        padding-left: 100% !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-0-5 {
        padding-top: .125rem !important;
        padding-bottom: .125rem !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-md-6 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-md-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-md-8 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .py-md-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .py-md-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important
    }

    .py-md-11 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important
    }

    .py-md-12 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important
    }

    .py-md-gs {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-md-10p {
        padding-top: 10% !important;
        padding-bottom: 10% !important
    }

    .py-md-20p {
        padding-top: 20% !important;
        padding-bottom: 20% !important
    }

    .py-md-25p {
        padding-top: 25% !important;
        padding-bottom: 25% !important
    }

    .py-md-30p {
        padding-top: 30% !important;
        padding-bottom: 30% !important
    }

    .py-md-35p {
        padding-top: 35% !important;
        padding-bottom: 35% !important
    }

    .py-md-40p {
        padding-top: 40% !important;
        padding-bottom: 40% !important
    }

    .py-md-45p {
        padding-top: 45% !important;
        padding-bottom: 45% !important
    }

    .py-md-50p {
        padding-top: 50% !important;
        padding-bottom: 50% !important
    }

    .py-md-60p {
        padding-top: 60% !important;
        padding-bottom: 60% !important
    }

    .py-md-70p {
        padding-top: 70% !important;
        padding-bottom: 70% !important
    }

    .py-md-75p {
        padding-top: 75% !important;
        padding-bottom: 75% !important
    }

    .py-md-80p {
        padding-top: 80% !important;
        padding-bottom: 80% !important
    }

    .py-md-90p {
        padding-top: 90% !important;
        padding-bottom: 90% !important
    }

    .py-md-100p {
        padding-top: 100% !important;
        padding-bottom: 100% !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-0-5 {
        padding-top: .125rem !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 2rem !important
    }

    .pt-md-6 {
        padding-top: 3.5rem !important
    }

    .pt-md-7 {
        padding-top: 4rem !important
    }

    .pt-md-8 {
        padding-top: 4.5rem !important
    }

    .pt-md-9 {
        padding-top: 5rem !important
    }

    .pt-md-10 {
        padding-top: 6rem !important
    }

    .pt-md-11 {
        padding-top: 7rem !important
    }

    .pt-md-12 {
        padding-top: 8rem !important
    }

    .pt-md-gs {
        padding-top: 2rem !important
    }

    .pt-md-10p {
        padding-top: 10% !important
    }

    .pt-md-20p {
        padding-top: 20% !important
    }

    .pt-md-25p {
        padding-top: 25% !important
    }

    .pt-md-30p {
        padding-top: 30% !important
    }

    .pt-md-35p {
        padding-top: 35% !important
    }

    .pt-md-40p {
        padding-top: 40% !important
    }

    .pt-md-45p {
        padding-top: 45% !important
    }

    .pt-md-50p {
        padding-top: 50% !important
    }

    .pt-md-60p {
        padding-top: 60% !important
    }

    .pt-md-70p {
        padding-top: 70% !important
    }

    .pt-md-75p {
        padding-top: 75% !important
    }

    .pt-md-80p {
        padding-top: 80% !important
    }

    .pt-md-90p {
        padding-top: 90% !important
    }

    .pt-md-100p {
        padding-top: 100% !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-0-5 {
        padding-right: .125rem !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 2rem !important
    }

    .pe-md-6 {
        padding-right: 3.5rem !important
    }

    .pe-md-7 {
        padding-right: 4rem !important
    }

    .pe-md-8 {
        padding-right: 4.5rem !important
    }

    .pe-md-9 {
        padding-right: 5rem !important
    }

    .pe-md-10 {
        padding-right: 6rem !important
    }

    .pe-md-11 {
        padding-right: 7rem !important
    }

    .pe-md-12 {
        padding-right: 8rem !important
    }

    .pe-md-gs {
        padding-right: 2rem !important
    }

    .pe-md-10p {
        padding-right: 10% !important
    }

    .pe-md-20p {
        padding-right: 20% !important
    }

    .pe-md-25p {
        padding-right: 25% !important
    }

    .pe-md-30p {
        padding-right: 30% !important
    }

    .pe-md-35p {
        padding-right: 35% !important
    }

    .pe-md-40p {
        padding-right: 40% !important
    }

    .pe-md-45p {
        padding-right: 45% !important
    }

    .pe-md-50p {
        padding-right: 50% !important
    }

    .pe-md-60p {
        padding-right: 60% !important
    }

    .pe-md-70p {
        padding-right: 70% !important
    }

    .pe-md-75p {
        padding-right: 75% !important
    }

    .pe-md-80p {
        padding-right: 80% !important
    }

    .pe-md-90p {
        padding-right: 90% !important
    }

    .pe-md-100p {
        padding-right: 100% !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-0-5 {
        padding-bottom: .125rem !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 2rem !important
    }

    .pb-md-6 {
        padding-bottom: 3.5rem !important
    }

    .pb-md-7 {
        padding-bottom: 4rem !important
    }

    .pb-md-8 {
        padding-bottom: 4.5rem !important
    }

    .pb-md-9 {
        padding-bottom: 5rem !important
    }

    .pb-md-10 {
        padding-bottom: 6rem !important
    }

    .pb-md-11 {
        padding-bottom: 7rem !important
    }

    .pb-md-12 {
        padding-bottom: 8rem !important
    }

    .pb-md-gs {
        padding-bottom: 2rem !important
    }

    .pb-md-10p {
        padding-bottom: 10% !important
    }

    .pb-md-20p {
        padding-bottom: 20% !important
    }

    .pb-md-25p {
        padding-bottom: 25% !important
    }

    .pb-md-30p {
        padding-bottom: 30% !important
    }

    .pb-md-35p {
        padding-bottom: 35% !important
    }

    .pb-md-40p {
        padding-bottom: 40% !important
    }

    .pb-md-45p {
        padding-bottom: 45% !important
    }

    .pb-md-50p {
        padding-bottom: 50% !important
    }

    .pb-md-60p {
        padding-bottom: 60% !important
    }

    .pb-md-70p {
        padding-bottom: 70% !important
    }

    .pb-md-75p {
        padding-bottom: 75% !important
    }

    .pb-md-80p {
        padding-bottom: 80% !important
    }

    .pb-md-90p {
        padding-bottom: 90% !important
    }

    .pb-md-100p {
        padding-bottom: 100% !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-0-5 {
        padding-left: .125rem !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 2rem !important
    }

    .ps-md-6 {
        padding-left: 3.5rem !important
    }

    .ps-md-7 {
        padding-left: 4rem !important
    }

    .ps-md-8 {
        padding-left: 4.5rem !important
    }

    .ps-md-9 {
        padding-left: 5rem !important
    }

    .ps-md-10 {
        padding-left: 6rem !important
    }

    .ps-md-11 {
        padding-left: 7rem !important
    }

    .ps-md-12 {
        padding-left: 8rem !important
    }

    .ps-md-gs {
        padding-left: 2rem !important
    }

    .ps-md-10p {
        padding-left: 10% !important
    }

    .ps-md-20p {
        padding-left: 20% !important
    }

    .ps-md-25p {
        padding-left: 25% !important
    }

    .ps-md-30p {
        padding-left: 30% !important
    }

    .ps-md-35p {
        padding-left: 35% !important
    }

    .ps-md-40p {
        padding-left: 40% !important
    }

    .ps-md-45p {
        padding-left: 45% !important
    }

    .ps-md-50p {
        padding-left: 50% !important
    }

    .ps-md-60p {
        padding-left: 60% !important
    }

    .ps-md-70p {
        padding-left: 70% !important
    }

    .ps-md-75p {
        padding-left: 75% !important
    }

    .ps-md-80p {
        padding-left: 80% !important
    }

    .ps-md-90p {
        padding-left: 90% !important
    }

    .ps-md-100p {
        padding-left: 100% !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-0-5 {
        gap: .125rem !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 2rem !important
    }

    .gap-md-6 {
        gap: 3.5rem !important
    }

    .gap-md-7 {
        gap: 4rem !important
    }

    .gap-md-8 {
        gap: 4.5rem !important
    }

    .gap-md-9 {
        gap: 5rem !important
    }

    .gap-md-10 {
        gap: 6rem !important
    }

    .gap-md-11 {
        gap: 7rem !important
    }

    .gap-md-12 {
        gap: 8rem !important
    }

    .gap-md-gs {
        gap: 2rem !important
    }

    .gap-md-10p {
        gap: 10% !important
    }

    .gap-md-20p {
        gap: 20% !important
    }

    .gap-md-25p {
        gap: 25% !important
    }

    .gap-md-30p {
        gap: 30% !important
    }

    .gap-md-35p {
        gap: 35% !important
    }

    .gap-md-40p {
        gap: 40% !important
    }

    .gap-md-45p {
        gap: 45% !important
    }

    .gap-md-50p {
        gap: 50% !important
    }

    .gap-md-60p {
        gap: 60% !important
    }

    .gap-md-70p {
        gap: 70% !important
    }

    .gap-md-75p {
        gap: 75% !important
    }

    .gap-md-80p {
        gap: 80% !important
    }

    .gap-md-90p {
        gap: 90% !important
    }

    .gap-md-100p {
        gap: 100% !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width: 992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .position-lg-static {
        position: static !important
    }

    .position-lg-relative {
        position: relative !important
    }

    .position-lg-absolute {
        position: absolute !important
    }

    .position-lg-fixed {
        position: fixed !important
    }

    .position-lg-sticky {
        position: sticky !important
    }

    .top-lg-auto {
        top: auto !important
    }

    .top-lg-0 {
        top: 0 !important
    }

    .top-lg-50 {
        top: 50% !important
    }

    .top-lg-100 {
        top: 100% !important
    }

    .bottom-lg-auto {
        bottom: auto !important
    }

    .bottom-lg-0 {
        bottom: 0 !important
    }

    .bottom-lg-50 {
        bottom: 50% !important
    }

    .bottom-lg-100 {
        bottom: 100% !important
    }

    .start-lg-auto {
        left: auto !important
    }

    .start-lg-0 {
        left: 0 !important
    }

    .start-lg-50 {
        left: 50% !important
    }

    .start-lg-100 {
        left: 100% !important
    }

    .end-lg-auto {
        right: auto !important
    }

    .end-lg-0 {
        right: 0 !important
    }

    .end-lg-50 {
        right: 50% !important
    }

    .end-lg-100 {
        right: 100% !important
    }

    .translate-middle-lg {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-lg-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-lg-y {
        transform: translateY(-50%) !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .w-lg-1rem {
        width: 1rem !important
    }

    .w-lg-1-5rem {
        width: 1.5rem !important
    }

    .w-lg-2rem {
        width: 2rem !important
    }

    .w-lg-3rem {
        width: 3rem !important
    }

    .w-lg-4rem {
        width: 4rem !important
    }

    .w-lg-5rem {
        width: 5rem !important
    }

    .w-lg-6rem {
        width: 6rem !important
    }

    .w-lg-7rem {
        width: 7rem !important
    }

    .w-lg-8rem {
        width: 8rem !important
    }

    .w-lg-10rem {
        width: 10rem !important
    }

    .w-lg-12rem {
        width: 12rem !important
    }

    .w-lg-16rem {
        width: 16rem !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .h-lg-1rem {
        height: 1rem !important
    }

    .h-lg-1-5rem {
        height: 1.5rem !important
    }

    .h-lg-2rem {
        height: 2rem !important
    }

    .h-lg-3rem {
        height: 3rem !important
    }

    .h-lg-4rem {
        height: 4rem !important
    }

    .h-lg-5rem {
        height: 5rem !important
    }

    .h-lg-6rem {
        height: 6rem !important
    }

    .h-lg-7rem {
        height: 7rem !important
    }

    .h-lg-8rem {
        height: 8rem !important
    }

    .h-lg-10rem {
        height: 10rem !important
    }

    .h-lg-12rem {
        height: 12rem !important
    }

    .h-lg-16rem {
        height: 16rem !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shritw-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shritw-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-0-5 {
        margin: .125rem !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 2rem !important
    }

    .m-lg-6 {
        margin: 3.5rem !important
    }

    .m-lg-7 {
        margin: 4rem !important
    }

    .m-lg-8 {
        margin: 4.5rem !important
    }

    .m-lg-9 {
        margin: 5rem !important
    }

    .m-lg-10 {
        margin: 6rem !important
    }

    .m-lg-11 {
        margin: 7rem !important
    }

    .m-lg-12 {
        margin: 8rem !important
    }

    .m-lg-gs {
        margin: 2rem !important
    }

    .m-lg-10p {
        margin: 10% !important
    }

    .m-lg-20p {
        margin: 20% !important
    }

    .m-lg-25p {
        margin: 25% !important
    }

    .m-lg-30p {
        margin: 30% !important
    }

    .m-lg-35p {
        margin: 35% !important
    }

    .m-lg-40p {
        margin: 40% !important
    }

    .m-lg-45p {
        margin: 45% !important
    }

    .m-lg-50p {
        margin: 50% !important
    }

    .m-lg-60p {
        margin: 60% !important
    }

    .m-lg-70p {
        margin: 70% !important
    }

    .m-lg-75p {
        margin: 75% !important
    }

    .m-lg-80p {
        margin: 80% !important
    }

    .m-lg-90p {
        margin: 90% !important
    }

    .m-lg-100p {
        margin: 100% !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-0-5 {
        margin-right: .125rem !important;
        margin-left: .125rem !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-lg-6 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-lg-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-lg-8 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-lg-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important
    }

    .mx-lg-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important
    }

    .mx-lg-11 {
        margin-right: 7rem !important;
        margin-left: 7rem !important
    }

    .mx-lg-12 {
        margin-right: 8rem !important;
        margin-left: 8rem !important
    }

    .mx-lg-gs {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-lg-10p {
        margin-right: 10% !important;
        margin-left: 10% !important
    }

    .mx-lg-20p {
        margin-right: 20% !important;
        margin-left: 20% !important
    }

    .mx-lg-25p {
        margin-right: 25% !important;
        margin-left: 25% !important
    }

    .mx-lg-30p {
        margin-right: 30% !important;
        margin-left: 30% !important
    }

    .mx-lg-35p {
        margin-right: 35% !important;
        margin-left: 35% !important
    }

    .mx-lg-40p {
        margin-right: 40% !important;
        margin-left: 40% !important
    }

    .mx-lg-45p {
        margin-right: 45% !important;
        margin-left: 45% !important
    }

    .mx-lg-50p {
        margin-right: 50% !important;
        margin-left: 50% !important
    }

    .mx-lg-60p {
        margin-right: 60% !important;
        margin-left: 60% !important
    }

    .mx-lg-70p {
        margin-right: 70% !important;
        margin-left: 70% !important
    }

    .mx-lg-75p {
        margin-right: 75% !important;
        margin-left: 75% !important
    }

    .mx-lg-80p {
        margin-right: 80% !important;
        margin-left: 80% !important
    }

    .mx-lg-90p {
        margin-right: 90% !important;
        margin-left: 90% !important
    }

    .mx-lg-100p {
        margin-right: 100% !important;
        margin-left: 100% !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-0-5 {
        margin-top: .125rem !important;
        margin-bottom: .125rem !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-lg-6 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-lg-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-lg-8 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-lg-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important
    }

    .my-lg-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important
    }

    .my-lg-11 {
        margin-top: 7rem !important;
        margin-bottom: 7rem !important
    }

    .my-lg-12 {
        margin-top: 8rem !important;
        margin-bottom: 8rem !important
    }

    .my-lg-gs {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-lg-10p {
        margin-top: 10% !important;
        margin-bottom: 10% !important
    }

    .my-lg-20p {
        margin-top: 20% !important;
        margin-bottom: 20% !important
    }

    .my-lg-25p {
        margin-top: 25% !important;
        margin-bottom: 25% !important
    }

    .my-lg-30p {
        margin-top: 30% !important;
        margin-bottom: 30% !important
    }

    .my-lg-35p {
        margin-top: 35% !important;
        margin-bottom: 35% !important
    }

    .my-lg-40p {
        margin-top: 40% !important;
        margin-bottom: 40% !important
    }

    .my-lg-45p {
        margin-top: 45% !important;
        margin-bottom: 45% !important
    }

    .my-lg-50p {
        margin-top: 50% !important;
        margin-bottom: 50% !important
    }

    .my-lg-60p {
        margin-top: 60% !important;
        margin-bottom: 60% !important
    }

    .my-lg-70p {
        margin-top: 70% !important;
        margin-bottom: 70% !important
    }

    .my-lg-75p {
        margin-top: 75% !important;
        margin-bottom: 75% !important
    }

    .my-lg-80p {
        margin-top: 80% !important;
        margin-bottom: 80% !important
    }

    .my-lg-90p {
        margin-top: 90% !important;
        margin-bottom: 90% !important
    }

    .my-lg-100p {
        margin-top: 100% !important;
        margin-bottom: 100% !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-0-5 {
        margin-top: .125rem !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 2rem !important
    }

    .mt-lg-6 {
        margin-top: 3.5rem !important
    }

    .mt-lg-7 {
        margin-top: 4rem !important
    }

    .mt-lg-8 {
        margin-top: 4.5rem !important
    }

    .mt-lg-9 {
        margin-top: 5rem !important
    }

    .mt-lg-10 {
        margin-top: 6rem !important
    }

    .mt-lg-11 {
        margin-top: 7rem !important
    }

    .mt-lg-12 {
        margin-top: 8rem !important
    }

    .mt-lg-gs {
        margin-top: 2rem !important
    }

    .mt-lg-10p {
        margin-top: 10% !important
    }

    .mt-lg-20p {
        margin-top: 20% !important
    }

    .mt-lg-25p {
        margin-top: 25% !important
    }

    .mt-lg-30p {
        margin-top: 30% !important
    }

    .mt-lg-35p {
        margin-top: 35% !important
    }

    .mt-lg-40p {
        margin-top: 40% !important
    }

    .mt-lg-45p {
        margin-top: 45% !important
    }

    .mt-lg-50p {
        margin-top: 50% !important
    }

    .mt-lg-60p {
        margin-top: 60% !important
    }

    .mt-lg-70p {
        margin-top: 70% !important
    }

    .mt-lg-75p {
        margin-top: 75% !important
    }

    .mt-lg-80p {
        margin-top: 80% !important
    }

    .mt-lg-90p {
        margin-top: 90% !important
    }

    .mt-lg-100p {
        margin-top: 100% !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-0-5 {
        margin-right: .125rem !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 2rem !important
    }

    .me-lg-6 {
        margin-right: 3.5rem !important
    }

    .me-lg-7 {
        margin-right: 4rem !important
    }

    .me-lg-8 {
        margin-right: 4.5rem !important
    }

    .me-lg-9 {
        margin-right: 5rem !important
    }

    .me-lg-10 {
        margin-right: 6rem !important
    }

    .me-lg-11 {
        margin-right: 7rem !important
    }

    .me-lg-12 {
        margin-right: 8rem !important
    }

    .me-lg-gs {
        margin-right: 2rem !important
    }

    .me-lg-10p {
        margin-right: 10% !important
    }

    .me-lg-20p {
        margin-right: 20% !important
    }

    .me-lg-25p {
        margin-right: 25% !important
    }

    .me-lg-30p {
        margin-right: 30% !important
    }

    .me-lg-35p {
        margin-right: 35% !important
    }

    .me-lg-40p {
        margin-right: 40% !important
    }

    .me-lg-45p {
        margin-right: 45% !important
    }

    .me-lg-50p {
        margin-right: 50% !important
    }

    .me-lg-60p {
        margin-right: 60% !important
    }

    .me-lg-70p {
        margin-right: 70% !important
    }

    .me-lg-75p {
        margin-right: 75% !important
    }

    .me-lg-80p {
        margin-right: 80% !important
    }

    .me-lg-90p {
        margin-right: 90% !important
    }

    .me-lg-100p {
        margin-right: 100% !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-0-5 {
        margin-bottom: .125rem !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 2rem !important
    }

    .mb-lg-6 {
        margin-bottom: 3.5rem !important
    }

    .mb-lg-7 {
        margin-bottom: 4rem !important
    }

    .mb-lg-8 {
        margin-bottom: 4.5rem !important
    }

    .mb-lg-9 {
        margin-bottom: 5rem !important
    }

    .mb-lg-10 {
        margin-bottom: 6rem !important
    }

    .mb-lg-11 {
        margin-bottom: 7rem !important
    }

    .mb-lg-12 {
        margin-bottom: 8rem !important
    }

    .mb-lg-gs {
        margin-bottom: 2rem !important
    }

    .mb-lg-10p {
        margin-bottom: 10% !important
    }

    .mb-lg-20p {
        margin-bottom: 20% !important
    }

    .mb-lg-25p {
        margin-bottom: 25% !important
    }

    .mb-lg-30p {
        margin-bottom: 30% !important
    }

    .mb-lg-35p {
        margin-bottom: 35% !important
    }

    .mb-lg-40p {
        margin-bottom: 40% !important
    }

    .mb-lg-45p {
        margin-bottom: 45% !important
    }

    .mb-lg-50p {
        margin-bottom: 50% !important
    }

    .mb-lg-60p {
        margin-bottom: 60% !important
    }

    .mb-lg-70p {
        margin-bottom: 70% !important
    }

    .mb-lg-75p {
        margin-bottom: 75% !important
    }

    .mb-lg-80p {
        margin-bottom: 80% !important
    }

    .mb-lg-90p {
        margin-bottom: 90% !important
    }

    .mb-lg-100p {
        margin-bottom: 100% !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-0-5 {
        margin-left: .125rem !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 2rem !important
    }

    .ms-lg-6 {
        margin-left: 3.5rem !important
    }

    .ms-lg-7 {
        margin-left: 4rem !important
    }

    .ms-lg-8 {
        margin-left: 4.5rem !important
    }

    .ms-lg-9 {
        margin-left: 5rem !important
    }

    .ms-lg-10 {
        margin-left: 6rem !important
    }

    .ms-lg-11 {
        margin-left: 7rem !important
    }

    .ms-lg-12 {
        margin-left: 8rem !important
    }

    .ms-lg-gs {
        margin-left: 2rem !important
    }

    .ms-lg-10p {
        margin-left: 10% !important
    }

    .ms-lg-20p {
        margin-left: 20% !important
    }

    .ms-lg-25p {
        margin-left: 25% !important
    }

    .ms-lg-30p {
        margin-left: 30% !important
    }

    .ms-lg-35p {
        margin-left: 35% !important
    }

    .ms-lg-40p {
        margin-left: 40% !important
    }

    .ms-lg-45p {
        margin-left: 45% !important
    }

    .ms-lg-50p {
        margin-left: 50% !important
    }

    .ms-lg-60p {
        margin-left: 60% !important
    }

    .ms-lg-70p {
        margin-left: 70% !important
    }

    .ms-lg-75p {
        margin-left: 75% !important
    }

    .ms-lg-80p {
        margin-left: 80% !important
    }

    .ms-lg-90p {
        margin-left: 90% !important
    }

    .ms-lg-100p {
        margin-left: 100% !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n0-5 {
        margin: -0.125rem !important
    }

    .m-lg-n1 {
        margin: -0.25rem !important
    }

    .m-lg-n2 {
        margin: -0.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -2rem !important
    }

    .m-lg-n6 {
        margin: -3.5rem !important
    }

    .m-lg-n7 {
        margin: -4rem !important
    }

    .m-lg-n8 {
        margin: -4.5rem !important
    }

    .m-lg-n9 {
        margin: -5rem !important
    }

    .m-lg-n10 {
        margin: -6rem !important
    }

    .m-lg-n11 {
        margin: -7rem !important
    }

    .m-lg-n12 {
        margin: -8rem !important
    }

    .m-lg-ngs {
        margin: -2rem !important
    }

    .m-lg-n10p {
        margin: -10% !important
    }

    .m-lg-n20p {
        margin: -20% !important
    }

    .m-lg-n25p {
        margin: -25% !important
    }

    .m-lg-n30p {
        margin: -30% !important
    }

    .m-lg-n35p {
        margin: -35% !important
    }

    .m-lg-n40p {
        margin: -40% !important
    }

    .m-lg-n45p {
        margin: -45% !important
    }

    .m-lg-n50p {
        margin: -50% !important
    }

    .m-lg-n60p {
        margin: -60% !important
    }

    .m-lg-n70p {
        margin: -70% !important
    }

    .m-lg-n75p {
        margin: -75% !important
    }

    .m-lg-n80p {
        margin: -80% !important
    }

    .m-lg-n90p {
        margin: -90% !important
    }

    .m-lg-n100p {
        margin: -100% !important
    }

    .mx-lg-n0-5 {
        margin-right: -0.125rem !important;
        margin-left: -0.125rem !important
    }

    .mx-lg-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-lg-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-lg-n6 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-lg-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-lg-n8 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .mx-lg-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important
    }

    .mx-lg-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important
    }

    .mx-lg-n11 {
        margin-right: -7rem !important;
        margin-left: -7rem !important
    }

    .mx-lg-n12 {
        margin-right: -8rem !important;
        margin-left: -8rem !important
    }

    .mx-lg-ngs {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-lg-n10p {
        margin-right: -10% !important;
        margin-left: -10% !important
    }

    .mx-lg-n20p {
        margin-right: -20% !important;
        margin-left: -20% !important
    }

    .mx-lg-n25p {
        margin-right: -25% !important;
        margin-left: -25% !important
    }

    .mx-lg-n30p {
        margin-right: -30% !important;
        margin-left: -30% !important
    }

    .mx-lg-n35p {
        margin-right: -35% !important;
        margin-left: -35% !important
    }

    .mx-lg-n40p {
        margin-right: -40% !important;
        margin-left: -40% !important
    }

    .mx-lg-n45p {
        margin-right: -45% !important;
        margin-left: -45% !important
    }

    .mx-lg-n50p {
        margin-right: -50% !important;
        margin-left: -50% !important
    }

    .mx-lg-n60p {
        margin-right: -60% !important;
        margin-left: -60% !important
    }

    .mx-lg-n70p {
        margin-right: -70% !important;
        margin-left: -70% !important
    }

    .mx-lg-n75p {
        margin-right: -75% !important;
        margin-left: -75% !important
    }

    .mx-lg-n80p {
        margin-right: -80% !important;
        margin-left: -80% !important
    }

    .mx-lg-n90p {
        margin-right: -90% !important;
        margin-left: -90% !important
    }

    .mx-lg-n100p {
        margin-right: -100% !important;
        margin-left: -100% !important
    }

    .my-lg-n0-5 {
        margin-top: -0.125rem !important;
        margin-bottom: -0.125rem !important
    }

    .my-lg-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-lg-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-lg-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-lg-n6 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-lg-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-lg-n8 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .my-lg-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important
    }

    .my-lg-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important
    }

    .my-lg-n11 {
        margin-top: -7rem !important;
        margin-bottom: -7rem !important
    }

    .my-lg-n12 {
        margin-top: -8rem !important;
        margin-bottom: -8rem !important
    }

    .my-lg-ngs {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-lg-n10p {
        margin-top: -10% !important;
        margin-bottom: -10% !important
    }

    .my-lg-n20p {
        margin-top: -20% !important;
        margin-bottom: -20% !important
    }

    .my-lg-n25p {
        margin-top: -25% !important;
        margin-bottom: -25% !important
    }

    .my-lg-n30p {
        margin-top: -30% !important;
        margin-bottom: -30% !important
    }

    .my-lg-n35p {
        margin-top: -35% !important;
        margin-bottom: -35% !important
    }

    .my-lg-n40p {
        margin-top: -40% !important;
        margin-bottom: -40% !important
    }

    .my-lg-n45p {
        margin-top: -45% !important;
        margin-bottom: -45% !important
    }

    .my-lg-n50p {
        margin-top: -50% !important;
        margin-bottom: -50% !important
    }

    .my-lg-n60p {
        margin-top: -60% !important;
        margin-bottom: -60% !important
    }

    .my-lg-n70p {
        margin-top: -70% !important;
        margin-bottom: -70% !important
    }

    .my-lg-n75p {
        margin-top: -75% !important;
        margin-bottom: -75% !important
    }

    .my-lg-n80p {
        margin-top: -80% !important;
        margin-bottom: -80% !important
    }

    .my-lg-n90p {
        margin-top: -90% !important;
        margin-bottom: -90% !important
    }

    .my-lg-n100p {
        margin-top: -100% !important;
        margin-bottom: -100% !important
    }

    .mt-lg-n0-5 {
        margin-top: -0.125rem !important
    }

    .mt-lg-n1 {
        margin-top: -0.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -0.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -2rem !important
    }

    .mt-lg-n6 {
        margin-top: -3.5rem !important
    }

    .mt-lg-n7 {
        margin-top: -4rem !important
    }

    .mt-lg-n8 {
        margin-top: -4.5rem !important
    }

    .mt-lg-n9 {
        margin-top: -5rem !important
    }

    .mt-lg-n10 {
        margin-top: -6rem !important
    }

    .mt-lg-n11 {
        margin-top: -7rem !important
    }

    .mt-lg-n12 {
        margin-top: -8rem !important
    }

    .mt-lg-ngs {
        margin-top: -2rem !important
    }

    .mt-lg-n10p {
        margin-top: -10% !important
    }

    .mt-lg-n20p {
        margin-top: -20% !important
    }

    .mt-lg-n25p {
        margin-top: -25% !important
    }

    .mt-lg-n30p {
        margin-top: -30% !important
    }

    .mt-lg-n35p {
        margin-top: -35% !important
    }

    .mt-lg-n40p {
        margin-top: -40% !important
    }

    .mt-lg-n45p {
        margin-top: -45% !important
    }

    .mt-lg-n50p {
        margin-top: -50% !important
    }

    .mt-lg-n60p {
        margin-top: -60% !important
    }

    .mt-lg-n70p {
        margin-top: -70% !important
    }

    .mt-lg-n75p {
        margin-top: -75% !important
    }

    .mt-lg-n80p {
        margin-top: -80% !important
    }

    .mt-lg-n90p {
        margin-top: -90% !important
    }

    .mt-lg-n100p {
        margin-top: -100% !important
    }

    .me-lg-n0-5 {
        margin-right: -0.125rem !important
    }

    .me-lg-n1 {
        margin-right: -0.25rem !important
    }

    .me-lg-n2 {
        margin-right: -0.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -2rem !important
    }

    .me-lg-n6 {
        margin-right: -3.5rem !important
    }

    .me-lg-n7 {
        margin-right: -4rem !important
    }

    .me-lg-n8 {
        margin-right: -4.5rem !important
    }

    .me-lg-n9 {
        margin-right: -5rem !important
    }

    .me-lg-n10 {
        margin-right: -6rem !important
    }

    .me-lg-n11 {
        margin-right: -7rem !important
    }

    .me-lg-n12 {
        margin-right: -8rem !important
    }

    .me-lg-ngs {
        margin-right: -2rem !important
    }

    .me-lg-n10p {
        margin-right: -10% !important
    }

    .me-lg-n20p {
        margin-right: -20% !important
    }

    .me-lg-n25p {
        margin-right: -25% !important
    }

    .me-lg-n30p {
        margin-right: -30% !important
    }

    .me-lg-n35p {
        margin-right: -35% !important
    }

    .me-lg-n40p {
        margin-right: -40% !important
    }

    .me-lg-n45p {
        margin-right: -45% !important
    }

    .me-lg-n50p {
        margin-right: -50% !important
    }

    .me-lg-n60p {
        margin-right: -60% !important
    }

    .me-lg-n70p {
        margin-right: -70% !important
    }

    .me-lg-n75p {
        margin-right: -75% !important
    }

    .me-lg-n80p {
        margin-right: -80% !important
    }

    .me-lg-n90p {
        margin-right: -90% !important
    }

    .me-lg-n100p {
        margin-right: -100% !important
    }

    .mb-lg-n0-5 {
        margin-bottom: -0.125rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -2rem !important
    }

    .mb-lg-n6 {
        margin-bottom: -3.5rem !important
    }

    .mb-lg-n7 {
        margin-bottom: -4rem !important
    }

    .mb-lg-n8 {
        margin-bottom: -4.5rem !important
    }

    .mb-lg-n9 {
        margin-bottom: -5rem !important
    }

    .mb-lg-n10 {
        margin-bottom: -6rem !important
    }

    .mb-lg-n11 {
        margin-bottom: -7rem !important
    }

    .mb-lg-n12 {
        margin-bottom: -8rem !important
    }

    .mb-lg-ngs {
        margin-bottom: -2rem !important
    }

    .mb-lg-n10p {
        margin-bottom: -10% !important
    }

    .mb-lg-n20p {
        margin-bottom: -20% !important
    }

    .mb-lg-n25p {
        margin-bottom: -25% !important
    }

    .mb-lg-n30p {
        margin-bottom: -30% !important
    }

    .mb-lg-n35p {
        margin-bottom: -35% !important
    }

    .mb-lg-n40p {
        margin-bottom: -40% !important
    }

    .mb-lg-n45p {
        margin-bottom: -45% !important
    }

    .mb-lg-n50p {
        margin-bottom: -50% !important
    }

    .mb-lg-n60p {
        margin-bottom: -60% !important
    }

    .mb-lg-n70p {
        margin-bottom: -70% !important
    }

    .mb-lg-n75p {
        margin-bottom: -75% !important
    }

    .mb-lg-n80p {
        margin-bottom: -80% !important
    }

    .mb-lg-n90p {
        margin-bottom: -90% !important
    }

    .mb-lg-n100p {
        margin-bottom: -100% !important
    }

    .ms-lg-n0-5 {
        margin-left: -0.125rem !important
    }

    .ms-lg-n1 {
        margin-left: -0.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -0.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -2rem !important
    }

    .ms-lg-n6 {
        margin-left: -3.5rem !important
    }

    .ms-lg-n7 {
        margin-left: -4rem !important
    }

    .ms-lg-n8 {
        margin-left: -4.5rem !important
    }

    .ms-lg-n9 {
        margin-left: -5rem !important
    }

    .ms-lg-n10 {
        margin-left: -6rem !important
    }

    .ms-lg-n11 {
        margin-left: -7rem !important
    }

    .ms-lg-n12 {
        margin-left: -8rem !important
    }

    .ms-lg-ngs {
        margin-left: -2rem !important
    }

    .ms-lg-n10p {
        margin-left: -10% !important
    }

    .ms-lg-n20p {
        margin-left: -20% !important
    }

    .ms-lg-n25p {
        margin-left: -25% !important
    }

    .ms-lg-n30p {
        margin-left: -30% !important
    }

    .ms-lg-n35p {
        margin-left: -35% !important
    }

    .ms-lg-n40p {
        margin-left: -40% !important
    }

    .ms-lg-n45p {
        margin-left: -45% !important
    }

    .ms-lg-n50p {
        margin-left: -50% !important
    }

    .ms-lg-n60p {
        margin-left: -60% !important
    }

    .ms-lg-n70p {
        margin-left: -70% !important
    }

    .ms-lg-n75p {
        margin-left: -75% !important
    }

    .ms-lg-n80p {
        margin-left: -80% !important
    }

    .ms-lg-n90p {
        margin-left: -90% !important
    }

    .ms-lg-n100p {
        margin-left: -100% !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-0-5 {
        padding: .125rem !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 2rem !important
    }

    .p-lg-6 {
        padding: 3.5rem !important
    }

    .p-lg-7 {
        padding: 4rem !important
    }

    .p-lg-8 {
        padding: 4.5rem !important
    }

    .p-lg-9 {
        padding: 5rem !important
    }

    .p-lg-10 {
        padding: 6rem !important
    }

    .p-lg-11 {
        padding: 7rem !important
    }

    .p-lg-12 {
        padding: 8rem !important
    }

    .p-lg-gs {
        padding: 2rem !important
    }

    .p-lg-10p {
        padding: 10% !important
    }

    .p-lg-20p {
        padding: 20% !important
    }

    .p-lg-25p {
        padding: 25% !important
    }

    .p-lg-30p {
        padding: 30% !important
    }

    .p-lg-35p {
        padding: 35% !important
    }

    .p-lg-40p {
        padding: 40% !important
    }

    .p-lg-45p {
        padding: 45% !important
    }

    .p-lg-50p {
        padding: 50% !important
    }

    .p-lg-60p {
        padding: 60% !important
    }

    .p-lg-70p {
        padding: 70% !important
    }

    .p-lg-75p {
        padding: 75% !important
    }

    .p-lg-80p {
        padding: 80% !important
    }

    .p-lg-90p {
        padding: 90% !important
    }

    .p-lg-100p {
        padding: 100% !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-0-5 {
        padding-right: .125rem !important;
        padding-left: .125rem !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-lg-6 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-lg-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-lg-8 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .px-lg-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important
    }

    .px-lg-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important
    }

    .px-lg-11 {
        padding-right: 7rem !important;
        padding-left: 7rem !important
    }

    .px-lg-12 {
        padding-right: 8rem !important;
        padding-left: 8rem !important
    }

    .px-lg-gs {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-lg-10p {
        padding-right: 10% !important;
        padding-left: 10% !important
    }

    .px-lg-20p {
        padding-right: 20% !important;
        padding-left: 20% !important
    }

    .px-lg-25p {
        padding-right: 25% !important;
        padding-left: 25% !important
    }

    .px-lg-30p {
        padding-right: 30% !important;
        padding-left: 30% !important
    }

    .px-lg-35p {
        padding-right: 35% !important;
        padding-left: 35% !important
    }

    .px-lg-40p {
        padding-right: 40% !important;
        padding-left: 40% !important
    }

    .px-lg-45p {
        padding-right: 45% !important;
        padding-left: 45% !important
    }

    .px-lg-50p {
        padding-right: 50% !important;
        padding-left: 50% !important
    }

    .px-lg-60p {
        padding-right: 60% !important;
        padding-left: 60% !important
    }

    .px-lg-70p {
        padding-right: 70% !important;
        padding-left: 70% !important
    }

    .px-lg-75p {
        padding-right: 75% !important;
        padding-left: 75% !important
    }

    .px-lg-80p {
        padding-right: 80% !important;
        padding-left: 80% !important
    }

    .px-lg-90p {
        padding-right: 90% !important;
        padding-left: 90% !important
    }

    .px-lg-100p {
        padding-right: 100% !important;
        padding-left: 100% !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-0-5 {
        padding-top: .125rem !important;
        padding-bottom: .125rem !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-lg-6 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-lg-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-lg-8 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .py-lg-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .py-lg-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important
    }

    .py-lg-11 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important
    }

    .py-lg-12 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important
    }

    .py-lg-gs {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-lg-10p {
        padding-top: 10% !important;
        padding-bottom: 10% !important
    }

    .py-lg-20p {
        padding-top: 20% !important;
        padding-bottom: 20% !important
    }

    .py-lg-25p {
        padding-top: 25% !important;
        padding-bottom: 25% !important
    }

    .py-lg-30p {
        padding-top: 30% !important;
        padding-bottom: 30% !important
    }

    .py-lg-35p {
        padding-top: 35% !important;
        padding-bottom: 35% !important
    }

    .py-lg-40p {
        padding-top: 40% !important;
        padding-bottom: 40% !important
    }

    .py-lg-45p {
        padding-top: 45% !important;
        padding-bottom: 45% !important
    }

    .py-lg-50p {
        padding-top: 50% !important;
        padding-bottom: 50% !important
    }

    .py-lg-60p {
        padding-top: 60% !important;
        padding-bottom: 60% !important
    }

    .py-lg-70p {
        padding-top: 70% !important;
        padding-bottom: 70% !important
    }

    .py-lg-75p {
        padding-top: 75% !important;
        padding-bottom: 75% !important
    }

    .py-lg-80p {
        padding-top: 80% !important;
        padding-bottom: 80% !important
    }

    .py-lg-90p {
        padding-top: 90% !important;
        padding-bottom: 90% !important
    }

    .py-lg-100p {
        padding-top: 100% !important;
        padding-bottom: 100% !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-0-5 {
        padding-top: .125rem !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 2rem !important
    }

    .pt-lg-6 {
        padding-top: 3.5rem !important
    }

    .pt-lg-7 {
        padding-top: 4rem !important
    }

    .pt-lg-8 {
        padding-top: 4.5rem !important
    }

    .pt-lg-9 {
        padding-top: 5rem !important
    }

    .pt-lg-10 {
        padding-top: 6rem !important
    }

    .pt-lg-11 {
        padding-top: 7rem !important
    }

    .pt-lg-12 {
        padding-top: 8rem !important
    }

    .pt-lg-gs {
        padding-top: 2rem !important
    }

    .pt-lg-10p {
        padding-top: 10% !important
    }

    .pt-lg-20p {
        padding-top: 20% !important
    }

    .pt-lg-25p {
        padding-top: 25% !important
    }

    .pt-lg-30p {
        padding-top: 30% !important
    }

    .pt-lg-35p {
        padding-top: 35% !important
    }

    .pt-lg-40p {
        padding-top: 40% !important
    }

    .pt-lg-45p {
        padding-top: 45% !important
    }

    .pt-lg-50p {
        padding-top: 50% !important
    }

    .pt-lg-60p {
        padding-top: 60% !important
    }

    .pt-lg-70p {
        padding-top: 70% !important
    }

    .pt-lg-75p {
        padding-top: 75% !important
    }

    .pt-lg-80p {
        padding-top: 80% !important
    }

    .pt-lg-90p {
        padding-top: 90% !important
    }

    .pt-lg-100p {
        padding-top: 100% !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-0-5 {
        padding-right: .125rem !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 2rem !important
    }

    .pe-lg-6 {
        padding-right: 3.5rem !important
    }

    .pe-lg-7 {
        padding-right: 4rem !important
    }

    .pe-lg-8 {
        padding-right: 4.5rem !important
    }

    .pe-lg-9 {
        padding-right: 5rem !important
    }

    .pe-lg-10 {
        padding-right: 6rem !important
    }

    .pe-lg-11 {
        padding-right: 7rem !important
    }

    .pe-lg-12 {
        padding-right: 8rem !important
    }

    .pe-lg-gs {
        padding-right: 2rem !important
    }

    .pe-lg-10p {
        padding-right: 10% !important
    }

    .pe-lg-20p {
        padding-right: 20% !important
    }

    .pe-lg-25p {
        padding-right: 25% !important
    }

    .pe-lg-30p {
        padding-right: 30% !important
    }

    .pe-lg-35p {
        padding-right: 35% !important
    }

    .pe-lg-40p {
        padding-right: 40% !important
    }

    .pe-lg-45p {
        padding-right: 45% !important
    }

    .pe-lg-50p {
        padding-right: 50% !important
    }

    .pe-lg-60p {
        padding-right: 60% !important
    }

    .pe-lg-70p {
        padding-right: 70% !important
    }

    .pe-lg-75p {
        padding-right: 75% !important
    }

    .pe-lg-80p {
        padding-right: 80% !important
    }

    .pe-lg-90p {
        padding-right: 90% !important
    }

    .pe-lg-100p {
        padding-right: 100% !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-0-5 {
        padding-bottom: .125rem !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 2rem !important
    }

    .pb-lg-6 {
        padding-bottom: 3.5rem !important
    }

    .pb-lg-7 {
        padding-bottom: 4rem !important
    }

    .pb-lg-8 {
        padding-bottom: 4.5rem !important
    }

    .pb-lg-9 {
        padding-bottom: 5rem !important
    }

    .pb-lg-10 {
        padding-bottom: 6rem !important
    }

    .pb-lg-11 {
        padding-bottom: 7rem !important
    }

    .pb-lg-12 {
        padding-bottom: 8rem !important
    }

    .pb-lg-gs {
        padding-bottom: 2rem !important
    }

    .pb-lg-10p {
        padding-bottom: 10% !important
    }

    .pb-lg-20p {
        padding-bottom: 20% !important
    }

    .pb-lg-25p {
        padding-bottom: 25% !important
    }

    .pb-lg-30p {
        padding-bottom: 30% !important
    }

    .pb-lg-35p {
        padding-bottom: 35% !important
    }

    .pb-lg-40p {
        padding-bottom: 40% !important
    }

    .pb-lg-45p {
        padding-bottom: 45% !important
    }

    .pb-lg-50p {
        padding-bottom: 50% !important
    }

    .pb-lg-60p {
        padding-bottom: 60% !important
    }

    .pb-lg-70p {
        padding-bottom: 70% !important
    }

    .pb-lg-75p {
        padding-bottom: 75% !important
    }

    .pb-lg-80p {
        padding-bottom: 80% !important
    }

    .pb-lg-90p {
        padding-bottom: 90% !important
    }

    .pb-lg-100p {
        padding-bottom: 100% !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-0-5 {
        padding-left: .125rem !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 2rem !important
    }

    .ps-lg-6 {
        padding-left: 3.5rem !important
    }

    .ps-lg-7 {
        padding-left: 4rem !important
    }

    .ps-lg-8 {
        padding-left: 4.5rem !important
    }

    .ps-lg-9 {
        padding-left: 5rem !important
    }

    .ps-lg-10 {
        padding-left: 6rem !important
    }

    .ps-lg-11 {
        padding-left: 7rem !important
    }

    .ps-lg-12 {
        padding-left: 8rem !important
    }

    .ps-lg-gs {
        padding-left: 2rem !important
    }

    .ps-lg-10p {
        padding-left: 10% !important
    }

    .ps-lg-20p {
        padding-left: 20% !important
    }

    .ps-lg-25p {
        padding-left: 25% !important
    }

    .ps-lg-30p {
        padding-left: 30% !important
    }

    .ps-lg-35p {
        padding-left: 35% !important
    }

    .ps-lg-40p {
        padding-left: 40% !important
    }

    .ps-lg-45p {
        padding-left: 45% !important
    }

    .ps-lg-50p {
        padding-left: 50% !important
    }

    .ps-lg-60p {
        padding-left: 60% !important
    }

    .ps-lg-70p {
        padding-left: 70% !important
    }

    .ps-lg-75p {
        padding-left: 75% !important
    }

    .ps-lg-80p {
        padding-left: 80% !important
    }

    .ps-lg-90p {
        padding-left: 90% !important
    }

    .ps-lg-100p {
        padding-left: 100% !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-0-5 {
        gap: .125rem !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 2rem !important
    }

    .gap-lg-6 {
        gap: 3.5rem !important
    }

    .gap-lg-7 {
        gap: 4rem !important
    }

    .gap-lg-8 {
        gap: 4.5rem !important
    }

    .gap-lg-9 {
        gap: 5rem !important
    }

    .gap-lg-10 {
        gap: 6rem !important
    }

    .gap-lg-11 {
        gap: 7rem !important
    }

    .gap-lg-12 {
        gap: 8rem !important
    }

    .gap-lg-gs {
        gap: 2rem !important
    }

    .gap-lg-10p {
        gap: 10% !important
    }

    .gap-lg-20p {
        gap: 20% !important
    }

    .gap-lg-25p {
        gap: 25% !important
    }

    .gap-lg-30p {
        gap: 30% !important
    }

    .gap-lg-35p {
        gap: 35% !important
    }

    .gap-lg-40p {
        gap: 40% !important
    }

    .gap-lg-45p {
        gap: 45% !important
    }

    .gap-lg-50p {
        gap: 50% !important
    }

    .gap-lg-60p {
        gap: 60% !important
    }

    .gap-lg-70p {
        gap: 70% !important
    }

    .gap-lg-75p {
        gap: 75% !important
    }

    .gap-lg-80p {
        gap: 80% !important
    }

    .gap-lg-90p {
        gap: 90% !important
    }

    .gap-lg-100p {
        gap: 100% !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width: 1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .position-xl-static {
        position: static !important
    }

    .position-xl-relative {
        position: relative !important
    }

    .position-xl-absolute {
        position: absolute !important
    }

    .position-xl-fixed {
        position: fixed !important
    }

    .position-xl-sticky {
        position: sticky !important
    }

    .top-xl-auto {
        top: auto !important
    }

    .top-xl-0 {
        top: 0 !important
    }

    .top-xl-50 {
        top: 50% !important
    }

    .top-xl-100 {
        top: 100% !important
    }

    .bottom-xl-auto {
        bottom: auto !important
    }

    .bottom-xl-0 {
        bottom: 0 !important
    }

    .bottom-xl-50 {
        bottom: 50% !important
    }

    .bottom-xl-100 {
        bottom: 100% !important
    }

    .start-xl-auto {
        left: auto !important
    }

    .start-xl-0 {
        left: 0 !important
    }

    .start-xl-50 {
        left: 50% !important
    }

    .start-xl-100 {
        left: 100% !important
    }

    .end-xl-auto {
        right: auto !important
    }

    .end-xl-0 {
        right: 0 !important
    }

    .end-xl-50 {
        right: 50% !important
    }

    .end-xl-100 {
        right: 100% !important
    }

    .translate-middle-xl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xl-y {
        transform: translateY(-50%) !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .w-xl-1rem {
        width: 1rem !important
    }

    .w-xl-1-5rem {
        width: 1.5rem !important
    }

    .w-xl-2rem {
        width: 2rem !important
    }

    .w-xl-3rem {
        width: 3rem !important
    }

    .w-xl-4rem {
        width: 4rem !important
    }

    .w-xl-5rem {
        width: 5rem !important
    }

    .w-xl-6rem {
        width: 6rem !important
    }

    .w-xl-7rem {
        width: 7rem !important
    }

    .w-xl-8rem {
        width: 8rem !important
    }

    .w-xl-10rem {
        width: 10rem !important
    }

    .w-xl-12rem {
        width: 12rem !important
    }

    .w-xl-16rem {
        width: 16rem !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .h-xl-1rem {
        height: 1rem !important
    }

    .h-xl-1-5rem {
        height: 1.5rem !important
    }

    .h-xl-2rem {
        height: 2rem !important
    }

    .h-xl-3rem {
        height: 3rem !important
    }

    .h-xl-4rem {
        height: 4rem !important
    }

    .h-xl-5rem {
        height: 5rem !important
    }

    .h-xl-6rem {
        height: 6rem !important
    }

    .h-xl-7rem {
        height: 7rem !important
    }

    .h-xl-8rem {
        height: 8rem !important
    }

    .h-xl-10rem {
        height: 10rem !important
    }

    .h-xl-12rem {
        height: 12rem !important
    }

    .h-xl-16rem {
        height: 16rem !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shritw-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shritw-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-0-5 {
        margin: .125rem !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 2rem !important
    }

    .m-xl-6 {
        margin: 3.5rem !important
    }

    .m-xl-7 {
        margin: 4rem !important
    }

    .m-xl-8 {
        margin: 4.5rem !important
    }

    .m-xl-9 {
        margin: 5rem !important
    }

    .m-xl-10 {
        margin: 6rem !important
    }

    .m-xl-11 {
        margin: 7rem !important
    }

    .m-xl-12 {
        margin: 8rem !important
    }

    .m-xl-gs {
        margin: 2rem !important
    }

    .m-xl-10p {
        margin: 10% !important
    }

    .m-xl-20p {
        margin: 20% !important
    }

    .m-xl-25p {
        margin: 25% !important
    }

    .m-xl-30p {
        margin: 30% !important
    }

    .m-xl-35p {
        margin: 35% !important
    }

    .m-xl-40p {
        margin: 40% !important
    }

    .m-xl-45p {
        margin: 45% !important
    }

    .m-xl-50p {
        margin: 50% !important
    }

    .m-xl-60p {
        margin: 60% !important
    }

    .m-xl-70p {
        margin: 70% !important
    }

    .m-xl-75p {
        margin: 75% !important
    }

    .m-xl-80p {
        margin: 80% !important
    }

    .m-xl-90p {
        margin: 90% !important
    }

    .m-xl-100p {
        margin: 100% !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-0-5 {
        margin-right: .125rem !important;
        margin-left: .125rem !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xl-6 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-xl-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-xl-8 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-xl-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important
    }

    .mx-xl-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important
    }

    .mx-xl-11 {
        margin-right: 7rem !important;
        margin-left: 7rem !important
    }

    .mx-xl-12 {
        margin-right: 8rem !important;
        margin-left: 8rem !important
    }

    .mx-xl-gs {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xl-10p {
        margin-right: 10% !important;
        margin-left: 10% !important
    }

    .mx-xl-20p {
        margin-right: 20% !important;
        margin-left: 20% !important
    }

    .mx-xl-25p {
        margin-right: 25% !important;
        margin-left: 25% !important
    }

    .mx-xl-30p {
        margin-right: 30% !important;
        margin-left: 30% !important
    }

    .mx-xl-35p {
        margin-right: 35% !important;
        margin-left: 35% !important
    }

    .mx-xl-40p {
        margin-right: 40% !important;
        margin-left: 40% !important
    }

    .mx-xl-45p {
        margin-right: 45% !important;
        margin-left: 45% !important
    }

    .mx-xl-50p {
        margin-right: 50% !important;
        margin-left: 50% !important
    }

    .mx-xl-60p {
        margin-right: 60% !important;
        margin-left: 60% !important
    }

    .mx-xl-70p {
        margin-right: 70% !important;
        margin-left: 70% !important
    }

    .mx-xl-75p {
        margin-right: 75% !important;
        margin-left: 75% !important
    }

    .mx-xl-80p {
        margin-right: 80% !important;
        margin-left: 80% !important
    }

    .mx-xl-90p {
        margin-right: 90% !important;
        margin-left: 90% !important
    }

    .mx-xl-100p {
        margin-right: 100% !important;
        margin-left: 100% !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-0-5 {
        margin-top: .125rem !important;
        margin-bottom: .125rem !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xl-6 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-xl-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-xl-8 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-xl-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important
    }

    .my-xl-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important
    }

    .my-xl-11 {
        margin-top: 7rem !important;
        margin-bottom: 7rem !important
    }

    .my-xl-12 {
        margin-top: 8rem !important;
        margin-bottom: 8rem !important
    }

    .my-xl-gs {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xl-10p {
        margin-top: 10% !important;
        margin-bottom: 10% !important
    }

    .my-xl-20p {
        margin-top: 20% !important;
        margin-bottom: 20% !important
    }

    .my-xl-25p {
        margin-top: 25% !important;
        margin-bottom: 25% !important
    }

    .my-xl-30p {
        margin-top: 30% !important;
        margin-bottom: 30% !important
    }

    .my-xl-35p {
        margin-top: 35% !important;
        margin-bottom: 35% !important
    }

    .my-xl-40p {
        margin-top: 40% !important;
        margin-bottom: 40% !important
    }

    .my-xl-45p {
        margin-top: 45% !important;
        margin-bottom: 45% !important
    }

    .my-xl-50p {
        margin-top: 50% !important;
        margin-bottom: 50% !important
    }

    .my-xl-60p {
        margin-top: 60% !important;
        margin-bottom: 60% !important
    }

    .my-xl-70p {
        margin-top: 70% !important;
        margin-bottom: 70% !important
    }

    .my-xl-75p {
        margin-top: 75% !important;
        margin-bottom: 75% !important
    }

    .my-xl-80p {
        margin-top: 80% !important;
        margin-bottom: 80% !important
    }

    .my-xl-90p {
        margin-top: 90% !important;
        margin-bottom: 90% !important
    }

    .my-xl-100p {
        margin-top: 100% !important;
        margin-bottom: 100% !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-0-5 {
        margin-top: .125rem !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 2rem !important;
    }

    .mt-xl-6 {
        margin-top: 3.5rem !important
    }

    .mt-xl-7 {
        margin-top: 4rem !important
    }

    .mt-xl-8 {
        margin-top: 4.5rem !important
    }

    .mt-xl-9 {
        margin-top: 5rem !important
    }

    .mt-xl-10 {
        margin-top: 6rem !important
    }

    .mt-xl-11 {
        margin-top: 7rem !important
    }

    .mt-xl-12 {
        margin-top: 8rem !important
    }

    .mt-xl-gs {
        margin-top: 2rem !important
    }

    .mt-xl-10p {
        margin-top: 10% !important
    }

    .mt-xl-20p {
        margin-top: 20% !important
    }

    .mt-xl-25p {
        margin-top: 25% !important
    }

    .mt-xl-30p {
        margin-top: 30% !important
    }

    .mt-xl-35p {
        margin-top: 35% !important
    }

    .mt-xl-40p {
        margin-top: 40% !important
    }

    .mt-xl-45p {
        margin-top: 45% !important
    }

    .mt-xl-50p {
        margin-top: 50% !important
    }

    .mt-xl-60p {
        margin-top: 60% !important
    }

    .mt-xl-70p {
        margin-top: 70% !important
    }

    .mt-xl-75p {
        margin-top: 75% !important
    }

    .mt-xl-80p {
        margin-top: 80% !important
    }

    .mt-xl-90p {
        margin-top: 90% !important
    }

    .mt-xl-100p {
        margin-top: 100% !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-0-5 {
        margin-right: .125rem !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 2rem !important
    }

    .me-xl-6 {
        margin-right: 3.5rem !important
    }

    .me-xl-7 {
        margin-right: 4rem !important
    }

    .me-xl-8 {
        margin-right: 4.5rem !important
    }

    .me-xl-9 {
        margin-right: 5rem !important
    }

    .me-xl-10 {
        margin-right: 6rem !important
    }

    .me-xl-11 {
        margin-right: 7rem !important
    }

    .me-xl-12 {
        margin-right: 8rem !important
    }

    .me-xl-gs {
        margin-right: 2rem !important
    }

    .me-xl-10p {
        margin-right: 10% !important
    }

    .me-xl-20p {
        margin-right: 20% !important
    }

    .me-xl-25p {
        margin-right: 25% !important
    }

    .me-xl-30p {
        margin-right: 30% !important
    }

    .me-xl-35p {
        margin-right: 35% !important
    }

    .me-xl-40p {
        margin-right: 40% !important
    }

    .me-xl-45p {
        margin-right: 45% !important
    }

    .me-xl-50p {
        margin-right: 50% !important
    }

    .me-xl-60p {
        margin-right: 60% !important
    }

    .me-xl-70p {
        margin-right: 70% !important
    }

    .me-xl-75p {
        margin-right: 75% !important
    }

    .me-xl-80p {
        margin-right: 80% !important
    }

    .me-xl-90p {
        margin-right: 90% !important
    }

    .me-xl-100p {
        margin-right: 100% !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-0-5 {
        margin-bottom: .125rem !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xl-6 {
        margin-bottom: 3.5rem !important
    }

    .mb-xl-7 {
        margin-bottom: 4rem !important
    }

    .mb-xl-8 {
        margin-bottom: 4.5rem !important
    }

    .mb-xl-9 {
        margin-bottom: 5rem !important
    }

    .mb-xl-10 {
        margin-bottom: 6rem !important
    }

    .mb-xl-11 {
        margin-bottom: 7rem !important
    }

    .mb-xl-12 {
        margin-bottom: 8rem !important
    }

    .mb-xl-gs {
        margin-bottom: 2rem !important
    }

    .mb-xl-10p {
        margin-bottom: 10% !important
    }

    .mb-xl-20p {
        margin-bottom: 20% !important
    }

    .mb-xl-25p {
        margin-bottom: 25% !important
    }

    .mb-xl-30p {
        margin-bottom: 30% !important
    }

    .mb-xl-35p {
        margin-bottom: 35% !important
    }

    .mb-xl-40p {
        margin-bottom: 40% !important
    }

    .mb-xl-45p {
        margin-bottom: 45% !important
    }

    .mb-xl-50p {
        margin-bottom: 50% !important
    }

    .mb-xl-60p {
        margin-bottom: 60% !important
    }

    .mb-xl-70p {
        margin-bottom: 70% !important
    }

    .mb-xl-75p {
        margin-bottom: 75% !important
    }

    .mb-xl-80p {
        margin-bottom: 80% !important
    }

    .mb-xl-90p {
        margin-bottom: 90% !important
    }

    .mb-xl-100p {
        margin-bottom: 100% !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-0-5 {
        margin-left: .125rem !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 2rem !important
    }

    .ms-xl-6 {
        margin-left: 3.5rem !important
    }

    .ms-xl-7 {
        margin-left: 4rem !important
    }

    .ms-xl-8 {
        margin-left: 4.5rem !important
    }

    .ms-xl-9 {
        margin-left: 5rem !important
    }

    .ms-xl-10 {
        margin-left: 6rem !important
    }

    .ms-xl-11 {
        margin-left: 7rem !important
    }

    .ms-xl-12 {
        margin-left: 8rem !important
    }

    .ms-xl-gs {
        margin-left: 2rem !important
    }

    .ms-xl-10p {
        margin-left: 10% !important
    }

    .ms-xl-20p {
        margin-left: 20% !important
    }

    .ms-xl-25p {
        margin-left: 25% !important
    }

    .ms-xl-30p {
        margin-left: 30% !important
    }

    .ms-xl-35p {
        margin-left: 35% !important
    }

    .ms-xl-40p {
        margin-left: 40% !important
    }

    .ms-xl-45p {
        margin-left: 45% !important
    }

    .ms-xl-50p {
        margin-left: 50% !important
    }

    .ms-xl-60p {
        margin-left: 60% !important
    }

    .ms-xl-70p {
        margin-left: 70% !important
    }

    .ms-xl-75p {
        margin-left: 75% !important
    }

    .ms-xl-80p {
        margin-left: 80% !important
    }

    .ms-xl-90p {
        margin-left: 90% !important
    }

    .ms-xl-100p {
        margin-left: 100% !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n0-5 {
        margin: -0.125rem !important
    }

    .m-xl-n1 {
        margin: -0.25rem !important
    }

    .m-xl-n2 {
        margin: -0.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -2rem !important
    }

    .m-xl-n6 {
        margin: -3.5rem !important
    }

    .m-xl-n7 {
        margin: -4rem !important
    }

    .m-xl-n8 {
        margin: -4.5rem !important
    }

    .m-xl-n9 {
        margin: -5rem !important
    }

    .m-xl-n10 {
        margin: -6rem !important
    }

    .m-xl-n11 {
        margin: -7rem !important
    }

    .m-xl-n12 {
        margin: -8rem !important
    }

    .m-xl-ngs {
        margin: -2rem !important
    }

    .m-xl-n10p {
        margin: -10% !important
    }

    .m-xl-n20p {
        margin: -20% !important
    }

    .m-xl-n25p {
        margin: -25% !important
    }

    .m-xl-n30p {
        margin: -30% !important
    }

    .m-xl-n35p {
        margin: -35% !important
    }

    .m-xl-n40p {
        margin: -40% !important
    }

    .m-xl-n45p {
        margin: -45% !important
    }

    .m-xl-n50p {
        margin: -50% !important
    }

    .m-xl-n60p {
        margin: -60% !important
    }

    .m-xl-n70p {
        margin: -70% !important
    }

    .m-xl-n75p {
        margin: -75% !important
    }

    .m-xl-n80p {
        margin: -80% !important
    }

    .m-xl-n90p {
        margin: -90% !important
    }

    .m-xl-n100p {
        margin: -100% !important
    }

    .mx-xl-n0-5 {
        margin-right: -0.125rem !important;
        margin-left: -0.125rem !important
    }

    .mx-xl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-xl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xl-n6 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-xl-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-xl-n8 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .mx-xl-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important
    }

    .mx-xl-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important
    }

    .mx-xl-n11 {
        margin-right: -7rem !important;
        margin-left: -7rem !important
    }

    .mx-xl-n12 {
        margin-right: -8rem !important;
        margin-left: -8rem !important
    }

    .mx-xl-ngs {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xl-n10p {
        margin-right: -10% !important;
        margin-left: -10% !important
    }

    .mx-xl-n20p {
        margin-right: -20% !important;
        margin-left: -20% !important
    }

    .mx-xl-n25p {
        margin-right: -25% !important;
        margin-left: -25% !important
    }

    .mx-xl-n30p {
        margin-right: -30% !important;
        margin-left: -30% !important
    }

    .mx-xl-n35p {
        margin-right: -35% !important;
        margin-left: -35% !important
    }

    .mx-xl-n40p {
        margin-right: -40% !important;
        margin-left: -40% !important
    }

    .mx-xl-n45p {
        margin-right: -45% !important;
        margin-left: -45% !important
    }

    .mx-xl-n50p {
        margin-right: -50% !important;
        margin-left: -50% !important
    }

    .mx-xl-n60p {
        margin-right: -60% !important;
        margin-left: -60% !important
    }

    .mx-xl-n70p {
        margin-right: -70% !important;
        margin-left: -70% !important
    }

    .mx-xl-n75p {
        margin-right: -75% !important;
        margin-left: -75% !important
    }

    .mx-xl-n80p {
        margin-right: -80% !important;
        margin-left: -80% !important
    }

    .mx-xl-n90p {
        margin-right: -90% !important;
        margin-left: -90% !important
    }

    .mx-xl-n100p {
        margin-right: -100% !important;
        margin-left: -100% !important
    }

    .my-xl-n0-5 {
        margin-top: -0.125rem !important;
        margin-bottom: -0.125rem !important
    }

    .my-xl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-xl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xl-n6 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-xl-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-xl-n8 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .my-xl-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important
    }

    .my-xl-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important
    }

    .my-xl-n11 {
        margin-top: -7rem !important;
        margin-bottom: -7rem !important
    }

    .my-xl-n12 {
        margin-top: -8rem !important;
        margin-bottom: -8rem !important
    }

    .my-xl-ngs {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xl-n10p {
        margin-top: -10% !important;
        margin-bottom: -10% !important
    }

    .my-xl-n20p {
        margin-top: -20% !important;
        margin-bottom: -20% !important
    }

    .my-xl-n25p {
        margin-top: -25% !important;
        margin-bottom: -25% !important
    }

    .my-xl-n30p {
        margin-top: -30% !important;
        margin-bottom: -30% !important
    }

    .my-xl-n35p {
        margin-top: -35% !important;
        margin-bottom: -35% !important
    }

    .my-xl-n40p {
        margin-top: -40% !important;
        margin-bottom: -40% !important
    }

    .my-xl-n45p {
        margin-top: -45% !important;
        margin-bottom: -45% !important
    }

    .my-xl-n50p {
        margin-top: -50% !important;
        margin-bottom: -50% !important
    }

    .my-xl-n60p {
        margin-top: -60% !important;
        margin-bottom: -60% !important
    }

    .my-xl-n70p {
        margin-top: -70% !important;
        margin-bottom: -70% !important
    }

    .my-xl-n75p {
        margin-top: -75% !important;
        margin-bottom: -75% !important
    }

    .my-xl-n80p {
        margin-top: -80% !important;
        margin-bottom: -80% !important
    }

    .my-xl-n90p {
        margin-top: -90% !important;
        margin-bottom: -90% !important
    }

    .my-xl-n100p {
        margin-top: -100% !important;
        margin-bottom: -100% !important
    }

    .mt-xl-n0-5 {
        margin-top: -0.125rem !important
    }

    .mt-xl-n1 {
        margin-top: -0.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -0.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -2rem !important
    }

    .mt-xl-n6 {
        margin-top: -3.5rem !important
    }

    .mt-xl-n7 {
        margin-top: -4rem !important
    }

    .mt-xl-n8 {
        margin-top: -4.5rem !important
    }

    .mt-xl-n9 {
        margin-top: -5rem !important
    }

    .mt-xl-n10 {
        margin-top: -6rem !important
    }

    .mt-xl-n11 {
        margin-top: -7rem !important
    }

    .mt-xl-n12 {
        margin-top: -8rem !important
    }

    .mt-xl-ngs {
        margin-top: -2rem !important
    }

    .mt-xl-n10p {
        margin-top: -10% !important
    }

    .mt-xl-n20p {
        margin-top: -20% !important
    }

    .mt-xl-n25p {
        margin-top: -25% !important
    }

    .mt-xl-n30p {
        margin-top: -30% !important
    }

    .mt-xl-n35p {
        margin-top: -35% !important
    }

    .mt-xl-n40p {
        margin-top: -40% !important
    }

    .mt-xl-n45p {
        margin-top: -45% !important
    }

    .mt-xl-n50p {
        margin-top: -50% !important
    }

    .mt-xl-n60p {
        margin-top: -60% !important
    }

    .mt-xl-n70p {
        margin-top: -70% !important
    }

    .mt-xl-n75p {
        margin-top: -75% !important
    }

    .mt-xl-n80p {
        margin-top: -80% !important
    }

    .mt-xl-n90p {
        margin-top: -90% !important
    }

    .mt-xl-n100p {
        margin-top: -100% !important
    }

    .me-xl-n0-5 {
        margin-right: -0.125rem !important
    }

    .me-xl-n1 {
        margin-right: -0.25rem !important
    }

    .me-xl-n2 {
        margin-right: -0.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -2rem !important
    }

    .me-xl-n6 {
        margin-right: -3.5rem !important
    }

    .me-xl-n7 {
        margin-right: -4rem !important
    }

    .me-xl-n8 {
        margin-right: -4.5rem !important
    }

    .me-xl-n9 {
        margin-right: -5rem !important
    }

    .me-xl-n10 {
        margin-right: -6rem !important
    }

    .me-xl-n11 {
        margin-right: -7rem !important
    }

    .me-xl-n12 {
        margin-right: -8rem !important
    }

    .me-xl-ngs {
        margin-right: -2rem !important
    }

    .me-xl-n10p {
        margin-right: -10% !important
    }

    .me-xl-n20p {
        margin-right: -20% !important
    }

    .me-xl-n25p {
        margin-right: -25% !important
    }

    .me-xl-n30p {
        margin-right: -30% !important
    }

    .me-xl-n35p {
        margin-right: -35% !important
    }

    .me-xl-n40p {
        margin-right: -40% !important
    }

    .me-xl-n45p {
        margin-right: -45% !important
    }

    .me-xl-n50p {
        margin-right: -50% !important
    }

    .me-xl-n60p {
        margin-right: -60% !important
    }

    .me-xl-n70p {
        margin-right: -70% !important
    }

    .me-xl-n75p {
        margin-right: -75% !important
    }

    .me-xl-n80p {
        margin-right: -80% !important
    }

    .me-xl-n90p {
        margin-right: -90% !important
    }

    .me-xl-n100p {
        margin-right: -100% !important
    }

    .mb-xl-n0-5 {
        margin-bottom: -0.125rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xl-n6 {
        margin-bottom: -3.5rem !important
    }

    .mb-xl-n7 {
        margin-bottom: -4rem !important
    }

    .mb-xl-n8 {
        margin-bottom: -4.5rem !important
    }

    .mb-xl-n9 {
        margin-bottom: -5rem !important
    }

    .mb-xl-n10 {
        margin-bottom: -6rem !important
    }

    .mb-xl-n11 {
        margin-bottom: -7rem !important
    }

    .mb-xl-n12 {
        margin-bottom: -8rem !important
    }

    .mb-xl-ngs {
        margin-bottom: -2rem !important
    }

    .mb-xl-n10p {
        margin-bottom: -10% !important
    }

    .mb-xl-n20p {
        margin-bottom: -20% !important
    }

    .mb-xl-n25p {
        margin-bottom: -25% !important
    }

    .mb-xl-n30p {
        margin-bottom: -30% !important
    }

    .mb-xl-n35p {
        margin-bottom: -35% !important
    }

    .mb-xl-n40p {
        margin-bottom: -40% !important
    }

    .mb-xl-n45p {
        margin-bottom: -45% !important
    }

    .mb-xl-n50p {
        margin-bottom: -50% !important
    }

    .mb-xl-n60p {
        margin-bottom: -60% !important
    }

    .mb-xl-n70p {
        margin-bottom: -70% !important
    }

    .mb-xl-n75p {
        margin-bottom: -75% !important
    }

    .mb-xl-n80p {
        margin-bottom: -80% !important
    }

    .mb-xl-n90p {
        margin-bottom: -90% !important
    }

    .mb-xl-n100p {
        margin-bottom: -100% !important
    }

    .ms-xl-n0-5 {
        margin-left: -0.125rem !important
    }

    .ms-xl-n1 {
        margin-left: -0.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -0.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -2rem !important
    }

    .ms-xl-n6 {
        margin-left: -3.5rem !important
    }

    .ms-xl-n7 {
        margin-left: -4rem !important
    }

    .ms-xl-n8 {
        margin-left: -4.5rem !important
    }

    .ms-xl-n9 {
        margin-left: -5rem !important
    }

    .ms-xl-n10 {
        margin-left: -6rem !important
    }

    .ms-xl-n11 {
        margin-left: -7rem !important
    }

    .ms-xl-n12 {
        margin-left: -8rem !important
    }

    .ms-xl-ngs {
        margin-left: -2rem !important
    }

    .ms-xl-n10p {
        margin-left: -10% !important
    }

    .ms-xl-n20p {
        margin-left: -20% !important
    }

    .ms-xl-n25p {
        margin-left: -25% !important
    }

    .ms-xl-n30p {
        margin-left: -30% !important
    }

    .ms-xl-n35p {
        margin-left: -35% !important
    }

    .ms-xl-n40p {
        margin-left: -40% !important
    }

    .ms-xl-n45p {
        margin-left: -45% !important
    }

    .ms-xl-n50p {
        margin-left: -50% !important
    }

    .ms-xl-n60p {
        margin-left: -60% !important
    }

    .ms-xl-n70p {
        margin-left: -70% !important
    }

    .ms-xl-n75p {
        margin-left: -75% !important
    }

    .ms-xl-n80p {
        margin-left: -80% !important
    }

    .ms-xl-n90p {
        margin-left: -90% !important
    }

    .ms-xl-n100p {
        margin-left: -100% !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-0-5 {
        padding: .125rem !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 2rem !important
    }

    .p-xl-6 {
        padding: 3.5rem !important
    }

    .p-xl-7 {
        padding: 4rem !important
    }

    .p-xl-8 {
        padding: 4.5rem !important
    }

    .p-xl-9 {
        padding: 5rem !important
    }

    .p-xl-10 {
        padding: 6rem !important
    }

    .p-xl-11 {
        padding: 7rem !important
    }

    .p-xl-12 {
        padding: 8rem !important
    }

    .p-xl-gs {
        padding: 2rem !important
    }

    .p-xl-10p {
        padding: 10% !important
    }

    .p-xl-20p {
        padding: 20% !important
    }

    .p-xl-25p {
        padding: 25% !important
    }

    .p-xl-30p {
        padding: 30% !important
    }

    .p-xl-35p {
        padding: 35% !important
    }

    .p-xl-40p {
        padding: 40% !important
    }

    .p-xl-45p {
        padding: 45% !important
    }

    .p-xl-50p {
        padding: 50% !important
    }

    .p-xl-60p {
        padding: 60% !important
    }

    .p-xl-70p {
        padding: 70% !important
    }

    .p-xl-75p {
        padding: 75% !important
    }

    .p-xl-80p {
        padding: 80% !important
    }

    .p-xl-90p {
        padding: 90% !important
    }

    .p-xl-100p {
        padding: 100% !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-0-5 {
        padding-right: .125rem !important;
        padding-left: .125rem !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xl-6 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-xl-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-xl-8 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .px-xl-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important
    }

    .px-xl-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important
    }

    .px-xl-11 {
        padding-right: 7rem !important;
        padding-left: 7rem !important
    }

    .px-xl-12 {
        padding-right: 8rem !important;
        padding-left: 8rem !important
    }

    .px-xl-gs {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xl-10p {
        padding-right: 10% !important;
        padding-left: 10% !important
    }

    .px-xl-20p {
        padding-right: 20% !important;
        padding-left: 20% !important
    }

    .px-xl-25p {
        padding-right: 25% !important;
        padding-left: 25% !important
    }

    .px-xl-30p {
        padding-right: 30% !important;
        padding-left: 30% !important
    }

    .px-xl-35p {
        padding-right: 35% !important;
        padding-left: 35% !important
    }

    .px-xl-40p {
        padding-right: 40% !important;
        padding-left: 40% !important
    }

    .px-xl-45p {
        padding-right: 45% !important;
        padding-left: 45% !important
    }

    .px-xl-50p {
        padding-right: 50% !important;
        padding-left: 50% !important
    }

    .px-xl-60p {
        padding-right: 60% !important;
        padding-left: 60% !important
    }

    .px-xl-70p {
        padding-right: 70% !important;
        padding-left: 70% !important
    }

    .px-xl-75p {
        padding-right: 75% !important;
        padding-left: 75% !important
    }

    .px-xl-80p {
        padding-right: 80% !important;
        padding-left: 80% !important
    }

    .px-xl-90p {
        padding-right: 90% !important;
        padding-left: 90% !important
    }

    .px-xl-100p {
        padding-right: 100% !important;
        padding-left: 100% !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-0-5 {
        padding-top: .125rem !important;
        padding-bottom: .125rem !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xl-6 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-xl-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-xl-8 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .py-xl-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .py-xl-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important
    }

    .py-xl-11 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important
    }

    .py-xl-12 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important
    }

    .py-xl-gs {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xl-10p {
        padding-top: 10% !important;
        padding-bottom: 10% !important
    }

    .py-xl-20p {
        padding-top: 20% !important;
        padding-bottom: 20% !important
    }

    .py-xl-25p {
        padding-top: 25% !important;
        padding-bottom: 25% !important
    }

    .py-xl-30p {
        padding-top: 30% !important;
        padding-bottom: 30% !important
    }

    .py-xl-35p {
        padding-top: 35% !important;
        padding-bottom: 35% !important
    }

    .py-xl-40p {
        padding-top: 40% !important;
        padding-bottom: 40% !important
    }

    .py-xl-45p {
        padding-top: 45% !important;
        padding-bottom: 45% !important
    }

    .py-xl-50p {
        padding-top: 50% !important;
        padding-bottom: 50% !important
    }

    .py-xl-60p {
        padding-top: 60% !important;
        padding-bottom: 60% !important
    }

    .py-xl-70p {
        padding-top: 70% !important;
        padding-bottom: 70% !important
    }

    .py-xl-75p {
        padding-top: 75% !important;
        padding-bottom: 75% !important
    }

    .py-xl-80p {
        padding-top: 80% !important;
        padding-bottom: 80% !important
    }

    .py-xl-90p {
        padding-top: 90% !important;
        padding-bottom: 90% !important
    }

    .py-xl-100p {
        padding-top: 100% !important;
        padding-bottom: 100% !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-0-5 {
        padding-top: .125rem !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 2rem !important
    }

    .pt-xl-6 {
        padding-top: 3.5rem !important
    }

    .pt-xl-7 {
        padding-top: 4rem !important
    }

    .pt-xl-8 {
        padding-top: 4.5rem !important
    }

    .pt-xl-9 {
        padding-top: 5rem !important
    }

    .pt-xl-10 {
        padding-top: 6rem !important
    }

    .pt-xl-11 {
        padding-top: 7rem !important
    }

    .pt-xl-12 {
        padding-top: 8rem !important
    }

    .pt-xl-gs {
        padding-top: 2rem !important
    }

    .pt-xl-10p {
        padding-top: 10% !important
    }

    .pt-xl-20p {
        padding-top: 20% !important
    }

    .pt-xl-25p {
        padding-top: 25% !important
    }

    .pt-xl-30p {
        padding-top: 30% !important
    }

    .pt-xl-35p {
        padding-top: 35% !important
    }

    .pt-xl-40p {
        padding-top: 40% !important
    }

    .pt-xl-45p {
        padding-top: 45% !important
    }

    .pt-xl-50p {
        padding-top: 50% !important
    }

    .pt-xl-60p {
        padding-top: 60% !important
    }

    .pt-xl-70p {
        padding-top: 70% !important
    }

    .pt-xl-75p {
        padding-top: 75% !important
    }

    .pt-xl-80p {
        padding-top: 80% !important
    }

    .pt-xl-90p {
        padding-top: 90% !important
    }

    .pt-xl-100p {
        padding-top: 100% !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-0-5 {
        padding-right: .125rem !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 2rem !important
    }

    .pe-xl-6 {
        padding-right: 3.5rem !important
    }

    .pe-xl-7 {
        padding-right: 4rem !important
    }

    .pe-xl-8 {
        padding-right: 4.5rem !important
    }

    .pe-xl-9 {
        padding-right: 5rem !important
    }

    .pe-xl-10 {
        padding-right: 6rem !important
    }

    .pe-xl-11 {
        padding-right: 7rem !important
    }

    .pe-xl-12 {
        padding-right: 8rem !important
    }

    .pe-xl-gs {
        padding-right: 2rem !important
    }

    .pe-xl-10p {
        padding-right: 10% !important
    }

    .pe-xl-20p {
        padding-right: 20% !important
    }

    .pe-xl-25p {
        padding-right: 25% !important
    }

    .pe-xl-30p {
        padding-right: 30% !important
    }

    .pe-xl-35p {
        padding-right: 35% !important
    }

    .pe-xl-40p {
        padding-right: 40% !important
    }

    .pe-xl-45p {
        padding-right: 45% !important
    }

    .pe-xl-50p {
        padding-right: 50% !important
    }

    .pe-xl-60p {
        padding-right: 60% !important
    }

    .pe-xl-70p {
        padding-right: 70% !important
    }

    .pe-xl-75p {
        padding-right: 75% !important
    }

    .pe-xl-80p {
        padding-right: 80% !important
    }

    .pe-xl-90p {
        padding-right: 90% !important
    }

    .pe-xl-100p {
        padding-right: 100% !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-0-5 {
        padding-bottom: .125rem !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xl-6 {
        padding-bottom: 3.5rem !important
    }

    .pb-xl-7 {
        padding-bottom: 4rem !important
    }

    .pb-xl-8 {
        padding-bottom: 4.5rem !important
    }

    .pb-xl-9 {
        padding-bottom: 5rem !important
    }

    .pb-xl-10 {
        padding-bottom: 6rem !important
    }

    .pb-xl-11 {
        padding-bottom: 7rem !important
    }

    .pb-xl-12 {
        padding-bottom: 8rem !important
    }

    .pb-xl-gs {
        padding-bottom: 2rem !important
    }

    .pb-xl-10p {
        padding-bottom: 10% !important
    }

    .pb-xl-20p {
        padding-bottom: 20% !important
    }

    .pb-xl-25p {
        padding-bottom: 25% !important
    }

    .pb-xl-30p {
        padding-bottom: 30% !important
    }

    .pb-xl-35p {
        padding-bottom: 35% !important
    }

    .pb-xl-40p {
        padding-bottom: 40% !important
    }

    .pb-xl-45p {
        padding-bottom: 45% !important
    }

    .pb-xl-50p {
        padding-bottom: 50% !important
    }

    .pb-xl-60p {
        padding-bottom: 60% !important
    }

    .pb-xl-70p {
        padding-bottom: 70% !important
    }

    .pb-xl-75p {
        padding-bottom: 75% !important
    }

    .pb-xl-80p {
        padding-bottom: 80% !important
    }

    .pb-xl-90p {
        padding-bottom: 90% !important
    }

    .pb-xl-100p {
        padding-bottom: 100% !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-0-5 {
        padding-left: .125rem !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 2rem !important
    }

    .ps-xl-6 {
        padding-left: 3.5rem !important
    }

    .ps-xl-7 {
        padding-left: 4rem !important
    }

    .ps-xl-8 {
        padding-left: 4.5rem !important
    }

    .ps-xl-9 {
        padding-left: 5rem !important
    }

    .ps-xl-10 {
        padding-left: 6rem !important
    }

    .ps-xl-11 {
        padding-left: 7rem !important
    }

    .ps-xl-12 {
        padding-left: 8rem !important
    }

    .ps-xl-gs {
        padding-left: 2rem !important
    }

    .ps-xl-10p {
        padding-left: 10% !important
    }

    .ps-xl-20p {
        padding-left: 20% !important
    }

    .ps-xl-25p {
        padding-left: 25% !important
    }

    .ps-xl-30p {
        padding-left: 30% !important
    }

    .ps-xl-35p {
        padding-left: 35% !important
    }

    .ps-xl-40p {
        padding-left: 40% !important
    }

    .ps-xl-45p {
        padding-left: 45% !important
    }

    .ps-xl-50p {
        padding-left: 50% !important
    }

    .ps-xl-60p {
        padding-left: 60% !important
    }

    .ps-xl-70p {
        padding-left: 70% !important
    }

    .ps-xl-75p {
        padding-left: 75% !important
    }

    .ps-xl-80p {
        padding-left: 80% !important
    }

    .ps-xl-90p {
        padding-left: 90% !important
    }

    .ps-xl-100p {
        padding-left: 100% !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-0-5 {
        gap: .125rem !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 2rem !important
    }

    .gap-xl-6 {
        gap: 3.5rem !important
    }

    .gap-xl-7 {
        gap: 4rem !important
    }

    .gap-xl-8 {
        gap: 4.5rem !important
    }

    .gap-xl-9 {
        gap: 5rem !important
    }

    .gap-xl-10 {
        gap: 6rem !important
    }

    .gap-xl-11 {
        gap: 7rem !important
    }

    .gap-xl-12 {
        gap: 8rem !important
    }

    .gap-xl-gs {
        gap: 2rem !important
    }

    .gap-xl-10p {
        gap: 10% !important
    }

    .gap-xl-20p {
        gap: 20% !important
    }

    .gap-xl-25p {
        gap: 25% !important
    }

    .gap-xl-30p {
        gap: 30% !important
    }

    .gap-xl-35p {
        gap: 35% !important
    }

    .gap-xl-40p {
        gap: 40% !important
    }

    .gap-xl-45p {
        gap: 45% !important
    }

    .gap-xl-50p {
        gap: 50% !important
    }

    .gap-xl-60p {
        gap: 60% !important
    }

    .gap-xl-70p {
        gap: 70% !important
    }

    .gap-xl-75p {
        gap: 75% !important
    }

    .gap-xl-80p {
        gap: 80% !important
    }

    .gap-xl-90p {
        gap: 90% !important
    }

    .gap-xl-100p {
        gap: 100% !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width: 1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .position-xxl-static {
        position: static !important
    }

    .position-xxl-relative {
        position: relative !important
    }

    .position-xxl-absolute {
        position: absolute !important
    }

    .position-xxl-fixed {
        position: fixed !important
    }

    .position-xxl-sticky {
        position: sticky !important
    }

    .top-xxl-auto {
        top: auto !important
    }

    .top-xxl-0 {
        top: 0 !important
    }

    .top-xxl-50 {
        top: 50% !important
    }

    .top-xxl-100 {
        top: 100% !important
    }

    .bottom-xxl-auto {
        bottom: auto !important
    }

    .bottom-xxl-0 {
        bottom: 0 !important
    }

    .bottom-xxl-50 {
        bottom: 50% !important
    }

    .bottom-xxl-100 {
        bottom: 100% !important
    }

    .start-xxl-auto {
        left: auto !important
    }

    .start-xxl-0 {
        left: 0 !important
    }

    .start-xxl-50 {
        left: 50% !important
    }

    .start-xxl-100 {
        left: 100% !important
    }

    .end-xxl-auto {
        right: auto !important
    }

    .end-xxl-0 {
        right: 0 !important
    }

    .end-xxl-50 {
        right: 50% !important
    }

    .end-xxl-100 {
        right: 100% !important
    }

    .translate-middle-xxl {
        transform: translate(-50%, -50%) !important
    }

    .translate-middle-xxl-x {
        transform: translateX(-50%) !important
    }

    .translate-middle-xxl-y {
        transform: translateY(-50%) !important
    }

    .w-xxl-25 {
        width: 25% !important
    }

    .w-xxl-50 {
        width: 50% !important
    }

    .w-xxl-75 {
        width: 75% !important
    }

    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .w-xxl-1rem {
        width: 1rem !important
    }

    .w-xxl-1-5rem {
        width: 1.5rem !important
    }

    .w-xxl-2rem {
        width: 2rem !important
    }

    .w-xxl-3rem {
        width: 3rem !important
    }

    .w-xxl-4rem {
        width: 4rem !important
    }

    .w-xxl-5rem {
        width: 5rem !important
    }

    .w-xxl-6rem {
        width: 6rem !important
    }

    .w-xxl-7rem {
        width: 7rem !important
    }

    .w-xxl-8rem {
        width: 8rem !important
    }

    .w-xxl-10rem {
        width: 10rem !important
    }

    .w-xxl-12rem {
        width: 12rem !important
    }

    .w-xxl-16rem {
        width: 16rem !important
    }

    .h-xxl-25 {
        height: 25% !important
    }

    .h-xxl-50 {
        height: 50% !important
    }

    .h-xxl-75 {
        height: 75% !important
    }

    .h-xxl-100 {
        height: 100% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .h-xxl-1rem {
        height: 1rem !important
    }

    .h-xxl-1-5rem {
        height: 1.5rem !important
    }

    .h-xxl-2rem {
        height: 2rem !important
    }

    .h-xxl-3rem {
        height: 3rem !important
    }

    .h-xxl-4rem {
        height: 4rem !important
    }

    .h-xxl-5rem {
        height: 5rem !important
    }

    .h-xxl-6rem {
        height: 6rem !important
    }

    .h-xxl-7rem {
        height: 7rem !important
    }

    .h-xxl-8rem {
        height: 8rem !important
    }

    .h-xxl-10rem {
        height: 10rem !important
    }

    .h-xxl-12rem {
        height: 12rem !important
    }

    .h-xxl-16rem {
        height: 16rem !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shritw-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shritw-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-0-5 {
        margin: .125rem !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 2rem !important
    }

    .m-xxl-6 {
        margin: 3.5rem !important
    }

    .m-xxl-7 {
        margin: 4rem !important
    }

    .m-xxl-8 {
        margin: 4.5rem !important
    }

    .m-xxl-9 {
        margin: 5rem !important
    }

    .m-xxl-10 {
        margin: 6rem !important
    }

    .m-xxl-11 {
        margin: 7rem !important
    }

    .m-xxl-12 {
        margin: 8rem !important
    }

    .m-xxl-gs {
        margin: 2rem !important
    }

    .m-xxl-10p {
        margin: 10% !important
    }

    .m-xxl-20p {
        margin: 20% !important
    }

    .m-xxl-25p {
        margin: 25% !important
    }

    .m-xxl-30p {
        margin: 30% !important
    }

    .m-xxl-35p {
        margin: 35% !important
    }

    .m-xxl-40p {
        margin: 40% !important
    }

    .m-xxl-45p {
        margin: 45% !important
    }

    .m-xxl-50p {
        margin: 50% !important
    }

    .m-xxl-60p {
        margin: 60% !important
    }

    .m-xxl-70p {
        margin: 70% !important
    }

    .m-xxl-75p {
        margin: 75% !important
    }

    .m-xxl-80p {
        margin: 80% !important
    }

    .m-xxl-90p {
        margin: 90% !important
    }

    .m-xxl-100p {
        margin: 100% !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-0-5 {
        margin-right: .125rem !important;
        margin-left: .125rem !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xxl-6 {
        margin-right: 3.5rem !important;
        margin-left: 3.5rem !important
    }

    .mx-xxl-7 {
        margin-right: 4rem !important;
        margin-left: 4rem !important
    }

    .mx-xxl-8 {
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important
    }

    .mx-xxl-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important
    }

    .mx-xxl-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important
    }

    .mx-xxl-11 {
        margin-right: 7rem !important;
        margin-left: 7rem !important
    }

    .mx-xxl-12 {
        margin-right: 8rem !important;
        margin-left: 8rem !important
    }

    .mx-xxl-gs {
        margin-right: 2rem !important;
        margin-left: 2rem !important
    }

    .mx-xxl-10p {
        margin-right: 10% !important;
        margin-left: 10% !important
    }

    .mx-xxl-20p {
        margin-right: 20% !important;
        margin-left: 20% !important
    }

    .mx-xxl-25p {
        margin-right: 25% !important;
        margin-left: 25% !important
    }

    .mx-xxl-30p {
        margin-right: 30% !important;
        margin-left: 30% !important
    }

    .mx-xxl-35p {
        margin-right: 35% !important;
        margin-left: 35% !important
    }

    .mx-xxl-40p {
        margin-right: 40% !important;
        margin-left: 40% !important
    }

    .mx-xxl-45p {
        margin-right: 45% !important;
        margin-left: 45% !important
    }

    .mx-xxl-50p {
        margin-right: 50% !important;
        margin-left: 50% !important
    }

    .mx-xxl-60p {
        margin-right: 60% !important;
        margin-left: 60% !important
    }

    .mx-xxl-70p {
        margin-right: 70% !important;
        margin-left: 70% !important
    }

    .mx-xxl-75p {
        margin-right: 75% !important;
        margin-left: 75% !important
    }

    .mx-xxl-80p {
        margin-right: 80% !important;
        margin-left: 80% !important
    }

    .mx-xxl-90p {
        margin-right: 90% !important;
        margin-left: 90% !important
    }

    .mx-xxl-100p {
        margin-right: 100% !important;
        margin-left: 100% !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-0-5 {
        margin-top: .125rem !important;
        margin-bottom: .125rem !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xxl-6 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important
    }

    .my-xxl-7 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important
    }

    .my-xxl-8 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important
    }

    .my-xxl-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important
    }

    .my-xxl-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important
    }

    .my-xxl-11 {
        margin-top: 7rem !important;
        margin-bottom: 7rem !important
    }

    .my-xxl-12 {
        margin-top: 8rem !important;
        margin-bottom: 8rem !important
    }

    .my-xxl-gs {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important
    }

    .my-xxl-10p {
        margin-top: 10% !important;
        margin-bottom: 10% !important
    }

    .my-xxl-20p {
        margin-top: 20% !important;
        margin-bottom: 20% !important
    }

    .my-xxl-25p {
        margin-top: 25% !important;
        margin-bottom: 25% !important
    }

    .my-xxl-30p {
        margin-top: 30% !important;
        margin-bottom: 30% !important
    }

    .my-xxl-35p {
        margin-top: 35% !important;
        margin-bottom: 35% !important
    }

    .my-xxl-40p {
        margin-top: 40% !important;
        margin-bottom: 40% !important
    }

    .my-xxl-45p {
        margin-top: 45% !important;
        margin-bottom: 45% !important
    }

    .my-xxl-50p {
        margin-top: 50% !important;
        margin-bottom: 50% !important
    }

    .my-xxl-60p {
        margin-top: 60% !important;
        margin-bottom: 60% !important
    }

    .my-xxl-70p {
        margin-top: 70% !important;
        margin-bottom: 70% !important
    }

    .my-xxl-75p {
        margin-top: 75% !important;
        margin-bottom: 75% !important
    }

    .my-xxl-80p {
        margin-top: 80% !important;
        margin-bottom: 80% !important
    }

    .my-xxl-90p {
        margin-top: 90% !important;
        margin-bottom: 90% !important
    }

    .my-xxl-100p {
        margin-top: 100% !important;
        margin-bottom: 100% !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-0-5 {
        margin-top: .125rem !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 2rem !important
    }

    .mt-xxl-6 {
        margin-top: 3.5rem !important
    }

    .mt-xxl-7 {
        margin-top: 4rem !important
    }

    .mt-xxl-8 {
        margin-top: 4.5rem !important
    }

    .mt-xxl-9 {
        margin-top: 5rem !important
    }

    .mt-xxl-10 {
        margin-top: 6rem !important
    }

    .mt-xxl-11 {
        margin-top: 7rem !important
    }

    .mt-xxl-12 {
        margin-top: 8rem !important
    }

    .mt-xxl-gs {
        margin-top: 2rem !important
    }

    .mt-xxl-10p {
        margin-top: 10% !important
    }

    .mt-xxl-20p {
        margin-top: 20% !important
    }

    .mt-xxl-25p {
        margin-top: 25% !important
    }

    .mt-xxl-30p {
        margin-top: 30% !important
    }

    .mt-xxl-35p {
        margin-top: 35% !important
    }

    .mt-xxl-40p {
        margin-top: 40% !important
    }

    .mt-xxl-45p {
        margin-top: 45% !important
    }

    .mt-xxl-50p {
        margin-top: 50% !important
    }

    .mt-xxl-60p {
        margin-top: 60% !important
    }

    .mt-xxl-70p {
        margin-top: 70% !important
    }

    .mt-xxl-75p {
        margin-top: 75% !important
    }

    .mt-xxl-80p {
        margin-top: 80% !important
    }

    .mt-xxl-90p {
        margin-top: 90% !important
    }

    .mt-xxl-100p {
        margin-top: 100% !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-0-5 {
        margin-right: .125rem !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 2rem !important
    }

    .me-xxl-6 {
        margin-right: 3.5rem !important
    }

    .me-xxl-7 {
        margin-right: 4rem !important
    }

    .me-xxl-8 {
        margin-right: 4.5rem !important
    }

    .me-xxl-9 {
        margin-right: 5rem !important
    }

    .me-xxl-10 {
        margin-right: 6rem !important
    }

    .me-xxl-11 {
        margin-right: 7rem !important
    }

    .me-xxl-12 {
        margin-right: 8rem !important
    }

    .me-xxl-gs {
        margin-right: 2rem !important
    }

    .me-xxl-10p {
        margin-right: 10% !important
    }

    .me-xxl-20p {
        margin-right: 20% !important
    }

    .me-xxl-25p {
        margin-right: 25% !important
    }

    .me-xxl-30p {
        margin-right: 30% !important
    }

    .me-xxl-35p {
        margin-right: 35% !important
    }

    .me-xxl-40p {
        margin-right: 40% !important
    }

    .me-xxl-45p {
        margin-right: 45% !important
    }

    .me-xxl-50p {
        margin-right: 50% !important
    }

    .me-xxl-60p {
        margin-right: 60% !important
    }

    .me-xxl-70p {
        margin-right: 70% !important
    }

    .me-xxl-75p {
        margin-right: 75% !important
    }

    .me-xxl-80p {
        margin-right: 80% !important
    }

    .me-xxl-90p {
        margin-right: 90% !important
    }

    .me-xxl-100p {
        margin-right: 100% !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-0-5 {
        margin-bottom: .125rem !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 2rem !important
    }

    .mb-xxl-6 {
        margin-bottom: 3.5rem !important
    }

    .mb-xxl-7 {
        margin-bottom: 4rem !important
    }

    .mb-xxl-8 {
        margin-bottom: 4.5rem !important
    }

    .mb-xxl-9 {
        margin-bottom: 5rem !important
    }

    .mb-xxl-10 {
        margin-bottom: 6rem !important
    }

    .mb-xxl-11 {
        margin-bottom: 7rem !important
    }

    .mb-xxl-12 {
        margin-bottom: 8rem !important
    }

    .mb-xxl-gs {
        margin-bottom: 2rem !important
    }

    .mb-xxl-10p {
        margin-bottom: 10% !important
    }

    .mb-xxl-20p {
        margin-bottom: 20% !important
    }

    .mb-xxl-25p {
        margin-bottom: 25% !important
    }

    .mb-xxl-30p {
        margin-bottom: 30% !important
    }

    .mb-xxl-35p {
        margin-bottom: 35% !important
    }

    .mb-xxl-40p {
        margin-bottom: 40% !important
    }

    .mb-xxl-45p {
        margin-bottom: 45% !important
    }

    .mb-xxl-50p {
        margin-bottom: 50% !important
    }

    .mb-xxl-60p {
        margin-bottom: 60% !important
    }

    .mb-xxl-70p {
        margin-bottom: 70% !important
    }

    .mb-xxl-75p {
        margin-bottom: 75% !important
    }

    .mb-xxl-80p {
        margin-bottom: 80% !important
    }

    .mb-xxl-90p {
        margin-bottom: 90% !important
    }

    .mb-xxl-100p {
        margin-bottom: 100% !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-0-5 {
        margin-left: .125rem !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 2rem !important
    }

    .ms-xxl-6 {
        margin-left: 3.5rem !important
    }

    .ms-xxl-7 {
        margin-left: 4rem !important
    }

    .ms-xxl-8 {
        margin-left: 4.5rem !important
    }

    .ms-xxl-9 {
        margin-left: 5rem !important
    }

    .ms-xxl-10 {
        margin-left: 6rem !important
    }

    .ms-xxl-11 {
        margin-left: 7rem !important
    }

    .ms-xxl-12 {
        margin-left: 8rem !important
    }

    .ms-xxl-gs {
        margin-left: 2rem !important
    }

    .ms-xxl-10p {
        margin-left: 10% !important
    }

    .ms-xxl-20p {
        margin-left: 20% !important
    }

    .ms-xxl-25p {
        margin-left: 25% !important
    }

    .ms-xxl-30p {
        margin-left: 30% !important
    }

    .ms-xxl-35p {
        margin-left: 35% !important
    }

    .ms-xxl-40p {
        margin-left: 40% !important
    }

    .ms-xxl-45p {
        margin-left: 45% !important
    }

    .ms-xxl-50p {
        margin-left: 50% !important
    }

    .ms-xxl-60p {
        margin-left: 60% !important
    }

    .ms-xxl-70p {
        margin-left: 70% !important
    }

    .ms-xxl-75p {
        margin-left: 75% !important
    }

    .ms-xxl-80p {
        margin-left: 80% !important
    }

    .ms-xxl-90p {
        margin-left: 90% !important
    }

    .ms-xxl-100p {
        margin-left: 100% !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n0-5 {
        margin: -0.125rem !important
    }

    .m-xxl-n1 {
        margin: -0.25rem !important
    }

    .m-xxl-n2 {
        margin: -0.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -2rem !important
    }

    .m-xxl-n6 {
        margin: -3.5rem !important
    }

    .m-xxl-n7 {
        margin: -4rem !important
    }

    .m-xxl-n8 {
        margin: -4.5rem !important
    }

    .m-xxl-n9 {
        margin: -5rem !important
    }

    .m-xxl-n10 {
        margin: -6rem !important
    }

    .m-xxl-n11 {
        margin: -7rem !important
    }

    .m-xxl-n12 {
        margin: -8rem !important
    }

    .m-xxl-ngs {
        margin: -2rem !important
    }

    .m-xxl-n10p {
        margin: -10% !important
    }

    .m-xxl-n20p {
        margin: -20% !important
    }

    .m-xxl-n25p {
        margin: -25% !important
    }

    .m-xxl-n30p {
        margin: -30% !important
    }

    .m-xxl-n35p {
        margin: -35% !important
    }

    .m-xxl-n40p {
        margin: -40% !important
    }

    .m-xxl-n45p {
        margin: -45% !important
    }

    .m-xxl-n50p {
        margin: -50% !important
    }

    .m-xxl-n60p {
        margin: -60% !important
    }

    .m-xxl-n70p {
        margin: -70% !important
    }

    .m-xxl-n75p {
        margin: -75% !important
    }

    .m-xxl-n80p {
        margin: -80% !important
    }

    .m-xxl-n90p {
        margin: -90% !important
    }

    .m-xxl-n100p {
        margin: -100% !important
    }

    .mx-xxl-n0-5 {
        margin-right: -0.125rem !important;
        margin-left: -0.125rem !important
    }

    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important
    }

    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xxl-n6 {
        margin-right: -3.5rem !important;
        margin-left: -3.5rem !important
    }

    .mx-xxl-n7 {
        margin-right: -4rem !important;
        margin-left: -4rem !important
    }

    .mx-xxl-n8 {
        margin-right: -4.5rem !important;
        margin-left: -4.5rem !important
    }

    .mx-xxl-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important
    }

    .mx-xxl-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important
    }

    .mx-xxl-n11 {
        margin-right: -7rem !important;
        margin-left: -7rem !important
    }

    .mx-xxl-n12 {
        margin-right: -8rem !important;
        margin-left: -8rem !important
    }

    .mx-xxl-ngs {
        margin-right: -2rem !important;
        margin-left: -2rem !important
    }

    .mx-xxl-n10p {
        margin-right: -10% !important;
        margin-left: -10% !important
    }

    .mx-xxl-n20p {
        margin-right: -20% !important;
        margin-left: -20% !important
    }

    .mx-xxl-n25p {
        margin-right: -25% !important;
        margin-left: -25% !important
    }

    .mx-xxl-n30p {
        margin-right: -30% !important;
        margin-left: -30% !important
    }

    .mx-xxl-n35p {
        margin-right: -35% !important;
        margin-left: -35% !important
    }

    .mx-xxl-n40p {
        margin-right: -40% !important;
        margin-left: -40% !important
    }

    .mx-xxl-n45p {
        margin-right: -45% !important;
        margin-left: -45% !important
    }

    .mx-xxl-n50p {
        margin-right: -50% !important;
        margin-left: -50% !important
    }

    .mx-xxl-n60p {
        margin-right: -60% !important;
        margin-left: -60% !important
    }

    .mx-xxl-n70p {
        margin-right: -70% !important;
        margin-left: -70% !important
    }

    .mx-xxl-n75p {
        margin-right: -75% !important;
        margin-left: -75% !important
    }

    .mx-xxl-n80p {
        margin-right: -80% !important;
        margin-left: -80% !important
    }

    .mx-xxl-n90p {
        margin-right: -90% !important;
        margin-left: -90% !important
    }

    .mx-xxl-n100p {
        margin-right: -100% !important;
        margin-left: -100% !important
    }

    .my-xxl-n0-5 {
        margin-top: -0.125rem !important;
        margin-bottom: -0.125rem !important
    }

    .my-xxl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important
    }

    .my-xxl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xxl-n6 {
        margin-top: -3.5rem !important;
        margin-bottom: -3.5rem !important
    }

    .my-xxl-n7 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important
    }

    .my-xxl-n8 {
        margin-top: -4.5rem !important;
        margin-bottom: -4.5rem !important
    }

    .my-xxl-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important
    }

    .my-xxl-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important
    }

    .my-xxl-n11 {
        margin-top: -7rem !important;
        margin-bottom: -7rem !important
    }

    .my-xxl-n12 {
        margin-top: -8rem !important;
        margin-bottom: -8rem !important
    }

    .my-xxl-ngs {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important
    }

    .my-xxl-n10p {
        margin-top: -10% !important;
        margin-bottom: -10% !important
    }

    .my-xxl-n20p {
        margin-top: -20% !important;
        margin-bottom: -20% !important
    }

    .my-xxl-n25p {
        margin-top: -25% !important;
        margin-bottom: -25% !important
    }

    .my-xxl-n30p {
        margin-top: -30% !important;
        margin-bottom: -30% !important
    }

    .my-xxl-n35p {
        margin-top: -35% !important;
        margin-bottom: -35% !important
    }

    .my-xxl-n40p {
        margin-top: -40% !important;
        margin-bottom: -40% !important
    }

    .my-xxl-n45p {
        margin-top: -45% !important;
        margin-bottom: -45% !important
    }

    .my-xxl-n50p {
        margin-top: -50% !important;
        margin-bottom: -50% !important
    }

    .my-xxl-n60p {
        margin-top: -60% !important;
        margin-bottom: -60% !important
    }

    .my-xxl-n70p {
        margin-top: -70% !important;
        margin-bottom: -70% !important
    }

    .my-xxl-n75p {
        margin-top: -75% !important;
        margin-bottom: -75% !important
    }

    .my-xxl-n80p {
        margin-top: -80% !important;
        margin-bottom: -80% !important
    }

    .my-xxl-n90p {
        margin-top: -90% !important;
        margin-bottom: -90% !important
    }

    .my-xxl-n100p {
        margin-top: -100% !important;
        margin-bottom: -100% !important
    }

    .mt-xxl-n0-5 {
        margin-top: -0.125rem !important
    }

    .mt-xxl-n1 {
        margin-top: -0.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -0.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -2rem !important
    }

    .mt-xxl-n6 {
        margin-top: -3.5rem !important
    }

    .mt-xxl-n7 {
        margin-top: -4rem !important
    }

    .mt-xxl-n8 {
        margin-top: -4.5rem !important
    }

    .mt-xxl-n9 {
        margin-top: -5rem !important
    }

    .mt-xxl-n10 {
        margin-top: -6rem !important
    }

    .mt-xxl-n11 {
        margin-top: -7rem !important
    }

    .mt-xxl-n12 {
        margin-top: -8rem !important
    }

    .mt-xxl-ngs {
        margin-top: -2rem !important
    }

    .mt-xxl-n10p {
        margin-top: -10% !important
    }

    .mt-xxl-n20p {
        margin-top: -20% !important
    }

    .mt-xxl-n25p {
        margin-top: -25% !important
    }

    .mt-xxl-n30p {
        margin-top: -30% !important
    }

    .mt-xxl-n35p {
        margin-top: -35% !important
    }

    .mt-xxl-n40p {
        margin-top: -40% !important
    }

    .mt-xxl-n45p {
        margin-top: -45% !important
    }

    .mt-xxl-n50p {
        margin-top: -50% !important
    }

    .mt-xxl-n60p {
        margin-top: -60% !important
    }

    .mt-xxl-n70p {
        margin-top: -70% !important
    }

    .mt-xxl-n75p {
        margin-top: -75% !important
    }

    .mt-xxl-n80p {
        margin-top: -80% !important
    }

    .mt-xxl-n90p {
        margin-top: -90% !important
    }

    .mt-xxl-n100p {
        margin-top: -100% !important
    }

    .me-xxl-n0-5 {
        margin-right: -0.125rem !important
    }

    .me-xxl-n1 {
        margin-right: -0.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -0.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -2rem !important
    }

    .me-xxl-n6 {
        margin-right: -3.5rem !important
    }

    .me-xxl-n7 {
        margin-right: -4rem !important
    }

    .me-xxl-n8 {
        margin-right: -4.5rem !important
    }

    .me-xxl-n9 {
        margin-right: -5rem !important
    }

    .me-xxl-n10 {
        margin-right: -6rem !important
    }

    .me-xxl-n11 {
        margin-right: -7rem !important
    }

    .me-xxl-n12 {
        margin-right: -8rem !important
    }

    .me-xxl-ngs {
        margin-right: -2rem !important
    }

    .me-xxl-n10p {
        margin-right: -10% !important
    }

    .me-xxl-n20p {
        margin-right: -20% !important
    }

    .me-xxl-n25p {
        margin-right: -25% !important
    }

    .me-xxl-n30p {
        margin-right: -30% !important
    }

    .me-xxl-n35p {
        margin-right: -35% !important
    }

    .me-xxl-n40p {
        margin-right: -40% !important
    }

    .me-xxl-n45p {
        margin-right: -45% !important
    }

    .me-xxl-n50p {
        margin-right: -50% !important
    }

    .me-xxl-n60p {
        margin-right: -60% !important
    }

    .me-xxl-n70p {
        margin-right: -70% !important
    }

    .me-xxl-n75p {
        margin-right: -75% !important
    }

    .me-xxl-n80p {
        margin-right: -80% !important
    }

    .me-xxl-n90p {
        margin-right: -90% !important
    }

    .me-xxl-n100p {
        margin-right: -100% !important
    }

    .mb-xxl-n0-5 {
        margin-bottom: -0.125rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -0.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -0.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -2rem !important
    }

    .mb-xxl-n6 {
        margin-bottom: -3.5rem !important
    }

    .mb-xxl-n7 {
        margin-bottom: -4rem !important
    }

    .mb-xxl-n8 {
        margin-bottom: -4.5rem !important
    }

    .mb-xxl-n9 {
        margin-bottom: -5rem !important
    }

    .mb-xxl-n10 {
        margin-bottom: -6rem !important
    }

    .mb-xxl-n11 {
        margin-bottom: -7rem !important
    }

    .mb-xxl-n12 {
        margin-bottom: -8rem !important
    }

    .mb-xxl-ngs {
        margin-bottom: -2rem !important
    }

    .mb-xxl-n10p {
        margin-bottom: -10% !important
    }

    .mb-xxl-n20p {
        margin-bottom: -20% !important
    }

    .mb-xxl-n25p {
        margin-bottom: -25% !important
    }

    .mb-xxl-n30p {
        margin-bottom: -30% !important
    }

    .mb-xxl-n35p {
        margin-bottom: -35% !important
    }

    .mb-xxl-n40p {
        margin-bottom: -40% !important
    }

    .mb-xxl-n45p {
        margin-bottom: -45% !important
    }

    .mb-xxl-n50p {
        margin-bottom: -50% !important
    }

    .mb-xxl-n60p {
        margin-bottom: -60% !important
    }

    .mb-xxl-n70p {
        margin-bottom: -70% !important
    }

    .mb-xxl-n75p {
        margin-bottom: -75% !important
    }

    .mb-xxl-n80p {
        margin-bottom: -80% !important
    }

    .mb-xxl-n90p {
        margin-bottom: -90% !important
    }

    .mb-xxl-n100p {
        margin-bottom: -100% !important
    }

    .ms-xxl-n0-5 {
        margin-left: -0.125rem !important
    }

    .ms-xxl-n1 {
        margin-left: -0.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -0.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -2rem !important
    }

    .ms-xxl-n6 {
        margin-left: -3.5rem !important
    }

    .ms-xxl-n7 {
        margin-left: -4rem !important
    }

    .ms-xxl-n8 {
        margin-left: -4.5rem !important
    }

    .ms-xxl-n9 {
        margin-left: -5rem !important
    }

    .ms-xxl-n10 {
        margin-left: -6rem !important
    }

    .ms-xxl-n11 {
        margin-left: -7rem !important
    }

    .ms-xxl-n12 {
        margin-left: -8rem !important
    }

    .ms-xxl-ngs {
        margin-left: -2rem !important
    }

    .ms-xxl-n10p {
        margin-left: -10% !important
    }

    .ms-xxl-n20p {
        margin-left: -20% !important
    }

    .ms-xxl-n25p {
        margin-left: -25% !important
    }

    .ms-xxl-n30p {
        margin-left: -30% !important
    }

    .ms-xxl-n35p {
        margin-left: -35% !important
    }

    .ms-xxl-n40p {
        margin-left: -40% !important
    }

    .ms-xxl-n45p {
        margin-left: -45% !important
    }

    .ms-xxl-n50p {
        margin-left: -50% !important
    }

    .ms-xxl-n60p {
        margin-left: -60% !important
    }

    .ms-xxl-n70p {
        margin-left: -70% !important
    }

    .ms-xxl-n75p {
        margin-left: -75% !important
    }

    .ms-xxl-n80p {
        margin-left: -80% !important
    }

    .ms-xxl-n90p {
        margin-left: -90% !important
    }

    .ms-xxl-n100p {
        margin-left: -100% !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-0-5 {
        padding: .125rem !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 2rem !important
    }

    .p-xxl-6 {
        padding: 3.5rem !important
    }

    .p-xxl-7 {
        padding: 4rem !important
    }

    .p-xxl-8 {
        padding: 4.5rem !important
    }

    .p-xxl-9 {
        padding: 5rem !important
    }

    .p-xxl-10 {
        padding: 6rem !important
    }

    .p-xxl-11 {
        padding: 7rem !important
    }

    .p-xxl-12 {
        padding: 8rem !important
    }

    .p-xxl-gs {
        padding: 2rem !important
    }

    .p-xxl-10p {
        padding: 10% !important
    }

    .p-xxl-20p {
        padding: 20% !important
    }

    .p-xxl-25p {
        padding: 25% !important
    }

    .p-xxl-30p {
        padding: 30% !important
    }

    .p-xxl-35p {
        padding: 35% !important
    }

    .p-xxl-40p {
        padding: 40% !important
    }

    .p-xxl-45p {
        padding: 45% !important
    }

    .p-xxl-50p {
        padding: 50% !important
    }

    .p-xxl-60p {
        padding: 60% !important
    }

    .p-xxl-70p {
        padding: 70% !important
    }

    .p-xxl-75p {
        padding: 75% !important
    }

    .p-xxl-80p {
        padding: 80% !important
    }

    .p-xxl-90p {
        padding: 90% !important
    }

    .p-xxl-100p {
        padding: 100% !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-0-5 {
        padding-right: .125rem !important;
        padding-left: .125rem !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xxl-6 {
        padding-right: 3.5rem !important;
        padding-left: 3.5rem !important
    }

    .px-xxl-7 {
        padding-right: 4rem !important;
        padding-left: 4rem !important
    }

    .px-xxl-8 {
        padding-right: 4.5rem !important;
        padding-left: 4.5rem !important
    }

    .px-xxl-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important
    }

    .px-xxl-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important
    }

    .px-xxl-11 {
        padding-right: 7rem !important;
        padding-left: 7rem !important
    }

    .px-xxl-12 {
        padding-right: 8rem !important;
        padding-left: 8rem !important
    }

    .px-xxl-gs {
        padding-right: 2rem !important;
        padding-left: 2rem !important
    }

    .px-xxl-10p {
        padding-right: 10% !important;
        padding-left: 10% !important
    }

    .px-xxl-20p {
        padding-right: 20% !important;
        padding-left: 20% !important
    }

    .px-xxl-25p {
        padding-right: 25% !important;
        padding-left: 25% !important
    }

    .px-xxl-30p {
        padding-right: 30% !important;
        padding-left: 30% !important
    }

    .px-xxl-35p {
        padding-right: 35% !important;
        padding-left: 35% !important
    }

    .px-xxl-40p {
        padding-right: 40% !important;
        padding-left: 40% !important
    }

    .px-xxl-45p {
        padding-right: 45% !important;
        padding-left: 45% !important
    }

    .px-xxl-50p {
        padding-right: 50% !important;
        padding-left: 50% !important
    }

    .px-xxl-60p {
        padding-right: 60% !important;
        padding-left: 60% !important
    }

    .px-xxl-70p {
        padding-right: 70% !important;
        padding-left: 70% !important
    }

    .px-xxl-75p {
        padding-right: 75% !important;
        padding-left: 75% !important
    }

    .px-xxl-80p {
        padding-right: 80% !important;
        padding-left: 80% !important
    }

    .px-xxl-90p {
        padding-right: 90% !important;
        padding-left: 90% !important
    }

    .px-xxl-100p {
        padding-right: 100% !important;
        padding-left: 100% !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-0-5 {
        padding-top: .125rem !important;
        padding-bottom: .125rem !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xxl-6 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important
    }

    .py-xxl-7 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important
    }

    .py-xxl-8 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important
    }

    .py-xxl-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important
    }

    .py-xxl-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important
    }

    .py-xxl-11 {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important
    }

    .py-xxl-12 {
        padding-top: 8rem !important;
        padding-bottom: 8rem !important
    }

    .py-xxl-gs {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important
    }

    .py-xxl-10p {
        padding-top: 10% !important;
        padding-bottom: 10% !important
    }

    .py-xxl-20p {
        padding-top: 20% !important;
        padding-bottom: 20% !important
    }

    .py-xxl-25p {
        padding-top: 25% !important;
        padding-bottom: 25% !important
    }

    .py-xxl-30p {
        padding-top: 30% !important;
        padding-bottom: 30% !important
    }

    .py-xxl-35p {
        padding-top: 35% !important;
        padding-bottom: 35% !important
    }

    .py-xxl-40p {
        padding-top: 40% !important;
        padding-bottom: 40% !important
    }

    .py-xxl-45p {
        padding-top: 45% !important;
        padding-bottom: 45% !important
    }

    .py-xxl-50p {
        padding-top: 50% !important;
        padding-bottom: 50% !important
    }

    .py-xxl-60p {
        padding-top: 60% !important;
        padding-bottom: 60% !important
    }

    .py-xxl-70p {
        padding-top: 70% !important;
        padding-bottom: 70% !important
    }

    .py-xxl-75p {
        padding-top: 75% !important;
        padding-bottom: 75% !important
    }

    .py-xxl-80p {
        padding-top: 80% !important;
        padding-bottom: 80% !important
    }

    .py-xxl-90p {
        padding-top: 90% !important;
        padding-bottom: 90% !important
    }

    .py-xxl-100p {
        padding-top: 100% !important;
        padding-bottom: 100% !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-0-5 {
        padding-top: .125rem !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 2rem !important
    }

    .pt-xxl-6 {
        padding-top: 3.5rem !important
    }

    .pt-xxl-7 {
        padding-top: 4rem !important
    }

    .pt-xxl-8 {
        padding-top: 4.5rem !important
    }

    .pt-xxl-9 {
        padding-top: 5rem !important
    }

    .pt-xxl-10 {
        padding-top: 6rem !important
    }

    .pt-xxl-11 {
        padding-top: 7rem !important
    }

    .pt-xxl-12 {
        padding-top: 8rem !important
    }

    .pt-xxl-gs {
        padding-top: 2rem !important
    }

    .pt-xxl-10p {
        padding-top: 10% !important
    }

    .pt-xxl-20p {
        padding-top: 20% !important
    }

    .pt-xxl-25p {
        padding-top: 25% !important
    }

    .pt-xxl-30p {
        padding-top: 30% !important
    }

    .pt-xxl-35p {
        padding-top: 35% !important
    }

    .pt-xxl-40p {
        padding-top: 40% !important
    }

    .pt-xxl-45p {
        padding-top: 45% !important
    }

    .pt-xxl-50p {
        padding-top: 50% !important
    }

    .pt-xxl-60p {
        padding-top: 60% !important
    }

    .pt-xxl-70p {
        padding-top: 70% !important
    }

    .pt-xxl-75p {
        padding-top: 75% !important
    }

    .pt-xxl-80p {
        padding-top: 80% !important
    }

    .pt-xxl-90p {
        padding-top: 90% !important
    }

    .pt-xxl-100p {
        padding-top: 100% !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-0-5 {
        padding-right: .125rem !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 2rem !important
    }

    .pe-xxl-6 {
        padding-right: 3.5rem !important
    }

    .pe-xxl-7 {
        padding-right: 4rem !important
    }

    .pe-xxl-8 {
        padding-right: 4.5rem !important
    }

    .pe-xxl-9 {
        padding-right: 5rem !important
    }

    .pe-xxl-10 {
        padding-right: 6rem !important
    }

    .pe-xxl-11 {
        padding-right: 7rem !important
    }

    .pe-xxl-12 {
        padding-right: 8rem !important
    }

    .pe-xxl-gs {
        padding-right: 2rem !important
    }

    .pe-xxl-10p {
        padding-right: 10% !important
    }

    .pe-xxl-20p {
        padding-right: 20% !important
    }

    .pe-xxl-25p {
        padding-right: 25% !important
    }

    .pe-xxl-30p {
        padding-right: 30% !important
    }

    .pe-xxl-35p {
        padding-right: 35% !important
    }

    .pe-xxl-40p {
        padding-right: 40% !important
    }

    .pe-xxl-45p {
        padding-right: 45% !important
    }

    .pe-xxl-50p {
        padding-right: 50% !important
    }

    .pe-xxl-60p {
        padding-right: 60% !important
    }

    .pe-xxl-70p {
        padding-right: 70% !important
    }

    .pe-xxl-75p {
        padding-right: 75% !important
    }

    .pe-xxl-80p {
        padding-right: 80% !important
    }

    .pe-xxl-90p {
        padding-right: 90% !important
    }

    .pe-xxl-100p {
        padding-right: 100% !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-0-5 {
        padding-bottom: .125rem !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 2rem !important
    }

    .pb-xxl-6 {
        padding-bottom: 3.5rem !important
    }

    .pb-xxl-7 {
        padding-bottom: 4rem !important
    }

    .pb-xxl-8 {
        padding-bottom: 4.5rem !important
    }

    .pb-xxl-9 {
        padding-bottom: 5rem !important
    }

    .pb-xxl-10 {
        padding-bottom: 6rem !important
    }

    .pb-xxl-11 {
        padding-bottom: 7rem !important
    }

    .pb-xxl-12 {
        padding-bottom: 8rem !important
    }

    .pb-xxl-gs {
        padding-bottom: 2rem !important
    }

    .pb-xxl-10p {
        padding-bottom: 10% !important
    }

    .pb-xxl-20p {
        padding-bottom: 20% !important
    }

    .pb-xxl-25p {
        padding-bottom: 25% !important
    }

    .pb-xxl-30p {
        padding-bottom: 30% !important
    }

    .pb-xxl-35p {
        padding-bottom: 35% !important
    }

    .pb-xxl-40p {
        padding-bottom: 40% !important
    }

    .pb-xxl-45p {
        padding-bottom: 45% !important
    }

    .pb-xxl-50p {
        padding-bottom: 50% !important
    }

    .pb-xxl-60p {
        padding-bottom: 60% !important
    }

    .pb-xxl-70p {
        padding-bottom: 70% !important
    }

    .pb-xxl-75p {
        padding-bottom: 75% !important
    }

    .pb-xxl-80p {
        padding-bottom: 80% !important
    }

    .pb-xxl-90p {
        padding-bottom: 90% !important
    }

    .pb-xxl-100p {
        padding-bottom: 100% !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-0-5 {
        padding-left: .125rem !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 2rem !important
    }

    .ps-xxl-6 {
        padding-left: 3.5rem !important
    }

    .ps-xxl-7 {
        padding-left: 4rem !important
    }

    .ps-xxl-8 {
        padding-left: 4.5rem !important
    }

    .ps-xxl-9 {
        padding-left: 5rem !important
    }

    .ps-xxl-10 {
        padding-left: 6rem !important
    }

    .ps-xxl-11 {
        padding-left: 7rem !important
    }

    .ps-xxl-12 {
        padding-left: 8rem !important
    }

    .ps-xxl-gs {
        padding-left: 2rem !important
    }

    .ps-xxl-10p {
        padding-left: 10% !important
    }

    .ps-xxl-20p {
        padding-left: 20% !important
    }

    .ps-xxl-25p {
        padding-left: 25% !important
    }

    .ps-xxl-30p {
        padding-left: 30% !important
    }

    .ps-xxl-35p {
        padding-left: 35% !important
    }

    .ps-xxl-40p {
        padding-left: 40% !important
    }

    .ps-xxl-45p {
        padding-left: 45% !important
    }

    .ps-xxl-50p {
        padding-left: 50% !important
    }

    .ps-xxl-60p {
        padding-left: 60% !important
    }

    .ps-xxl-70p {
        padding-left: 70% !important
    }

    .ps-xxl-75p {
        padding-left: 75% !important
    }

    .ps-xxl-80p {
        padding-left: 80% !important
    }

    .ps-xxl-90p {
        padding-left: 90% !important
    }

    .ps-xxl-100p {
        padding-left: 100% !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-0-5 {
        gap: .125rem !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 2rem !important
    }

    .gap-xxl-6 {
        gap: 3.5rem !important
    }

    .gap-xxl-7 {
        gap: 4rem !important
    }

    .gap-xxl-8 {
        gap: 4.5rem !important
    }

    .gap-xxl-9 {
        gap: 5rem !important
    }

    .gap-xxl-10 {
        gap: 6rem !important
    }

    .gap-xxl-11 {
        gap: 7rem !important
    }

    .gap-xxl-12 {
        gap: 8rem !important
    }

    .gap-xxl-gs {
        gap: 2rem !important
    }

    .gap-xxl-10p {
        gap: 10% !important
    }

    .gap-xxl-20p {
        gap: 20% !important
    }

    .gap-xxl-25p {
        gap: 25% !important
    }

    .gap-xxl-30p {
        gap: 30% !important
    }

    .gap-xxl-35p {
        gap: 35% !important
    }

    .gap-xxl-40p {
        gap: 40% !important
    }

    .gap-xxl-45p {
        gap: 45% !important
    }

    .gap-xxl-50p {
        gap: 50% !important
    }

    .gap-xxl-60p {
        gap: 60% !important
    }

    .gap-xxl-70p {
        gap: 70% !important
    }

    .gap-xxl-75p {
        gap: 75% !important
    }

    .gap-xxl-80p {
        gap: 80% !important
    }

    .gap-xxl-90p {
        gap: 90% !important
    }

    .gap-xxl-100p {
        gap: 100% !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media(min-width: 1200px) {
    .fs-1 {
        font-size: 3rem !important
    }

    .fs-2 {
        font-size: 2.25rem !important
    }

    .fs-3 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

.btn-md {
    --btn-padding-y: 0.1875rem;
    --btn-padding-x: 1.125rem;
    --btn-font-size: 0.875rem;
    --btn-border-radius: 0.375rem
}

.btn-soft.btn-primary {
    --btn-color: var(--primary);
    --btn-bg: #e9e6fd;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-primary {
    --btn-color: var(--primary);
    --btn-bg: #e9e6fd;
    --btn-border-color: #c4bdfa
}

.btn-soft.btn-secondary {
    --btn-color: var(--secondary);
    --btn-bg: #dbdcde;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-secondary {
    --btn-color: var(--secondary);
    --btn-bg: #dbdcde;
    --btn-border-color: #9fa1a7
}

.btn-soft.btn-success {
    --btn-color: #55b59e;
    --btn-bg: #e6f4f0;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-success {
    --btn-color: #55b59e;
    --btn-bg: #e6f4f0;
    --btn-border-color: #bbe1d8
}

.btn-soft.btn-info {
    --btn-color: #39baf6;
    --btn-bg: #e1f5fe;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-info {
    --btn-color: #39baf6;
    --btn-bg: #e1f5fe;
    --btn-border-color: #b0e3fb
}

.btn-soft.btn-warning {
    --btn-color: #f7c239;
    --btn-bg: #fef6e1;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-warning {
    --btn-color: #f7c239;
    --btn-bg: #fef6e1;
    --btn-border-color: #fce7b0
}

.btn-soft.btn-danger {
    --btn-color: #ce5a56;
    --btn-bg: #f8e6e6;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-danger {
    --btn-color: #ce5a56;
    --btn-bg: #f8e6e6;
    --btn-border-color: #ebbdbb
}

.btn-soft.btn-light {
    --btn-color: #f8f9fa;
    --btn-bg: #fefefe;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-light {
    --btn-color: #f8f9fa;
    --btn-bg: #fefefe;
    --btn-border-color: #fcfdfd
}

.btn-soft.btn-dark {
    --btn-color: #1c263b;
    --btn-bg: #dddee2;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-dark {
    --btn-color: #1c263b;
    --btn-bg: #dddee2;
    --btn-border-color: #a4a8b1
}

.btn-soft.btn-darker {
    --btn-color: var(--secondary);
    --btn-bg: #dbdcde;
    --btn-border-color: transparent
}

.btn-soft.btn-outline-darker {
    --btn-color: var(--secondary);
    --btn-bg: #dbdcde;
    --btn-border-color: #9fa1a7
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.btn img {
    height: 1rem
}

.btn .icon {
    font-size: 1.3em;
    line-height: 1.5rem
}

.btn .icon+span, .btn span+.icon {
    margin-left: .625rem
}

.btn-icon {
    --btn-padding-x: 0;
    width: 2.5rem;
}

.btn-icon.btn-sm, .btn-group-sm>.btn-icon.btn {
    width: 1.875rem
}

.btn-icon.btn-md {
    width: 2.125rem
}

.btn-icon.btn-lg, .btn-group-lg>.btn-icon.btn {
    width: 3rem
}

.btn-block {
    width: 100%
}

.btn-light, .btn-outline-light {
    color: #1c263b
}

.btn-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--gutter-y));
    margin-right: calc(-0.5*var(--gutter-x));
    margin-left: calc(-0.5*var(--gutter-x))
}

.btn-list>* {
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-top: var(--gutter-y)
}

.btn-list-inline {
    flex-direction: row
}

.text-center .btn-list {
    justify-content: center
}

@media(min-width: 576px) {
    .text-sm-start .btn-list {
        justify-content: flex-start
    }
}

@media(min-width: 768px) {
    .text-md-start .btn-list {
        justify-content: flex-start
    }
}

@media(min-width: 992px) {
    .text-lg-start .btn-list {
        justify-content: flex-start
    }
}

@media(min-width: 1200px) {
    .text-xl-start .btn-list {
        justify-content: flex-start
    }
}

@media(min-width: 1400px) {
    .text-xxl-start .btn-list {
        justify-content: flex-start
    }
}

.is-dark [class*=btn-outline]:not(.btn-soft) {
    --btn-color: #fff
}

.accordion {
    --accordion-btn-padding-y: 1.375rem;
    --accordion-body-padding-y: 1.375rem;
    --accordion-active-bg: transparent
}

.is-dark .accordion {
    --accordion-btn-color: #dce3ed;
    --accordion-active-color: #fff;
    --accordion-color: #8b97a6;
    --accordion-bg: #11182b;
    --accordion-border-color: #1f2a42;
    --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M12 15.5c-0 0-0.001 0-0.001 0-0.276 0-0.525-0.112-0.706-0.293l-5-5c-0.174-0.18-0.281-0.425-0.281-0.695 0-0.552 0.448-1 1-1 0.27 0 0.515 0.107 0.695 0.281l4.293 4.293 4.293-4.293c0.18-0.174 0.425-0.281 0.695-0.281 0.552 0 1 0.448 1 1 0 0.27-0.107 0.515-0.281 0.695l-5 5c-0.18 0.181-0.43 0.293-0.706 0.293-0 0-0.001 0-0.001 0h0z'%3e%3c/path%3e%3c/svg%3e");
    --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath d='M12 15.5c-0 0-0.001 0-0.001 0-0.276 0-0.525-0.112-0.706-0.293l-5-5c-0.174-0.18-0.281-0.425-0.281-0.695 0-0.552 0.448-1 1-1 0.27 0 0.515 0.107 0.695 0.281l4.293 4.293 4.293-4.293c0.18-0.174 0.425-0.281 0.695-0.281 0.552 0 1 0.448 1 1 0 0.27-0.107 0.515-0.281 0.695l-5 5c-0.18 0.181-0.43 0.293-0.706 0.293-0 0-0.001 0-0.001 0h0z'%3e%3c/path%3e%3c/svg%3e")
}

.accordion-button {
    font-size: 1.125rem;
    font-weight: 500
}

.accordion-button:focus, .accordion-button:not(.collapsed) {
    box-shadow: none
}

.accordion-body {
    padding-top: 0
}

.accordion-separated .accordion-item {
    border-radius: .875rem;
    border: var(--accordion-border-width) solid var(--accordion-border-color)
}

.accordion-separated .accordion-item:not(:last-child) {
    margin-bottom: .685rem
}

.accordion-separated .accordion-button {
    --accordion-btn-bg: transparent;
    border-radius: var(--accordion-inner-border-radius) !important
}

.accordion-flush {
    --accordion-btn-padding-x: 0;
    --accordion-body-padding-x: 0;
    --accordion-bg: transparent;
    --accordion-active-bg: transparent;
    margin: -1.5rem 0
}

.accordion-flush .accordion-button {
    box-shadow: none
}

.accordion-plus-minus .accordion-button:after {
    background-image: none;
    font-family: "Nioicon";
    content: ""
}

.accordion-plus-minus .accordion-button:not(.collapsed):after {
    content: ""
}

.accordion-icon-accent .accordion-button:after {
    color: var(--primary)
}

.is-dark .card {
    --card-bg: rgb(37 48 60 / 45%);
}

.card-hover-translate {
    transition: all .3s ease
}

.card-hover-translate:hover {
    transform: translateY(-10px)
}

.nav-tabs {
    border-color: #f8f9fa
}

.nav-tabs .nav-link {
    border: 0
}

.nav-tabs-stretch {
    border-bottom: 0;
    margin-left: -1.25rem;
    margin-right: -1.25rem
}

.nav-tabs-stretch li {
    flex-grow: 1;
    border-bottom: 1px solid #f4f7fa;
    padding: 0 1.25rem
}

.nav-link {
    position: relative;
    display: inline-flex;
    gap: .625rem;
    align-items: center;
    white-space: nowrap;
    color: #6b7989;
    font-weight: 700;
    padding: .75rem 0
}

.nav-link .icon {
    font-size: 1.25rem
}

.nav-link:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: var(--primary);
    opacity: 0
}

.nav-link.active {
    color: #1c263b
}

.nav-link.active:after {
    opacity: 1
}

@media(min-width: 1200px) {
    .nav-tabs-stretch {
        margin-left: -1.875rem;
        margin-right: -1.875rem
    }

    .nav-tabs-stretch li {
        padding: 0 1.875rem
    }
}

.form-check {
    display: flex;
    align-items: flex-start
}

.form-check-label {
    line-height: 1.4rem;
    padding-left: .55rem;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
}

.form-check-input {
    margin-top: .12rem;
}

.form-check-lg .form-check-label {
    line-height: 2rem;
    padding-left: .875rem;
    font-size: 1.125rem
}

.form-check-lg .form-check-input {
    margin-top: .25rem;
    height: 1.5rem;
    width: 1.5rem
}

.form-check-lg.form-switch .form-check-input {
    width: 3rem
}

.form-check-xl .form-check-label {
    line-height: 2.25rem;
    padding-left: 1rem;
    font-size: 1.25rem
}

.form-check-xl .form-check-input {
    margin-top: .125rem;
    height: 2rem;
    width: 2rem
}

.form-check-xl.form-switch .form-check-input {
    width: 4rem
}

textarea.form-control {
    min-height: 5rem
}

textarea.form-control-sm {
    min-height: 3.75rem
}

textarea.form-control-lg {
    min-height: 7.5rem
}

.form-control-wrap {
    position: relative
}

.form-control-icon {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    color: #9b9ca2;
}

.form-control-icon.start+.form-control {
    padding-left: 2.5rem
}

.form-control-icon.end {
    left: auto;
    right: 0
}

.form-control-icon.end+.form-control {
    padding-right: 2.5rem
}

.form-control-plaintext:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0)
}

.password-toggle .on {
    display: block
}

.password-toggle .off {
    display: none
}

.password-toggle.is-shown .on {
    display: none
}

.password-toggle.is-shown .off {
    display: block
}

.hover-bg-primary:hover {
    background-color: RGBA(108, 91, 243, var(--hover-bg-opacity, 1)) !important
}

.hover-text-primary:hover {
    color: RGBA(108, 91, 243, var(--hover-text-opacity, 1)) !important
}

.text-bg-primary-soft {
    color: var(--primary);
    background-color: #edebfe !important;
    border-color: transparent
}

.text-bg-primary-soft-outline {
    color: var(--primary);
    background-color: #edebfe !important;
    border-color: #d3cefb
}

.is-dark .text-bg-primary-soft {
    color: var(--primary);
    background-color: #262c51 !important;
    border-color: transparent
}

.is-dark .text-bg-primary-soft-outline {
    color: var(--primary);
    background-color: #262c51 !important;
    border-color: #343672
}

.is-dark .litw-primary {
    color: var(--primary) !important
}

.is-dark .litw-primary:hover, .is-dark .litw-primary:focus {
    color: var(--primary-dark) !important
}

.hover-bg-secondary:hover {
    background-color: RGBA(14, 20, 36, var(--hover-bg-opacity, 1)) !important
}

.hover-text-secondary:hover {
    color: RGBA(14, 20, 36, var(--hover-text-opacity, 1)) !important
}

.text-bg-secondary-soft {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: transparent
}

.text-bg-secondary-soft-outline {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: #b7b9bd
}

.is-dark .text-bg-secondary-soft {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: transparent
}

.is-dark .text-bg-secondary-soft-outline {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: #182134
}

.is-dark .litw-secondary {
    color: var(--secondary) !important
}

.is-dark .litw-secondary:hover, .is-dark .litw-secondary:focus {
    color: #0b101d !important
}

.hover-bg-success:hover {
    background-color: RGBA(85, 181, 158, var(--hover-bg-opacity, 1)) !important
}

.hover-text-success:hover {
    color: RGBA(85, 181, 158, var(--hover-text-opacity, 1)) !important
}

.text-bg-success-soft {
    color: #55b59e;
    background-color: #ebf6f3 !important;
    border-color: transparent
}

.text-bg-success-soft-outline {
    color: #55b59e;
    background-color: #ebf6f3 !important;
    border-color: #cce9e2
}

.is-dark .text-bg-success-soft {
    color: #55b59e;
    background-color: #233747 !important;
    border-color: transparent
}

.is-dark .text-bg-success-soft-outline {
    color: #55b59e;
    background-color: #233747 !important;
    border-color: #2d5159
}

.is-dark .litw-success {
    color: #55b59e !important
}

.is-dark .litw-success:hover, .is-dark .litw-success:focus {
    color: #44917e !important
}

.hover-bg-info:hover {
    background-color: RGBA(57, 186, 246, var(--hover-bg-opacity, 1)) !important
}

.hover-text-info:hover {
    color: RGBA(57, 186, 246, var(--hover-text-opacity, 1)) !important
}

.text-bg-info-soft {
    color: #39baf6;
    background-color: #e7f7fe !important;
    border-color: transparent
}

.text-bg-info-soft-outline {
    color: #39baf6;
    background-color: #e7f7fe !important;
    border-color: #c4eafc
}

.is-dark .text-bg-info-soft {
    color: #39baf6;
    background-color: #1f3851 !important;
    border-color: transparent
}

.is-dark .text-bg-info-soft-outline {
    color: #39baf6;
    background-color: #1f3851 !important;
    border-color: #255273
}

.is-dark .litw-info {
    color: #39baf6 !important
}

.is-dark .litw-info:hover, .is-dark .litw-info:focus {
    color: #2e95c5 !important
}

.hover-bg-warning:hover {
    background-color: RGBA(247, 194, 57, var(--hover-bg-opacity, 1)) !important
}

.hover-text-warning:hover {
    color: RGBA(247, 194, 57, var(--hover-text-opacity, 1)) !important
}

.text-bg-warning-soft {
    color: #f7c239;
    background-color: #fef8e7 !important;
    border-color: transparent
}

.text-bg-warning-soft-outline {
    color: #f7c239;
    background-color: #fef8e7 !important;
    border-color: #fdedc4
}

.is-dark .text-bg-warning-soft {
    color: #f7c239;
    background-color: #36393b !important;
    border-color: transparent
}

.is-dark .text-bg-warning-soft-outline {
    color: #f7c239;
    background-color: #36393b !important;
    border-color: #5e553a
}

.is-dark .litw-warning {
    color: #f7c239 !important
}

.is-dark .litw-warning:hover, .is-dark .litw-warning:focus {
    color: #c69b2e !important
}

.hover-bg-danger:hover {
    background-color: RGBA(206, 90, 86, var(--hover-bg-opacity, 1)) !important
}

.hover-text-danger:hover {
    color: RGBA(206, 90, 86, var(--hover-text-opacity, 1)) !important
}

.text-bg-danger-soft {
    color: #ce5a56;
    background-color: #f9ebeb !important;
    border-color: transparent
}

.text-bg-danger-soft-outline {
    color: #ce5a56;
    background-color: #f9ebeb !important;
    border-color: #f0cecc
}

.is-dark .text-bg-danger-soft {
    color: #ce5a56;
    background-color: #312c3e !important;
    border-color: transparent
}

.is-dark .text-bg-danger-soft-outline {
    color: #ce5a56;
    background-color: #312c3e !important;
    border-color: #513643
}

.is-dark .litw-danger {
    color: #ce5a56 !important
}

.is-dark .litw-danger:hover, .is-dark .litw-danger:focus {
    color: #a54845 !important
}

.hover-bg-light:hover {
    background-color: RGBA(248, 249, 250, var(--hover-bg-opacity, 1)) !important
}

.hover-text-light:hover {
    color: RGBA(248, 249, 250, var(--hover-text-opacity, 1)) !important
}

.text-bg-light-soft {
    color: #f8f9fa;
    background-color: #fefefe !important;
    border-color: transparent
}

.text-bg-light-soft-outline {
    color: #f8f9fa;
    background-color: #fefefe !important;
    border-color: #fdfdfe
}

.is-dark .text-bg-light-soft {
    color: #f8f9fa;
    background-color: #363f52 !important;
    border-color: transparent
}

.is-dark .text-bg-light-soft-outline {
    color: #f8f9fa;
    background-color: #363f52 !important;
    border-color: #5e6574
}

.is-dark .litw-light {
    color: #f8f9fa !important
}

.is-dark .litw-light:hover, .is-dark .litw-light:focus {
    color: #c6c7c8 !important
}

.hover-bg-dark:hover {
    background-color: RGBA(28, 38, 59, var(--hover-bg-opacity, 1)) !important
}

.hover-text-dark:hover {
    color: RGBA(28, 38, 59, var(--hover-text-opacity, 1)) !important
}

.text-bg-dark-soft {
    color: #1c263b;
    background-color: #e4e5e7 !important;
    border-color: transparent
}

.text-bg-dark-soft-outline {
    color: #1c263b;
    background-color: #e4e5e7 !important;
    border-color: #bbbec4
}

.is-dark .text-bg-dark-soft {
    color: #1c263b;
    background-color: #1c263b !important;
    border-color: transparent
}

.is-dark .text-bg-dark-soft-outline {
    color: #1c263b;
    background-color: #1c263b !important;
    border-color: #1c263b
}

.is-dark .litw-dark {
    color: #1c263b !important
}

.is-dark .litw-dark:hover, .is-dark .litw-dark:focus {
    color: #161e2f !important
}

.hover-bg-darker:hover {
    background-color: RGBA(14, 20, 36, var(--hover-bg-opacity, 1)) !important
}

.hover-text-darker:hover {
    color: RGBA(14, 20, 36, var(--hover-text-opacity, 1)) !important
}

.text-bg-darker-soft {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: transparent
}

.text-bg-darker-soft-outline {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: #b7b9bd
}

.is-dark .text-bg-darker-soft {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: transparent
}

.is-dark .text-bg-darker-soft-outline {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: #182134
}

.is-dark .litw-darker {
    color: var(--secondary) !important
}

.is-dark .litw-darker:hover, .is-dark .litw-darker:focus {
    color: #0b101d !important
}

.bg-blue {
    background-color: RGBA(13, 110, 253, var(--bg-opacity, 1)) !important
}

.text-blue {
    color: RGBA(13, 110, 253, var(--text-opacity, 1)) !important
}

.hover-bg-blue:hover {
    background-color: RGBA(13, 110, 253, var(--hover-bg-opacity, 1)) !important
}

.hover-text-blue:hover {
    color: RGBA(13, 110, 253, var(--hover-text-opacity, 1)) !important
}

.text-bg-blue-soft {
    color: #0d6efd;
    background-color: #e2eeff !important;
    border-color: transparent
}

.text-bg-blue-soft-outline {
    color: #0d6efd;
    background-color: #e2eeff !important;
    border-color: #b6d4fe
}

.is-dark .text-bg-blue-soft {
    color: #0d6efd;
    background-color: #1a2f52 !important;
    border-color: transparent
}

.is-dark .text-bg-blue-soft-outline {
    color: #0d6efd;
    background-color: #1a2f52 !important;
    border-color: #183c75
}

.bg-indigo {
    background-color: RGBA(164, 37, 254, var(--bg-opacity, 1)) !important
}

.text-indigo {
    color: RGBA(164, 37, 254, var(--text-opacity, 1)) !important
}

.hover-bg-indigo:hover {
    background-color: RGBA(164, 37, 254, var(--hover-bg-opacity, 1)) !important
}

.hover-text-indigo:hover {
    color: RGBA(164, 37, 254, var(--hover-text-opacity, 1)) !important
}

.text-bg-indigo-soft {
    color: #a425fe;
    background-color: #f4e5ff !important;
    border-color: transparent
}

.text-bg-indigo-soft-outline {
    color: #a425fe;
    background-color: #f4e5ff !important;
    border-color: #e4beff
}

.is-dark .text-bg-indigo-soft {
    color: #a425fe;
    background-color: #2c2652 !important;
    border-color: transparent
}

.is-dark .text-bg-indigo-soft-outline {
    color: #a425fe;
    background-color: #2c2652 !important;
    border-color: #452676
}

.bg-purple {
    background-color: RGBA(111, 66, 193, var(--bg-opacity, 1)) !important
}

.text-purple {
    color: RGBA(111, 66, 193, var(--text-opacity, 1)) !important
}

.hover-bg-purple:hover {
    background-color: RGBA(111, 66, 193, var(--hover-bg-opacity, 1)) !important
}

.hover-text-purple:hover {
    color: RGBA(111, 66, 193, var(--hover-text-opacity, 1)) !important
}

.text-bg-purple-soft {
    color: #6f42c1;
    background-color: #eee8f8 !important;
    border-color: transparent
}

.text-bg-purple-soft-outline {
    color: #6f42c1;
    background-color: #eee8f8 !important;
    border-color: #d4c6ec
}

.is-dark .text-bg-purple-soft {
    color: #6f42c1;
    background-color: #26294b !important;
    border-color: transparent
}

.is-dark .text-bg-purple-soft-outline {
    color: #6f42c1;
    background-color: #26294b !important;
    border-color: #352e63
}

.bg-pink {
    background-color: RGBA(214, 51, 132, var(--bg-opacity, 1)) !important
}

.text-pink {
    color: RGBA(214, 51, 132, var(--text-opacity, 1)) !important
}

.hover-bg-pink:hover {
    background-color: RGBA(214, 51, 132, var(--hover-bg-opacity, 1)) !important
}

.hover-text-pink:hover {
    color: RGBA(214, 51, 132, var(--hover-text-opacity, 1)) !important
}

.text-bg-pitw-soft {
    color: #d63384;
    background-color: #fae7f0 !important;
    border-color: transparent
}

.text-bg-pitw-soft-outline {
    color: #d63384;
    background-color: #fae7f0 !important;
    border-color: #f3c2da
}

.is-dark .text-bg-pitw-soft {
    color: #d63384;
    background-color: #322844 !important;
    border-color: transparent
}

.is-dark .text-bg-pitw-soft-outline {
    color: #d63384;
    background-color: #322844 !important;
    border-color: #542a51
}

.bg-red {
    background-color: RGBA(206, 90, 86, var(--bg-opacity, 1)) !important
}

.text-red {
    color: RGBA(206, 90, 86, var(--text-opacity, 1)) !important
}

.hover-bg-red:hover {
    background-color: RGBA(206, 90, 86, var(--hover-bg-opacity, 1)) !important
}

.hover-text-red:hover {
    color: RGBA(206, 90, 86, var(--hover-text-opacity, 1)) !important
}

.text-bg-red-soft {
    color: #ce5a56;
    background-color: #f9ebeb !important;
    border-color: transparent
}

.text-bg-red-soft-outline {
    color: #ce5a56;
    background-color: #f9ebeb !important;
    border-color: #f0cecc
}

.is-dark .text-bg-red-soft {
    color: #ce5a56;
    background-color: #312c3e !important;
    border-color: transparent
}

.is-dark .text-bg-red-soft-outline {
    color: #ce5a56;
    background-color: #312c3e !important;
    border-color: #513643
}

.bg-orange {
    background-color: RGBA(253, 126, 20, var(--bg-opacity, 1)) !important
}

.text-orange {
    color: RGBA(253, 126, 20, var(--text-opacity, 1)) !important
}

.hover-bg-orange:hover {
    background-color: RGBA(253, 126, 20, var(--hover-bg-opacity, 1)) !important
}

.hover-text-orange:hover {
    color: RGBA(253, 126, 20, var(--hover-text-opacity, 1)) !important
}

.text-bg-orange-soft {
    color: #fd7e14;
    background-color: #fff0e3 !important;
    border-color: transparent
}

.text-bg-orange-soft-outline {
    color: #fd7e14;
    background-color: #fff0e3 !important;
    border-color: #fed8b9
}

.is-dark .text-bg-orange-soft {
    color: #fd7e14;
    background-color: #373136 !important;
    border-color: transparent
}

.is-dark .text-bg-orange-soft-outline {
    color: #fd7e14;
    background-color: #373136 !important;
    border-color: #60402f
}

.bg-yellow {
    background-color: RGBA(247, 194, 57, var(--bg-opacity, 1)) !important
}

.text-yellow {
    color: RGBA(247, 194, 57, var(--text-opacity, 1)) !important
}

.hover-bg-yellow:hover {
    background-color: RGBA(247, 194, 57, var(--hover-bg-opacity, 1)) !important
}

.hover-text-yellow:hover {
    color: RGBA(247, 194, 57, var(--hover-text-opacity, 1)) !important
}

.text-bg-yellow-soft {
    color: #f7c239;
    background-color: #fef8e7 !important;
    border-color: transparent
}

.text-bg-yellow-soft-outline {
    color: #f7c239;
    background-color: #fef8e7 !important;
    border-color: #fdedc4
}

.is-dark .text-bg-yellow-soft {
    color: #f7c239;
    background-color: #36393b !important;
    border-color: transparent
}

.is-dark .text-bg-yellow-soft-outline {
    color: #f7c239;
    background-color: #36393b !important;
    border-color: #5e553a
}

.bg-green {
    background-color: RGBA(25, 135, 84, var(--bg-opacity, 1)) !important
}

.text-green {
    color: RGBA(25, 135, 84, var(--text-opacity, 1)) !important
}

.hover-bg-green:hover {
    background-color: RGBA(25, 135, 84, var(--hover-bg-opacity, 1)) !important
}

.hover-text-green:hover {
    color: RGBA(25, 135, 84, var(--hover-text-opacity, 1)) !important
}

.text-bg-green-soft {
    color: #198754;
    background-color: #e3f1ea !important;
    border-color: transparent
}

.text-bg-green-soft-outline {
    color: #198754;
    background-color: #e3f1ea !important;
    border-color: #badbcc
}

.is-dark .text-bg-green-soft {
    color: #198754;
    background-color: #1c323e !important;
    border-color: transparent
}

.is-dark .text-bg-green-soft-outline {
    color: #198754;
    background-color: #1c323e !important;
    border-color: #1b4343
}

.bg-teal {
    background-color: RGBA(85, 181, 158, var(--bg-opacity, 1)) !important
}

.text-teal {
    color: RGBA(85, 181, 158, var(--text-opacity, 1)) !important
}

.hover-bg-teal:hover {
    background-color: RGBA(85, 181, 158, var(--hover-bg-opacity, 1)) !important
}

.hover-text-teal:hover {
    color: RGBA(85, 181, 158, var(--hover-text-opacity, 1)) !important
}

.text-bg-teal-soft {
    color: #55b59e;
    background-color: #ebf6f3 !important;
    border-color: transparent
}

.text-bg-teal-soft-outline {
    color: #55b59e;
    background-color: #ebf6f3 !important;
    border-color: #cce9e2
}

.is-dark .text-bg-teal-soft {
    color: #55b59e;
    background-color: #233747 !important;
    border-color: transparent
}

.is-dark .text-bg-teal-soft-outline {
    color: #55b59e;
    background-color: #233747 !important;
    border-color: #2d5159
}

.bg-cyan {
    background-color: RGBA(57, 186, 246, var(--bg-opacity, 1)) !important
}

.text-cyan {
    color: RGBA(57, 186, 246, var(--text-opacity, 1)) !important
}

.hover-bg-cyan:hover {
    background-color: RGBA(57, 186, 246, var(--hover-bg-opacity, 1)) !important
}

.hover-text-cyan:hover {
    color: RGBA(57, 186, 246, var(--hover-text-opacity, 1)) !important
}

.text-bg-cyan-soft {
    color: #39baf6;
    background-color: #e7f7fe !important;
    border-color: transparent
}

.text-bg-cyan-soft-outline {
    color: #39baf6;
    background-color: #e7f7fe !important;
    border-color: #c4eafc
}

.is-dark .text-bg-cyan-soft {
    color: #39baf6;
    background-color: #1f3851 !important;
    border-color: transparent
}

.is-dark .text-bg-cyan-soft-outline {
    color: #39baf6;
    background-color: #1f3851 !important;
    border-color: #255273
}

.bg-black {
    background-color: RGBA(0, 0, 0, var(--bg-opacity, 1)) !important
}

.text-black {
    color: RGBA(0, 0, 0, var(--text-opacity, 1)) !important
}

.hover-bg-black:hover {
    background-color: RGBA(0, 0, 0, var(--hover-bg-opacity, 1)) !important
}

.hover-text-black:hover {
    color: RGBA(0, 0, 0, var(--hover-text-opacity, 1)) !important
}

.text-bg-black-soft {
    color: #000;
    background-color: #e0e0e0 !important;
    border-color: transparent
}

.text-bg-black-soft-outline {
    color: #000;
    background-color: #e0e0e0 !important;
    border-color: #b3b3b3
}

.is-dark .text-bg-black-soft {
    color: #000;
    background-color: #192134 !important;
    border-color: transparent
}

.is-dark .text-bg-black-soft-outline {
    color: #000;
    background-color: #192134 !important;
    border-color: #141b29
}

.bg-white {
    background-color: RGBA(255, 255, 255, var(--bg-opacity, 1)) !important
}

.text-white {
    color: RGBA(255, 255, 255, var(--text-opacity, 1)) !important
}

.hover-bg-white:hover {
    background-color: RGBA(255, 255, 255, var(--hover-bg-opacity, 1)) !important
}

.hover-text-white:hover {
    color: RGBA(255, 255, 255, var(--hover-text-opacity, 1)) !important
}

.text-bg-white-soft {
    color: #fff;
    background-color: white !important;
    border-color: transparent
}

.text-bg-white-soft-outline {
    color: #fff;
    background-color: white !important;
    border-color: white
}

.is-dark .text-bg-white-soft {
    color: #fff;
    background-color: #374053 !important;
    border-color: transparent
}

.is-dark .text-bg-white-soft-outline {
    color: #fff;
    background-color: #374053 !important;
    border-color: #606776
}

.bg-gray {
    background-color: RGBA(107, 121, 137, var(--bg-opacity, 1)) !important
}

.text-gray {
    color: RGBA(107, 121, 137, var(--text-opacity, 1)) !important
}

.hover-bg-gray:hover {
    background-color: RGBA(107, 121, 137, var(--hover-bg-opacity, 1)) !important
}

.hover-text-gray:hover {
    color: RGBA(107, 121, 137, var(--hover-text-opacity, 1)) !important
}

.text-bg-gray-soft {
    color: #6b7989;
    background-color: #edeff1 !important;
    border-color: transparent
}

.text-bg-gray-soft-outline {
    color: #6b7989;
    background-color: #edeff1 !important;
    border-color: #d3d7dc
}

.is-dark .text-bg-gray-soft {
    color: #6b7989;
    background-color: #253044 !important;
    border-color: transparent
}

.is-dark .text-bg-gray-soft-outline {
    color: #6b7989;
    background-color: #253044 !important;
    border-color: #343f52
}

.bg-gray-dark {
    background-color: RGBA(14, 20, 36, var(--bg-opacity, 1)) !important
}

.text-gray-dark {
    color: RGBA(14, 20, 36, var(--text-opacity, 1)) !important
}

.hover-bg-gray-dark:hover {
    background-color: RGBA(14, 20, 36, var(--hover-bg-opacity, 1)) !important
}

.hover-text-gray-dark:hover {
    color: RGBA(14, 20, 36, var(--hover-text-opacity, 1)) !important
}

.text-bg-gray-dark-soft {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: transparent
}

.text-bg-gray-dark-soft-outline {
    color: var(--secondary);
    background-color: #e2e3e5 !important;
    border-color: #b7b9bd
}

.is-dark .text-bg-gray-dark-soft {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: transparent
}

.is-dark .text-bg-gray-dark-soft-outline {
    color: var(--secondary);
    background-color: #1a2438 !important;
    border-color: #182134
}

.text-base {
    color: #6b7989 !important
}

.text-heading {
    color: #1c263b !important
}

.is-dark .text-base {
    color: #f4f7fa !important
}

.is-dark .text-heading {
    color: #f8f9fa !important
}

.litw-base {
    color: #6b7989 !important
}

.litw-base:hover, .litw-base:focus {
    color: #4b5560 !important
}

.litw-heading {
    color: #1c263b !important
}

.litw-heading:hover, .litw-heading:focus {
    color: #141b29 !important
}

.bg-gradient-primary {
    background: linear-gradient(var(--bg-gradient-angle, 0deg), RGBA(108, 91, 243, var(--bg-opacity, 1)) 48.76%, RGBA(164, 37, 254, var(--bg-opacity, 1)) 98.19%);
}

.bg-gradient-warning {
    background: linear-gradient(var(--bg-gradient-angle, 180deg), RGBA(255, 103, 73, var(--bg-opacity, 1)) 0%, RGBA(255, 185, 95, var(--bg-opacity, 1)) 100%)
}

.bg-gradient-info {
    background: linear-gradient(var(--bg-gradient-angle, 180deg), RGBA(22, 162, 255, var(--bg-opacity, 1)) 0%, RGBA(48, 79, 254, var(--bg-opacity, 1)) 100%)
}

.bg-gradient-dark {
    background: linear-gradient(var(--bg-gradient-angle, 108.46deg), RGBA(34, 44, 56, var(--bg-opacity, 1)) 0%, RGBA(16, 28, 42, var(--bg-opacity, 1)) 100%)
}

.text-gradient-primary {
    background: linear-gradient(var(--bg-gradient-angle, 90deg), RGBA(108, 91, 243, var(--bg-opacity, 1)) 48.76%, RGBA(164, 37, 254, var(--bg-opacity, 1)) 98.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0)
}

.text-gradient-warning {
    background: linear-gradient(var(--bg-gradient-angle, 180deg), RGBA(255, 103, 73, var(--bg-opacity, 1)) 0%, RGBA(255, 185, 95, var(--bg-opacity, 1)) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0)
}

.text-gradient-info {
    background: linear-gradient(var(--bg-gradient-angle, 180deg), RGBA(22, 162, 255, var(--bg-opacity, 1)) 0%, RGBA(48, 79, 254, var(--bg-opacity, 1)) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0)
}

.text-gradient-dark {
    background: linear-gradient(var(--bg-gradient-angle, 108.46deg), RGBA(34, 44, 56, var(--bg-opacity, 1)) 0%, RGBA(16, 28, 42, var(--bg-opacity, 1)) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0)
}

.gradient-angle-0 {
    --bg-gradient-angle: 0deg
}

.gradient-angle-90 {
    --bg-gradient-angle: 90deg
}

.gradient-angle-180 {
    --bg-gradient-angle: 180deg
}

.gradient-angle-270 {
    --bg-gradient-angle: 270deg
}

.is-dark strong {
    color: #fff
}

.is-dark .litw-base, .is-dark .litw-dark {
    color: #dce3ed !important
}

.is-dark .litw-base:hover, .is-dark .litw-base:focus, .is-dark .litw-dark:hover, .is-dark .litw-dark:focus {
    color: #fff !important
}

.is-dark .text-muted {
    color: #8b97a6 !important
}

.has-gap {
    margin-top: calc(-1*var(--gutter-y));
    margin-right: calc(-0.5*var(--gutter-x));
    margin-left: calc(-0.5*var(--gutter-x))
}

.has-gap>* {
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-top: var(--gutter-y)
}

@keyframes blink {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.typed-cursor.typed-cursor--blink {
    animation: blink .07s infinite !important
}

.typed-cursor {
    margin-left: .5rem;
    font-weight: 100
}

.tw-body {
    outline: none
}

.tw-app-root {
    outline: none;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.tw-footer {
    margin-top: auto
}

.logo-wrap {
    display: flex;
    position: relative;
    height: 100%
}

.logo-link {
    display: inline-flex;
    max-height: 26px
}

.logo-img {
    height: 100%;
    max-height: 28px;
    width: auto;
    /* margin-top: 2px; */
}

.logo-img:not(:first-child) {
    position: absolute;
    left: 0
}

.logo-light {
    opacity: 0;
    visibility: hidden
}

.is-dark .logo-light {
    opacity: 1;
    visibility: visible
}

.logo-dark {
    opacity: 1;
    visibility: visible
}

.is-dark .logo-dark {
    opacity: 0;
    visibility: hidden
}

.tw-menu {
    display: flex;
    align-items: center
}

.tw-menu-list {
    display: flex;
    flex-direction: column;
    padding: .5rem 0;
    max-height: 100%;
    overflow: auto;
    width: 100%
}

.tw-menu-item {
    position: relative;
    width: 100%;
}

.tw-menu-item:hover>.tw-menu-link {
    color: var(--primary)
}

.tw-menu-link {
    display: flex;
    align-items: center;
    color: #1c263b;
    font-weight: 500;
    font-size: .875rem;
    background-color: rgba(0, 0, 0, 0);
    padding: .5rem 1.5rem;
    white-space: nowrap;
    width: 100%;
    justify-content: center;
}

.active > .tw-menu-link {
    color: var(--primary) !important;
}

.tw-menu-toggle:after {
    font-family: "Nioicon";
    content: "";
    margin-left: .25rem
}

.tw-menu-dropdown {
    display: block;
    background: #f7f7f7;
    overflow: hidden;
    height: 0;
    padding: 0;
    transition: all 0.2s ease;
}

.current-page>.tw-menu-dropdown {
    display: block
}

.tw-menu-sub .tw-menu-link {
    font-size: .8125rem
}

.tw-menu-buttons {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .75rem;
    margin-top: 1rem
}

.tw-menu-buttons>li {
    padding: 0 .75rem
}

.tw-menu-buttons>li .btn {
    font-size: .8125rem
}

.on-dark .tw-menu-item:hover>.tw-menu-link {
    color: var(--primary)
}

.on-dark .tw-menu-link {
    color: #fff
}

.on-dark .tw-menu-dropdown {
    background: #151e36;
}

@media(min-width: 992px) {
    [data-menu-collapse=lg] .tw-menu {
        width: 100%;
        transform: none;
        position: static;
        box-shadow: none;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0)
    }

    [data-menu-collapse=lg] .tw-menu-list {
        width: auto;
        flex-direction: row;
        padding: 0 1rem;
        overflow: visible
    }

    [data-menu-collapse=lg] .tw-menu-item {
        width: auto;
        position: relative;
        display: flex;
        align-items: center
    }

    [data-menu-collapse=lg] .tw-menu-item:hover .tw-menu-dropdown {
        opacity: 1;
        visibility: visible;
    }

    [data-menu-collapse=lg] .tw-menu-link {
        padding: 1.25rem 1rem
    }

    [data-menu-collapse=lg] .tw-menu-dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        border-radius: .5rem;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 20px 0 rgba(14, 20, 36, .08);
        display: block !important
    }

    [data-menu-collapse=lg] .tw-menu-mega-wrap {
        display: flex;
        padding: .5rem
    }

    [data-menu-collapse=lg] .tw-menu-sub {
        min-width: 10rem
    }

    [data-menu-collapse=lg] .tw-menu-sub .tw-menu-link {
        padding: .5rem 1.5rem;
        justify-content: flex-start;
    }

    [data-menu-collapse=lg] .tw-menu-buttons {
        min-width: auto;
        margin-top: 0;
        padding: 0;
        margin-right: -1rem
    }

    [data-menu-collapse=lg] .tw-menu-buttons>li {
        padding: 0 1rem
    }

    [data-menu-collapse=lg] .on-dark .tw-menu-dropdown {
        background: #151e36
    }

    [data-menu-collapse=lg] .has-fixed .tw-menu-dropdown {
        border-top-right-radius: 0;
        border-top-left-radius: 0
    }
}

@media(min-width: 1200px) {
    [data-menu-collapse=xl] .tw-menu {
        width: 100%;
        transform: none;
        position: static;
        box-shadow: none;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0)
    }

    [data-menu-collapse=xl] .tw-menu-list {
        width: auto;
        flex-direction: row;
        padding: 0 1rem;
        overflow: visible
    }

    [data-menu-collapse=xl] .tw-menu-item {
        width: auto;
        position: relative;
        display: flex;
        align-items: center
    }

    [data-menu-collapse=xl] .tw-menu-item:hover .tw-menu-dropdown {
        opacity: 1;
        visibility: visible
    }

    [data-menu-collapse=xl] .tw-menu-link {
        padding: 1.25rem 1rem
    }

    [data-menu-collapse=xl] .tw-menu-dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        border-radius: .5rem;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 20px 0 rgba(14, 20, 36, .08);
        display: block !important
    }

    [data-menu-collapse=xl] .tw-menu-mega-wrap {
        display: flex;
        padding: .5rem
    }

    [data-menu-collapse=xl] .tw-menu-sub {
        min-width: 10rem
    }

    [data-menu-collapse=xl] .tw-menu-sub .tw-menu-link {
        padding: .5rem 1.5rem
    }

    [data-menu-collapse=xl] .tw-menu-buttons {
        min-width: auto;
        margin-top: 0;
        padding: 0;
        margin-right: -1rem
    }

    [data-menu-collapse=xl] .tw-menu-buttons>li {
        padding: 0 1rem
    }

    [data-menu-collapse=xl] .on-dark .tw-menu-dropdown {
        background: #151e36
    }

    [data-menu-collapse=xl] .has-fixed .tw-menu-dropdown {
        border-top-right-radius: 0;
        border-top-left-radius: 0
    }
}

.tw-header {
    padding-top: 88px
}

.tw-header-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: .75rem 0px;
    transition: all .2s linear;
    background-color: #fff
}

.tw-header-main.is-transparent {
    background-color: rgba(0, 0, 0, 0)
}

.tw-header-main.has-border {
    border-bottom: 1px solid #f4f7fa
}

.tw-header-main.has-fixed {
    position: fixed;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(14, 20, 36, .08)
}

.tw-header-main.has-fixed.on-dark {
    background: #121a2f
}

.tw-header-main.will-shrink.has-fixed {
    padding: 0 0px
}

.tw-header-wrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.tw-header-logo {
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
    margin: .5rem 0;
    flex-shrink: 0
}

.tw-header-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1026;
    padding: 1rem 0;
    overflow: auto;
    transform: translateX(-100%);
    background: #fff
}

.on-dark .tw-header-menu {
    background: #121a2f
}

.tw-header-menu.header-menu-active {
    transform: none
}

.tw-header-menu.menu-mobile {
    transition: .2s ease;
}

@media(min-width: 992px) {
    [data-menu-collapse=lg] .tw-header-menu {
        flex-grow: 1;
        transform: none;
        position: static;
        border: none;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        overflow: visible
    }

    [data-menu-collapse=lg] .tw-header-toggle {
        display: none
    }
}

@media(min-width: 1200px) {
    [data-menu-collapse=xl] .tw-header-menu {
        flex-grow: 1;
        transform: none;
        position: static;
        border: none;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
        overflow: visible
    }

    [data-menu-collapse=xl] .tw-header-toggle {
        display: none
    }
}

.header-menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 1024;
    opacity: 0;
    animation: fade-out 400ms ease 1
}

.header-menu-shown .header-menu-overlay {
    opacity: 1;
    animation: fade-in 400ms ease 1
}

.header-menu-shown {
    overflow: hidden
}

.tw-page-head {
    padding: 30px 0 50px
}

@media(min-width: 768px) {
    .tw-page-head {
        padding: 60px 0 80px
    }
}

.section {
    isolation: isolate;
    scroll-margin-top: var(--header-main-height, 63px);
    padding-top: 3.75rem;
    padding-bottom: 3.75rem
}

.section-head {
    padding-bottom: 2.5rem
}

.section-head .title:where(:not(:last-child)) {
    margin-bottom: 1.125rem
}

.section-content+.section-actions {
    padding-top: 2.5rem
}

.section-content+.section-content, .section-content+.section-head {
    padding-top: 3.75rem
}

.section-actions+.section-content {
    padding-top: 3.75rem
}

.section-wrap {
    position: relative
}

.section-wrap-angle:before {
    position: absolute;
    content: "";
    z-index: -1;
    background: #fff;
    height: 75px;
    width: 100%
}

.section-wrap-angle.bg-darker:before {
    background: var(--secondary)
}

.section-wrap-angle-top-right {
    margin-top: 60px
}

.section-wrap-angle-top-right:before {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    bottom: calc(100% - 45px);
    transform: skewY(-2.8deg)
}

@media(min-width: 992px) {
    .section {
        padding-top: 5.625rem;
        padding-bottom: 5.625rem
    }

    .section-head {
        padding-bottom: 3.75rem
    }

    .section-content+.section-actions {
        padding-top: 3.75rem
    }

    .section-content+.section-content, .section-content+.section-head {
        padding-top: 5.625rem
    }

    .section-actions+.section-content {
        padding-top: 5.625rem
    }

    .section-sm {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem
    }

    .section-sm .section-head {
        padding-bottom: 2.5rem
    }

    .section-sm .section-content+.section-actions {
        padding-top: 2.5rem
    }

    .section-sm .section-content+.section-content, .section-sm .section-content+.section-head {
        padding-top: 3.75rem
    }

    .section-sm .section-actions+.section-content {
        padding-top: 3.75rem
    }

    .section-lg {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem
    }

    .section-lg .section-head {
        padding-bottom: 3.75rem
    }

    .section-lg .section-content+.section-actions {
        padding-top: 3.75rem
    }

    .section-lg .section-content+.section-content, .section-lg .section-content+.section-head {
        padding-top: 5.625rem
    }

    .section-lg .section-actions+.section-content {
        padding-top: 5.625rem
    }
}

.section-bottom-0 {
    padding-bottom: 0
}

.section-top-0 {
    padding-top: 0
}

.section-0 {
    padding-top: 0;
    padding-bottom: 0
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-out {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

canvas {
    width: 100%;
    max-width: 100%
}

a {
    text-decoration: none;
    transition: color .2s, background-color .2s, border .2s, box-shadow .2s
}

h1:where(:last-child), h2:where(:last-child), h3:where(:last-child), h4:where(:last-child), h5:where(:last-child), h6:where(:last-child), .h1:where(:last-child), .h2:where(:last-child), .h3:where(:last-child), .h4:where(:last-child), .h5:where(:last-child), .h6:where(:last-child), p:where(:last-child) {
    margin-bottom: 0
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0
}

img {
    max-width: 100%
}

strong {
    color: #1c263b
}

.is-dark {
    color: #dce3ed
}

.is-dark h1, .is-dark h2, .is-dark h3, .is-dark h4, .is-dark h5, .is-dark h6, .is-dark .h1, .is-dark .h2, .is-dark .h3, .is-dark .h4, .is-dark .h5, .is-dark .h6 {
    color: #fff
}

.gap {
    margin-top: calc(-1*var(--gutter-y));
    margin-right: calc(-0.5*var(--gutter-x));
    margin-left: calc(-0.5*var(--gutter-x))
}

.gap[class*=gy-], .gap[class*=gx-] {
    margin-left: 0;
    margin-right: 0
}

.gap[class*=gy-]>*, .gap[class*=gx-]>* {
    padding-left: 0;
    padding-right: 0
}

.gap>* {
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-top: var(--gutter-y)
}

.z-index-1 {
    z-index: 1
}

.z-index-n1 {
    z-index: -1
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.media {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .25rem;
    font-size: .8333333333rem;
    flex-shrink: 0
}

.media .icon {
    font-size: 1.25rem
}

.media .icon.half {
    font-size: .625rem
}

.media svg.icon {
    height: 1.25rem;
    width: 1.25rem
}

.media img {
    border-radius: inherit
}

.media-rounded {
    border-radius: .5rem
}

.media-circle {
    border-radius: 50rem
}

.media-sharp {
    border-radius: 0
}

.media-middle {
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.media-border {
    border-width: 2px;
    border-style: solid
}

.media-sm {
    width: 1.875rem;
    height: 1.875rem;
    font-size: .625rem
}

.media-sm .icon {
    font-size: .9375rem
}

.media-sm .icon.half {
    font-size: .46875rem
}

.media-sm svg.icon {
    height: .9375rem;
    width: .9375rem
}

.media-md {
    width: 2.125rem;
    height: 2.125rem;
    font-size: .7083333333rem
}

.media-md .icon {
    font-size: 1.0625rem
}

.media-md .icon.half {
    font-size: .53125rem
}

.media-md svg.icon {
    height: 1.0625rem;
    width: 1.0625rem
}

.media-rg {
    width: 2.5rem;
    height: 2.5rem;
    font-size: .8333333333rem
}

.media-rg .icon {
    font-size: 1.25rem
}

.media-rg .icon.half {
    font-size: .625rem
}

.media-rg svg.icon {
    height: 1.25rem;
    width: 1.25rem
}

.media-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1rem
}

.media-lg .icon {
    font-size: 1.5rem
}

.media-lg .icon.half {
    font-size: .75rem
}

.media-lg svg.icon {
    height: 1.5rem;
    width: 1.5rem
}

.media-xl {
    width: 3.75rem;
    height: 3.75rem;
    font-size: 1.25rem
}

.media-xl .icon {
    font-size: 1.875rem
}

.media-xl .icon.half {
    font-size: .9375rem
}

.media-xl svg.icon {
    height: 1.875rem;
    width: 1.875rem
}

.media-2xl {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.5rem
}

.media-2xl .icon {
    font-size: 2.25rem
}

.media-2xl .icon.half {
    font-size: 1.125rem
}

.media-2xl svg.icon {
    height: 2.25rem;
    width: 2.25rem
}

.media-3xl {
    width: 5rem;
    height: 5rem;
    font-size: 1.6666666667rem
}

.media-3xl .icon {
    font-size: 2.5rem
}

.media-3xl .icon.half {
    font-size: 1.25rem
}

.media-3xl svg.icon {
    height: 2.5rem;
    width: 2.5rem
}

.link {
    display: inline-flex;
    align-items: center
}

.link span+.icon {
    margin-left: .75rem
}

.overline-title {
    font-size: .875rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .1em;
    color: #6b7989
}

.caption-text {
    font-size: .875rem;
    font-weight: 400
}

.lead-text {
    font-size: .875rem;
    font-weight: 500
}

.sub-text {
    font-size: .875rem;
    font-weight: 400
}

.smaller {
    font-size: .75rem
}

.small {
    font-size: .875rem
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--gutter-y));
    margin-right: calc(-0.5*var(--gutter-x));
    margin-left: calc(-0.5*var(--gutter-x))
}

.list>* {
    padding-right: calc(var(--gutter-x)*.5);
    padding-left: calc(var(--gutter-x)*.5);
    margin-top: var(--gutter-y)
}

.list li {
    display: flex;
    line-height: 1.5rem
}

.text-center .list li {
    justify-content: center
}

@media(min-width: 576px) {
    .text-sm-start .list li {
        justify-content: flex-start
    }

    .text-sm-center .list li {
        justify-content: center
    }
}

@media(min-width: 768px) {
    .text-md-start .list li {
        justify-content: flex-start
    }

    .text-sm-center .list li {
        justify-content: center
    }
}

@media(min-width: 992px) {
    .text-lg-start .list li {
        justify-content: flex-start
    }

    .text-sm-center .list li {
        justify-content: center
    }
}

@media(min-width: 1200px) {
    .text-xl-start .list li {
        justify-content: flex-start
    }

    .text-sm-center .list li {
        justify-content: center
    }
}

@media(min-width: 1400px) {
    .text-xxl-start .list li {
        justify-content: flex-start
    }

    .text-sm-center .list li {
        justify-content: center
    }
}

.list li>.icon {
    line-height: 1.5rem;
    margin-right: .625rem;
    font-size: 1rem
}

.list-sap-border li {
    position: relative
}

.list-sap-border li:not(:last-child):after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--gutter-y)*-0.5);
    height: 1px;
    background-color: rgba(0, 0, 0, .05)
}

.is-dark .list-sap-border li:not(:last-child):after {
    background-color: rgba(255, 255, 255, .05)
}

.list-row {
    flex-direction: row
}

.text-center .list {
    justify-content: center
}

@media(min-width: 576px) {
    .text-sm-start .list {
        justify-content: flex-start
    }

    .text-sm-center .list {
        justify-content: center
    }
}

@media(min-width: 768px) {
    .text-md-start .list {
        justify-content: flex-start
    }

    .text-sm-center .list {
        justify-content: center
    }
}

@media(min-width: 992px) {
    .text-lg-start .list {
        justify-content: flex-start
    }

    .text-sm-center .list {
        justify-content: center
    }
}

@media(min-width: 1200px) {
    .text-xl-start .list {
        justify-content: flex-start
    }

    .text-sm-center .list {
        justify-content: center
    }
}

@media(min-width: 1400px) {
    .text-xxl-start .list {
        justify-content: flex-start
    }

    .text-sm-center .list {
        justify-content: center
    }
}

.feature {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
}

.feature-inline {
    flex-direction: row
}

.feature-text .title {
    margin-bottom: .75rem;
    font-size: 18px;
    font-weight: 600;
    color: var(--gray-300);
}

.block-text .overline-title+h2, .block-text .overline-title+.h2 {
    margin-top: .75rem
}

.block-text h2+p, .block-text .h2+p {
    margin-top: 1.375rem
}

.block-text h3+p, .block-text .h3+p {
    margin-top: 1rem
}

.block-text h6+h3, .block-text .h6+h3, .block-text h6+.h3, .block-text .h6+.h3 {
    margin-top: 1rem
}

.block-text p+.btn-list {
    margin-top: 2rem
}

.block-text p+.list {
    margin-top: calc(1.5rem - var(--gutter-y)*.5)
}

.block-text .btn-list+.list {
    margin-top: 1.25rem
}

.block-text .list+.btn-list {
    margin-top: 2rem
}

.block-typo h1, .block-typo .h1, .block-typo h2, .block-typo .h2 {
    margin-bottom: .325em;
    margin-top: .5em
}

.block-typo h3, .block-typo .h3, .block-typo h4, .block-typo .h4, .block-typo h5, .block-typo .h5, .block-typo h6, .block-typo .h6 {
    margin-top: 1em;
    margin-bottom: .625em
}

.block-typo img {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
    border-radius: .5rem
}

.block-typo ul {
    margin-bottom: 1rem;
    margin-left: .75rem
}

.block-typo ul li {
    padding: .25rem 0
}

.block-typo ul li::marker {
    font-family: "Nioicon";
    content: "";
    font-size: 1rem;
    line-height: 1;
    margin-right: .5rem;
    text-indent: .5rem
}

@media(min-width: 992px) {
    .block-typo img {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        max-width: calc(100% - 5rem)
    }
}

.block-gfx {
    position: relative;
    isolation: isolate
}

.wgs-title {
    margin-bottom: .75rem
}

.wgs .list {
    font-size: .875rem
}

.pricing {
    position: relative;
    border-radius: .875rem;
    box-shadow: 0px 4px 12px 0px rgba(108, 91, 243, .1)
}

.pricing-toggle {
    position: relative;
    display: flex;
    border: .125rem solid #cfd8e6;
    background: #dce3ed;
    border-radius: 4rem;
    height: 2rem;
    width: 4rem
}

.pricing-toggle-wrap {
    display: flex;
    align-items: center;
    justify-content: center
}

.pricing-toggle-ball {
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    left: .25rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: var(--primary);
    transition: all .3s ease
}

.pricing-yearly .pricing-toggle-ball {
    left: 2.25rem
}

.pricing-toggle-text {
    transition: .3s ease
}

.pricing-toggle-text.monthly {
    color: var(--primary)
}

.pricing-yearly .pricing-toggle-text.monthly {
    color: #1c263b
}

.is-dark .pricing-yearly .pricing-toggle-text.monthly {
    color: #fff
}

.pricing-toggle-text.yearly {
    color: #1c263b
}

.is-dark .pricing-toggle-text.yearly {
    color: #fff
}

.pricing-yearly .pricing-toggle-text.yearly {
    color: var(--primary)
}

.show-yearly .pricing-price.monthly {
    display: none
}

.pricing-price.yearly {
    display: none
}

.show-yearly .pricing-price.yearly {
    display: block
}

.pricing-body {
    background-color: #fff;
    border-radius: .8125rem
}

.is-dark .pricing-body {
    background-color: #1c263b
}

.pricing-featured {
    box-shadow: none;
    padding: 2px;
    background-color: var(--primary)
}

.has-mask, .has-shape {
    position: relative
}

.has-mask>*:where(:not(.tw-mask):not(.tw-shape):not(.ignore-mask):not(.ignore-shape)), .has-shape>*:where(:not(.tw-mask):not(.tw-shape):not(.ignore-mask):not(.ignore-shape)) {
    position: relative;
    z-index: 5
}

.has-mask>*:where(.tw-header), .has-shape>*:where(.tw-header) {
    z-index: 6
}

.tw-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none
}

.bg-pattern-dot {
    background-image: url("../img/shapes/dot-a.png");
    background-position: center
}

.bg-pattern-dot-sm {
    background-image: url("../img/shapes/dot-a.png");
    background-size: 20px 20px;
    background-position: center
}

.bg-pattern-dot-white {
    background-image: url("../img/shapes/dot-a-white.png");
    background-position: center
}

.bg-pattern-dot-white-sm {
    background-image: url("../img/shapes/dot-a-white.png");
    background-size: 20px 20px;
    background-position: center
}

.bg-pattern-noise-a {
    background-image: url("../img/shapes/noise-a.png");
    background-size: cover
}

.bg-gradient-a {
    background-image: url("../img/gradient/a-blur.png"), url("../img/gradient/a.png");
    background-size: 2560px, cover;
    background-position: center 100%;
    background-repeat: no-repeat, repeat no-repeat
}

.bg-blend-top {
    -webkit-mask-image: linear-gradient(180deg, transparent 0, #000 40%);
    mask-image: linear-gradient(180deg, transparent 0, #000 40%)
}

.bg-blend-bottom {
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent 100%);
    mask-image: linear-gradient(180deg, #000 60%, transparent 100%)
}

.bg-blend-around {
    -webkit-mask-image: radial-gradient(ellipse, #000 50%, transparent 90%);
    mask-image: radial-gradient(ellipse, #000 50%, transparent 90%)
}

.bg-angle {
    background-repeat: repeat no-repeat
}

.bg-angle-top {
    background-position: 0 0
}

.bg-angle-bottom {
    background-position: 100% 100%
}

.bg-angle-flip {
    transform: scale(-1, -1)
}

.bg-angle-white {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 1920 55' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1921 55H0V53.1854L1921 0V55Z' fill='%23fff'/%3e%3c/svg%3e")
}

.bg-angle-black {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 1920 55' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1921 55H0V53.1854L1921 0V55Z' fill='%23000'/%3e%3c/svg%3e")
}

.bg-angle-dark {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 1920 55' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1921 55H0V53.1854L1921 0V55Z' fill='%231c263b'/%3e%3c/svg%3e")
}

.bg-angle-darker {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 1920 55' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1921 55H0V53.1854L1921 0V55Z' fill='%230e1424'/%3e%3c/svg%3e")
}

.tw-shape {
    position: absolute;
    z-index: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none
}

.bg-shape-blur-a {
    height: 1168px;
    width: 898px;
    background-image: url("../img/shapes/blur-a.png")
}

.bg-shape-blur-b {
    height: 762px;
    width: 586px;
    background-image: url("../img/shapes/blur-b.png")
}

.bg-shape-blur-c {
    height: 1036px;
    width: 1036px;
    background-image: url("../img/shapes/blur-c.png")
}

.bg-shape-blur-d {
    height: 1160px;
    width: 1160px;
    background-image: url("../img/shapes/blur-d.png")
}

.bg-shape-blur-e {
    height: 1100px;
    width: 1100px;
    background-image: url("../img/shapes/blur-e.png")
}

.bg-shape-blur-f {
    height: 1171px;
    width: 1170px;
    background-image: url("../img/shapes/blur-f.png")
}

.bg-shape-blur-g {
    height: 1348px;
    width: 1347px;
    background-image: url("../img/shapes/blur-g.png")
}

.bg-shape-blur-h {
    height: 1081px;
    width: 1081px;
    background-image: url("../img/shapes/blur-h.png")
}

.bg-shape-blur-i {
    height: 1171px;
    width: 1170px;
    background-image: url("../img/shapes/blur-i.png")
}

.bg-shape-blur-j {
    height: 1220px;
    width: 1220px;
    background-image: url("../img/shapes/blur-j.png")
}

.bg-shape-blur-k {
    height: 974px;
    width: 973px;
    background-image: url("../img/shapes/blur-k.png")
}

.bg-shape-blur-l {
    height: 1086px;
    width: 1087px;
    background-image: url("../img/shapes/blur-l.png")
}

.bg-shape-blur-m {
    height: 1160px;
    width: 1160px;
    background-image: url("../img/shapes/blur-m.png")
}

.bg-shape-blur-n {
    height: 871px;
    width: 751px;
    background-image: url("../img/shapes/blur-n.png")
}

.bg-shape-blur-o {
    height: 1099px;
    width: 1017px;
    background-image: url("../img/shapes/blur-o.png")
}

.bg-shape-blur-p {
    height: 1101px;
    width: 1100px;
    background-image: url("../img/shapes/blur-p.png")
}

.bg-shape-blur-q {
    height: 866px;
    width: 928px;
    background-image: url("../img/shapes/blur-q.png")
}

.bg-shape-blur-r {
    height: 883px;
    width: 883px;
    background-image: url("../img/shapes/blur-r.png")
}

.bg-shape-border-a {
    height: 481px;
    width: 572px;
    background-image: url("../img/shapes/border-a.png")
}

.bg-shape-border-b {
    height: 237px;
    width: 237px;
    background-image: url("../img/shapes/border-b.png")
}

.bg-shape-border-c {
    height: 949px;
    width: 949px;
    background-image: url("../img/shapes/border-c.png")
}

.bg-shape-border-d {
    height: 237px;
    width: 237px;
    background-image: url("../img/shapes/border-d.png")
}

.bg-shape-border-e {
    height: 175px;
    width: 175px;
    background-image: url("../img/shapes/border-e.png")
}

.bg-shape-border-f {
    height: 256px;
    width: 257px;
    background-image: url("../img/shapes/border-f.png")
}

.bg-shape-wormhole-a {
    height: 990px;
    width: 811px;
    background-image: url("../img/wormhole-a.png")
}
